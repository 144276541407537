import {ReactNode, useCallback} from 'react';
import {TextField, TextFieldProps} from '@mui/material';
import classNames from 'classnames';
import styles from './CustomTextField.module.css';

export type CustomTextFieldProps = {
    className?: string;
    label?: string;
    icon?: ReactNode;
    disabled?: boolean;
    first?: boolean;
    type?: string;
    maxLength?: number;
    rightComponent?: ReactNode;
    onEnter?: () => void;
} & TextFieldProps;

const CustomTextField = ({
    className,
    label,
    icon,
    disabled,
    first,
    maxLength,
    rightComponent,
    onEnter,
    ...props
}: CustomTextFieldProps) => {
    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (onEnter && event.key === 'Enter') {
                event.preventDefault();
                onEnter();
            }
        },
        [onEnter],
    );

    return (
        <div
            className={classNames(styles.textFieldContainer, {
                [styles.noTopMargin]: first,
            })}
        >
            {label}
            <div className={styles.textField}>
                {icon}
                <TextField
                    variant="standard"
                    margin="normal"
                    disabled={disabled}
                    className={classNames(styles.textInput, className)}
                    {...props}
                    onKeyDown={handleKeyDown}
                    slotProps={{
                        ...props.slotProps,
                        input: {
                            disableUnderline: true,
                            sx: {
                                color: 'var(--dark-grey)',
                            },
                            ...props.slotProps?.input,
                        },
                        htmlInput: {
                            maxLength: maxLength,
                            ...props.slotProps?.htmlInput,
                        },
                    }}
                    minRows={props.multiline ? 3 : undefined}
                    maxRows={props.multiline ? 3 : undefined}
                />
                {rightComponent}
            </div>
        </div>
    );
};
export default CustomTextField;
