import firebase from 'firebase/compat/app';

const storage = firebase.storage();

/**
 * @description A path inside of Firebase Storage.
 * @example 'locations/1234/locationMap.png'
 */
export type StorageFilePath = string;

export const uploadFile = (ref, file) => storage.ref(ref).put(file);

export const deleteFiles = paths => {
    const pathsWithoutCopies = [...new Set(paths)].filter(Boolean);

    if (pathsWithoutCopies) {
        return Promise.all(pathsWithoutCopies.map(deleteFile));
    }
};

export const deleteFile = path => storage.ref(path).delete();

export const getDownloadUrl = ref => storage.ref(ref).getDownloadURL();
