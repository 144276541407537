import React from 'react';
import {useTranslation} from 'react-i18next';
import Picker from '../picker/Picker';

const StatusPicker = props => {
    const {t} = useTranslation('issues');

    const STATUS_LABELS = {
        open: t('statusLabels.open'),
        inProgress: t('statusLabels.inProgress'),
        inReview: t('statusLabels.inReview'),
        done: t('statusLabels.done'),
    };

    const items = props.statuses?.map(status => ({
        value: status,
        label: STATUS_LABELS[status],
    }));
    const selectedValue = props.selectedStatus && {
        value: props.selectedStatus,
        label: STATUS_LABELS[props.selectedStatus],
    };
    return (
        <Picker
            label={t('dialog.issueStatus')}
            selectedValue={selectedValue}
            options={items}
            name="Miejsca"
            first={props.first}
            placeholder={t('dialog.searchForStatus')}
            handleChange={props.handleChange}
            className={props.className}
            searchable={false}
            disabled={props.disabled}
            menuPortalTarget={props.menuPortalTarget}
        />
    );
};

StatusPicker.defaultProps = {
    bottomLabelActive: true,
};

export default StatusPicker;
