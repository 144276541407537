import axios from 'axios';
import packageJson from '../../../../package.json';
import {PROJECT_NAME, APPLICATION_NAME} from '../../../config/appConfig';
import {LOGSTASH_URL} from '../../../config/logstash/logstashConfig';

const {database, version} = packageJson;

const axiosInstance = axios.create({headers: {}});

/** get active environment (or one of actives if more than 1 are selected)
 * database props represent environments, if a prop is set to true then that environment is active
 * */
const getAppEnvironment = () => {
    for (const prop in database) {
        if (database[prop]) return prop;
    }
    return 'unknown';
};

/**
 *  @error Error
 *  @consoleMessage OPTIONAL message to be displayed on console
 *  @isFatal OPTIONAL log flag, DEFAULT=false
 * */
const errorHandler = (
    error,
    consoleMessage?: string,
    isFatal: boolean = false,
) => {
    const errorsToIgnore = ['authError'];

    if (!errorsToIgnore.includes(error)) {
        reportError(error, consoleMessage, isFatal);
    }
};

/** createLogMessage based on error properties (should be changed if we create consistent logs template)
 * */
const createLogMessageForError = error => {
    let props = formattedLine('path', window.location.pathname);

    if (error.response?.data?.code) {
        props += formattedLine('code', error.response.data.code);
    } else if (error?.code) {
        props += formattedLine('code', error.code);
    }

    if (error.request?.responseURL) {
        props += formattedLine('responseURL', error.request.responseURL);
    }
    props += formattedLine('error', error.toString());

    return `{${props}\n}`;

    function formattedLine(prop, value) {
        return `\n  "${prop}": "${value}"`;
    }
};

/**
 *  @error Error
 *  @consoleMessage NULL | message to be displayed on console
 *  @isFatal log flag
 * */
const reportError = (error, consoleMessage, isFatal) => {
    const message = createLogMessageForError(error);

    axiosInstance
        .post(LOGSTASH_URL, {
            message: message,
            project: PROJECT_NAME,
            application: APPLICATION_NAME,
            appVersion: version,
            environment: getAppEnvironment(),
            isFatal: `${isFatal}`,
        })
        .catch(console.warn);

    if (database.develop) {
        console.error(error);
    }

    if (consoleMessage) console.warn(consoleMessage);
    else console.warn(error);
};

window.addEventListener('error', event => {
    event.preventDefault();
    if (event.error.hasBeenCaught !== undefined) {
        return false;
    }
    event.error.hasBeenCaught = true;
    errorHandler(event.error, '', true);
});

export default errorHandler;
