import firebase from 'firebase/compat/app';
import {elements} from '../../constants/endpoints';
import QueryBuilder from './QueryBuilder';
import {elementConverter} from '../converter/elementConverter';

class ElementsQueryBuilder {
    constructor(branchIds) {
        const createQuery = () => firebase.firestore().collection(elements());
        this._queryBuilder = new QueryBuilder(
            createQuery,
            branchIds,
            {pathToBranchId: 'location.branch.id'},
            elementConverter,
        );
    }

    static query(branchIds) {
        return new ElementsQueryBuilder(branchIds);
    }

    onSnapshot(callback, onError) {
        return this._queryBuilder.onSnapshot(callback, onError);
    }

    withLocation(locationId) {
        return this._queryBuilder.withLocation(locationId);
    }

    get() {
        return this._queryBuilder.get();
    }
}

const getElementsQuery = ElementsQueryBuilder.query;

export default getElementsQuery;
