import React, {useState} from 'react';
import styles from './PhotoGallery.module.css';
import {ImageDisplay} from '..';
import {ReactComponent as Icon} from '../../../static/images/icons/dropdown.svg';
import {Dialog} from '@mui/material';

const PhotoGallery = props => {
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(props.index);
    const photos = props.photos?.map((photo, index) => (
        <ImageDisplay
            key={index}
            src={photo}
            onImageClick={event => {
                event.stopPropagation();
                setSelectedPhotoIndex(index);
            }}
            imgClassName={styles.noBorder}
        />
    ));
    const changePhotoHandler = step => {
        let newIndex = selectedPhotoIndex + step;
        newIndex = newIndex === photos.length ? 0 : newIndex;
        newIndex = newIndex === -1 ? photos.length - 1 : newIndex;

        setSelectedPhotoIndex(newIndex);
    };
    const imagesCount = props.photos.length;
    return (
        <Dialog
            maxWidth={false}
            open
            onClick={props.onDialogClick}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }}
        >
            {imagesCount !== 1 ? (
                <div className={styles.container}>
                    <div className={styles.swapButton}>
                        <Icon
                            className={styles.icon}
                            onClick={event => {
                                event.stopPropagation();
                                changePhotoHandler(-1);
                            }}
                        />
                    </div>
                    <div className={styles.imageDisplay}>
                        <img src={props.photos[selectedPhotoIndex]} alt="" />
                    </div>
                    <div className={styles.swapButton}>
                        <Icon
                            className={styles.icon}
                            style={{transform: 'scaleX(-1)'}}
                            onClick={event => {
                                event.stopPropagation();
                                changePhotoHandler(1);
                            }}
                        />
                    </div>
                    <div />
                    <div className={styles.smallPhotos}>{photos}</div>
                    <div />
                </div>
            ) : (
                <img
                    className={styles.adjustedImage}
                    src={props.photos[selectedPhotoIndex]}
                    alt=""
                />
            )}
        </Dialog>
    );
};

export default PhotoGallery;
