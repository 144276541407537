import i18next, {InitOptions} from 'i18next';
import {initReactI18next} from 'react-i18next';

import {getDeviceLocaleShortCode} from './getUserLocales';
import {plResources} from './pl/resource';
import {enResources} from './en/resource';

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

export const DEFAULT_NAMESPACE = 'common';

const resources = {
    pl: plResources,
    en: enResources,
};

export const detectLocale = callback => callback(getDeviceLocaleShortCode());

const languageDetector = {
    type: 'languageDetector',
    detect: detectLocale,
    async: true,
    init: () => {},
    cacheUserLanguage: () => {},
};

const initOptions: InitOptions = {
    compatibilityJSON: 'v3',
    fallbackLng: ['en'],
    nonExplicitSupportedLngs: true,
    ns: ['common', 'auth', 'issues', 'locations', 'tasks'],
    defaultNS: DEFAULT_NAMESPACE,
    resources,
};

i18next
    .use(languageDetector as any)
    .use(initReactI18next)
    .init(initOptions)
    .catch(console.warn);
