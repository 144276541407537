import {useTranslation} from 'react-i18next';
import Picker from '../picker/Picker';
import {useMemo} from 'react';

const UserPicker = ({
    label,
    selectedUser,
    users,
    handleChange,
    className,
    icon,
    disabled,
    clearable,
    menuPortalTarget,
}) => {
    const {t} = useTranslation('issues');
    const items = users?.map(user => ({
        value: user.uid,
        label: `${user.name} ${user.surname}`,
    }));

    const emptyItem = useMemo(
        () => ({
            value: null,
            label: t('details.default.unassigned'),
        }),
        [t],
    );

    const selectedValue = selectedUser && {
        value: selectedUser.uid,
        label: `${selectedUser.name} ${selectedUser.surname}`,
    };

    const pickerItems = useMemo(() => [emptyItem, ...items], [items]);

    return (
        <Picker
            clearable={clearable}
            label={label}
            options={pickerItems}
            handleChange={handleChange}
            selectedValue={selectedValue}
            icon={icon}
            name="user"
            placeholder={t('dialog.searchForEmployee')}
            className={className}
            disabled={disabled}
            menuPortalTarget={menuPortalTarget}
        />
    );
};

export default UserPicker;
