import React from 'react';
import {useTranslation} from 'react-i18next';
import Picker from '../picker/Picker';
import {labels, Role} from '../../../constants/roles';
import {SelectOption} from '../Select/CustomSelect';
import {MenuPlacement} from 'react-select';

type Props = {
    selectedRole: string;
    handleChange: (payload: SelectOption) => void;
    first?: boolean;
    className?: string;
    disabled?: boolean;
    menuPlacement?: MenuPlacement;
    menuPortalTarget?: HTMLElement;
    bottomLabelActive?: boolean;
    placeholder?: string;
};

const availableRoles = [
    Role.ADMIN,
    Role.MAINTAINER,
    Role.SUPERVISOR,
    Role.REPORTER,
];

const RolePicker = ({
    selectedRole,
    handleChange,
    className,
    disabled,
    first,
    menuPlacement,
    menuPortalTarget,
}: Props) => {
    const items = availableRoles.map(role => ({
        value: role,
        label: labels[role],
    }));

    const selectedValue = selectedRole && {
        value: selectedRole,
        label: labels[selectedRole],
    };

    const {t} = useTranslation('common');

    return (
        <Picker
            label={t('rolePicker.label')}
            selectedValue={selectedValue}
            options={items}
            name="Rola"
            first={first}
            placeholder={t('rolePicker.placeholder')}
            handleChange={handleChange}
            searchable={false}
            className={className}
            disabled={disabled}
            menuPlacement={menuPlacement}
            menuPortalTarget={menuPortalTarget}
        />
    );
};

export default RolePicker;
