import {SET_SELECTED_BRANCHES, SET_BRANCHES} from './actionTypes';
import {Branch} from '../../models/branch';
import {AppStorage, StorageItem} from '../../storage/AppStorage';
import firebase from 'firebase/compat';

export type SetSelectedBranchesAction = {
    type: typeof SET_SELECTED_BRANCHES;
    selectedBranches: Branch[];
};

export type SetBranchesAction = {
    type: typeof SET_BRANCHES;
    branches: Branch[];
};

const setSelectedBranches = (selectedBranches: Branch[]) => ({
    selectedBranches,
    type: SET_SELECTED_BRANCHES,
});

const setBranches = (branches: Branch[]) => ({
    branches,
    type: SET_BRANCHES,
});

export const fetchBranches =
    dispatch =>
    (
        querySnapshot: firebase.firestore.QuerySnapshot,
        setActivityIndicator?: (value: boolean) => void,
    ) => {
        setActivityIndicator?.(true);
        const branches = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
        })) as Branch[];

        setActivityIndicator?.(false);

        dispatch(setBranches(branches));
    };

export const setUserSelectedBranches =
    (selectedBranches: Branch[]) => dispatch => {
        AppStorage.set(StorageItem.selectedBranches, selectedBranches);
        dispatch(setSelectedBranches(selectedBranches));
    };
