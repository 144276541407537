import classNames from 'classnames';
import Select, {CustomSelectProps} from '../Select/CustomSelect';
import styles from './Picker.module.css';

type Props = {
    className?: string;
    first?: boolean;
} & CustomSelectProps;

const Picker = ({
    className,
    first,
    searchable = true,
    ...selectProps
}: Props) => {
    const noTopMargin = first ? styles.noTopMargin : '';
    return (
        <div className={classNames(styles.picker, className, noTopMargin)}>
            <Select {...selectProps} searchable={searchable} />
        </div>
    );
};

export default Picker;
