import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import authReducer from './reducer/auth';
import elementsReducer from './reducer/element';
import issuesReducer from './reducer/issue';
import locationReducer from './reducer/location';
import userReducer from './reducer/user';
import taskReducer from './reducer/tasks';
import branchReducer from './reducer/branch';
import categoryReducer from './reducer/category';
import qrCodetReducer from './reducer/qrCode';
import {USER_LOGOUT} from './action/actionTypes';

const appReducer = combineReducers({
    auth: authReducer,
    element: elementsReducer,
    issue: issuesReducer,
    location: locationReducer,
    user: userReducer,
    task: taskReducer,
    branch: branchReducer,
    category: categoryReducer,
    qrCode: qrCodetReducer,
});

const rootReducer: typeof appReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

const composeEnhancers =
    process.env.NODE_ENV === 'development'
        ? // @ts-ignore
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
        : compose;

const configureStore = () =>
    createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
