import React, {Component} from 'react';

const NotificationContext = React.createContext({
    notifications: [],
    addNotification: ({message, type}) => {},
    removeNotification: () => {},
});

class NotificationProvider extends Component {
    state = {notifications: []};

    addNotification = (message, type) => {
        this.setState({
            notifications: [...this.state.notifications, {message, type}],
        });
        setTimeout(
            () =>
                this.removeNotification(this.state.notifications.lastIndexOf()),
            3000,
        );
    };

    removeNotification = id => {
        const notificationsArray = [...this.state.notifications];
        notificationsArray.splice(id, 1);
        this.setState({notifications: notificationsArray});
    };

    notificationsContext = () => ({
        state: this.state,
        addNotification: ({message, type}) =>
            this.addNotification(message, type),
        removeNotification: this.removeNotification,
    });

    render() {
        return (
            <NotificationContext.Provider value={this.notificationsContext()}>
                {this.props.children}
            </NotificationContext.Provider>
        );
    }
}

export {NotificationContext, NotificationProvider};
