import {useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './AddIssueLayout.module.css';
import {
    CustomTextField,
    LocationsPicker,
    ElementsPicker,
    CustomDatePicker,
    ImageDropzone,
    ImageDisplay,
    ClickablePhoneNumberInput,
} from '../../../common/components/index';
import Dialog from '../../../common/components/Dialog/Dialog';
import RadioButtons from '../../../common/components/radio/RadioButton';
import {LocalizationMapPicker} from '../LocalizationMapPicker/LocalizationMapPicker';
import {FormControlLabel, Radio} from '@mui/material';
import {PhoneInput} from '../../../common/components/PhoneInput/PhoneInput';

type PriorityLabels = {
    low: string;
    medium: string;
    high: string;
};

const AddIssueFormLayout = ({
    innerComponents: [CategoryPicker, _, UserPicker],
    props,
}) => {
    const dialogRef = useRef<HTMLDivElement | null>(null);
    const {t} = useTranslation('issues');
    const translatedPriorityLabels: PriorityLabels = {
        low: t('priorityLabels.low'),
        medium: t('priorityLabels.medium'),
        high: t('priorityLabels.high'),
    };

    const radioGroupOptions = useMemo(
        () =>
            Object.entries(translatedPriorityLabels).map(([key, value]) => ({
                name: value,
                value: key,
            })),
        [],
    );

    return (
        <Dialog
            ref={dialogRef}
            loading={props.loading}
            handleClose={props.onClose}
            dialogTitle={t('dialog.title')}
            submitButtonDisabled={props.isSubmitButtonDisabled}
            onSubmitHandler={props.onSubmitNewIssueHandler}
            submitButtonText={t('dialog.title')}
        >
            <LocationsPicker
                selectedLocation={props.selectedLocation}
                handleChange={props.onLocationChange}
                first
                locations={props.locations}
                disabled={props.disableElementAndLocationPicker}
                menuPortalTarget={dialogRef.current}
            />
            {props.isIssueLocalizationEnabled &&
                props.selectedLocation?.locationMap && (
                    <LocalizationMapPicker
                        selectedLocation={props.selectedLocation}
                        onSave={props.onLocationMarkerSave}
                        onDelete={props.onLocationMarkerDelete}
                        initInEditMode
                    />
                )}
            <ElementsPicker
                selectedElement={props.selectedElement}
                handleChange={props.onElementChange}
                elements={props.elements}
                disabled={props.disableElementAndLocationPicker}
                menuPortalTarget={dialogRef.current}
            />
            {props.selectedElement?.iconUri && (
                <ImageDisplay
                    src={props.selectedElement?.iconUri}
                    fullSizeSrc={props.selectedElement?.patternUri}
                    title={t('dialog.elementImg')}
                />
            )}
            <CustomDatePicker
                label={t('dialog.issueDate')}
                value={new Date()}
                readOnly
            />

            <PhoneInput
                label={t('details.default.phoneNumber')}
                placeholder={'123456789'}
                value={props.phoneNumber}
                onChange={props.onPhoneNumberChange}
            />

            {props.isIssuesCategoriesFeatureEnabled &&
                CategoryPicker({
                    label: t('dialog.issueCategory'),
                    selectedCategory: props.selectedCategory,
                    handleChange: props.onSelectedCategoryChange,
                    categories: props.categories,
                    menuPortalTarget: dialogRef.current,
                })}
            {UserPicker({
                label: t('dialog.assignedTo'),
                selectedUser: props.assignedEmployee,
                handleChange: props.onAssignedEmployeeChange,
                users: props.users,
                maintainerHasNoPermissionToAssignUsersToIssue:
                    props.maintainerHasNoPermissionToAssignUsersToIssue,
                menuPortalTarget: dialogRef.current,
            })}
            {RadioButtons({
                items: radioGroupOptions,
                label: t('dialog.priority'),
                onChange: props.onPriorityChange,
                defaultValue: props.defaultPriority,
            })}
            <CustomTextField
                className={styles.description}
                label={t('dialog.description')}
                onChange={props.onDescriptionChange}
                fullWidth
                multiline
            />
            <ImageDropzone
                label={t('dialog.issueImgs')}
                onDrop={props.onIssueImageDrop}
                imagesSrc={props.issueImageFiles.map(image =>
                    URL.createObjectURL(image),
                )}
                onRemoveImage={props.onRemoveIssueImage}
            />
        </Dialog>
    );
};

export default AddIssueFormLayout;
