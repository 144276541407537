import React, {ReactNode} from 'react';
import styles from './ListActionPanel.module.css';
import {AddButton, ListTitle, SearchField} from '../index';
import {If} from '../If';
import {SearchFieldProps} from '../searchField/SearchField';

type ListActionPanelProps = {
    title: string;
    showAddButton?: boolean;
    addButtonText: string;
    onToggleAddDialogHandler?: VoidFunction;
    searchPlaceholder?: string;
    additionalHeaderComponent?: ReactNode;
    onSettingsIconClick?: () => void;
    onMapIconClick?: () => void;
} & Omit<SearchFieldProps, 'placeholder'>;

const ListActionPanel = ({
    title,
    showAddButton,
    addButtonText,
    onToggleAddDialogHandler,
    searchPlaceholder,
    onSearchTextChange,
    withFilter,
    openFilter,
    filterState,
    onCheckboxClick,
    onFilterIconClickHandler,
    onSettingsIconClick,
    onMapIconClick,
    searchText,
    additionalHeaderComponent,
}: ListActionPanelProps) => (
    <div className={styles.actionListPanel}>
        <div className={styles.topBar}>
            <ListTitle title={title} />
            <If condition={additionalHeaderComponent}>
                {additionalHeaderComponent}
            </If>
            <If condition={showAddButton}>
                <span className={styles.addIssueButton}>
                    <AddButton
                        text={addButtonText}
                        onClick={onToggleAddDialogHandler}
                    />
                </span>
            </If>
        </div>
        <SearchField
            placeholder={searchPlaceholder}
            onSearchTextChange={onSearchTextChange}
            withFilter={withFilter}
            openFilter={openFilter}
            filterState={filterState}
            onCheckboxClick={onCheckboxClick}
            onFilterIconClickHandler={onFilterIconClickHandler}
            searchText={searchText}
            onSettingsIconClick={onSettingsIconClick}
            onMapIconClick={onMapIconClick}
        />
    </div>
);

export default ListActionPanel;
