import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import {
    List,
    ListColumnCreator,
    ListItem,
} from '../../../common/components/index';
import Status from './IssueListItem/display/Status/Status';
import MobileList from '../../../common/components/list/MobileList';
import ListItemMobile from '../../../common/components/list/listItem/ListItemMobile/ListItemMobile';
import MobileHeader from '../../../common/components/list/listItem/display/mobileHeader/mobileHeader';
import MobileDataDisplay from '../../../common/components/list/listItem/display/mobileDataDisplay/moblieDataDisplay';
import {
    issueCreateDateComparator,
    issuePriorityComparator,
    maintainerComparator,
    priorityComparator,
    statusComparator,
} from '../../../utils/sorting/sorting';
import {isOutdated} from '../../../utils/issue/issueUtils';
import ElementName from '../../../common/components/list/listItem/display/elementName/elementName';
import styles from './IssuesList.module.css';
import {LocationName} from './IssueListItem/display/locationName/locationName';
import {Issue} from '../../../models';
import moment from 'moment';
import {IssueColumn} from './IssueList.types';
import {IssuePriorityCell} from './IssueListItem/display/IssuePriorityCell/IssuePriorityCell';
import {useAppSelector} from '../../../store/hooks';
import {styled} from '@mui/material';
import {
    ClusterClickEvent,
    MapMarker,
    MapRefFunctions,
} from '../../Map/Map.types';
import {Map as MapComponent} from '../../Map/Map';
import {CSSVariables} from '../../../variables_css';
import {useMapStore} from '../../../store/zustand/useMapStore';

const MAP_WIDTH_ANIMATION = 300; // ms

const ISSUE_COLUMN_ORDER = Object.values(IssueColumn);

const sortColumns = (a: IssueColumn, b: IssueColumn): number => {
    return ISSUE_COLUMN_ORDER.indexOf(a) - ISSUE_COLUMN_ORDER.indexOf(b);
};

type Props = {
    isMapVisible: boolean;
    issues: Issue[];
    onIssueClick: (issue: Issue) => void;
    visibleColumns: IssueColumn[];
};

const IssuesList = ({
    issues,
    onIssueClick,
    visibleColumns,
    isMapVisible,
}: Props) => {
    const {t} = useTranslation('issues');

    const mapRef = useRef<MapRefFunctions | null>(null);

    const clickedMarkers = useMapStore(state => state.clickedMarkers);
    const setClickedMarkers = useMapStore(state => state.setClickedMarkers);

    const eventBased = useAppSelector(
        state => state.auth.organizationData.eventBased,
    );

    const issueExpiration = useAppSelector(
        state => !!state.auth.organizationData.issueExpiration,
    );

    const isIssueLocalizationEnabled = useAppSelector(
        state => !!state.auth.organizationData.isIssueLocalizationEnabled,
    );

    useEffect(() => {
        if (isMapVisible) {
            setTimeout(() => {
                mapRef.current?.invalidateSize();
            }, MAP_WIDTH_ANIMATION);
        }
    }, [isMapVisible]);

    const columnsMap = useMemo(
        () =>
            new Map<IssueColumn, any>([
                [
                    IssueColumn.ELEMENT_NAME,
                    new ListColumnCreator(
                        'elementName',
                        eventBased
                            ? t('table.columns.eventBased.workStation')
                            : t('table.columns.default.element'),
                        {
                            accessAttribute: issue => issue.element.name,
                        },
                    ),
                ],
                [
                    IssueColumn.ID,
                    new ListColumnCreator(
                        'id',
                        t('table.columns.default.id'),
                        {},
                    ),
                ],
                [
                    IssueColumn.LOCATION_NAME,
                    new ListColumnCreator(
                        'locationName',
                        eventBased
                            ? t('table.columns.eventBased.company')
                            : t('table.columns.default.location'),
                        {
                            accessAttribute: issue =>
                                issue.element.location.name,
                        },
                    ),
                ],
                [
                    IssueColumn.BRAND_NAME,
                    new ListColumnCreator(
                        'branchName',
                        eventBased
                            ? t('table.columns.eventBased.expo')
                            : t('table.columns.default.branch'),
                        {
                            accessAttribute: issue =>
                                issue.element.location.branch.name,
                        },
                    ),
                ],
                [
                    IssueColumn.DESCRIPTION,
                    new ListColumnCreator(
                        'issueDescription',
                        t('table.columns.default.description'),
                        {},
                    ),
                ],
                [
                    IssueColumn.CREATED_DATE,
                    new ListColumnCreator(
                        'createdDate',
                        t('table.columns.default.creationDate'),
                        {},
                    ),
                ],
                [
                    IssueColumn.ASSIGNED_TO,
                    new ListColumnCreator(
                        'assignedTo',
                        t('table.columns.default.assignedTo'),
                        {
                            customComparator: maintainerComparator,
                        },
                    ),
                ],
                [
                    IssueColumn.PRIORITY,
                    new ListColumnCreator(
                        'priority',
                        t('table.columns.default.priority'),
                        {
                            customComparator: priorityComparator,
                        },
                    ),
                ],
                [
                    IssueColumn.STATUS,
                    new ListColumnCreator(
                        'status',
                        t('table.columns.default.status'),
                        {
                            customComparator: statusComparator,
                        },
                    ),
                ],
            ]),
        [],
    );

    const columns = useMemo(() => {
        return visibleColumns
            .sort(sortColumns)
            .map(column => columnsMap.get(column));
    }, [visibleColumns]);

    const isColumnVisible = (value: IssueColumn) =>
        visibleColumns.includes(value);

    const classes = ['elementName', 'image'];

    const sortIssues = (issues: Issue[]) =>
        [...issues].sort(
            (a, b) =>
                issuePriorityComparator(a, b) ||
                issueCreateDateComparator(a, b),
        );

    const columnIds = useMemo(
        () => [...columnsMap.keys()].map(column => columnsMap.get(column).id),
        [columnsMap],
    );

    const getIssueListItemTemplate = (issue: Issue) => {
        const outdated = isOutdated(
            issue.priority,
            issue.history,
            issue.status,
        );

        return (
            <ListItem
                key={issue.id}
                onClick={() => onIssueClick(issue)}
                className={classnames({
                    [styles.overdue]: issueExpiration && outdated,
                })}
                columns={columnIds}
            >
                {isColumnVisible(IssueColumn.ELEMENT_NAME) && (
                    <ElementName
                        elementName={issue.element.name}
                        iconUri={issue.iconUri}
                    />
                )}

                {isColumnVisible(IssueColumn.ID) &&
                    issue.id.toLowerCase().slice(0, 6)}

                {isColumnVisible(IssueColumn.LOCATION_NAME) && (
                    <LocationName
                        locationName={issue.element.location.name}
                        hasLocationMarker={
                            isIssueLocalizationEnabled && !!issue.locationMarker
                        }
                    />
                )}

                {isColumnVisible(IssueColumn.BRAND_NAME) &&
                    `${issue.element.location.branch.name}`}

                {isColumnVisible(IssueColumn.DESCRIPTION) && (
                    <span>{issue.issueDescription}</span>
                )}

                {isColumnVisible(IssueColumn.CREATED_DATE) &&
                    moment(issue.createdDate).format('HH:mm DD/MM/YYYY')}

                {isColumnVisible(IssueColumn.ASSIGNED_TO) &&
                    (issue.assignedTo
                        ? `${issue.assignedTo.name} ${issue.assignedTo.surname}`
                        : ` `)}

                {isColumnVisible(IssueColumn.PRIORITY) && (
                    <IssuePriorityCell priority={issue?.priority} />
                )}

                {isColumnVisible(IssueColumn.STATUS) && (
                    <Status issue={issue} />
                )}
            </ListItem>
        );
    };

    const getMobileIssueListItemTemplate = (issue: Issue) => {
        const outdated = isOutdated(
            issue.priority,
            issue.history,
            issue.status,
        );
        return (
            <ListItemMobile
                key={issue.id}
                onClick={() => onIssueClick(issue)}
                className={classnames({
                    [styles.overdue]: issueExpiration && outdated,
                })}
            >
                <MobileHeader classes={['status', 'date']}>
                    <Status issue={issue} />
                    {moment(issue.createdDate).format('DD/MM/YYYY')}
                </MobileHeader>
                <MobileDataDisplay
                    withoutImage={false}
                    image={issue.iconUri}
                    left={issue.element.name}
                    right={issue.element.location.name}
                    title={
                        issue.issueDescription
                            ? issue.issueDescription
                            : t('mobile.noIssueName')
                    }
                />
            </ListItemMobile>
        );
    };

    const sortedIssues = useMemo(() => sortIssues(issues), [issues]);

    const markers = useMemo(() => {
        return sortedIssues.reduce<MapMarker[]>((acc, issue) => {
            const {coordinates} = issue.element.location;

            if (!coordinates) {
                return acc;
            }

            return [
                ...acc,
                {
                    id: issue.id,
                    name: issue.element.name,
                    ...coordinates,
                },
            ];
        }, []);
    }, [sortedIssues.length]);

    const onClusterClick = useCallback(
        (e: ClusterClickEvent) => {
            const {latlng} = e;

            const fixed = 3;
            const foundMarkers = markers.filter(
                marker =>
                    marker.lat.toFixed(fixed) === latlng.lat.toFixed(fixed) &&
                    marker.lng.toFixed(fixed) === latlng.lng.toFixed(fixed),
            );

            setClickedMarkers(foundMarkers);
        },
        [markers, sortedIssues],
    );

    const clearMapSelectedIssues = useCallback(() => {
        setClickedMarkers([]);
    }, []);

    const issuesToDisplay = useMemo(() => {
        if (!clickedMarkers.length) {
            return sortedIssues;
        }

        return sortedIssues.filter(issue =>
            clickedMarkers.find(marker => marker.id === issue.id),
        );
    }, [clickedMarkers, sortedIssues]);

    return (
        <>
            <Wrapper>
                <IssuesContainer isMapVisible={isMapVisible}>
                    <List
                        columns={columns}
                        rows={issuesToDisplay}
                        rowTemplate={getIssueListItemTemplate}
                        name="issues"
                    />
                </IssuesContainer>
                <MapContainer isMapVisible={isMapVisible}>
                    <MapComponent
                        ref={mapRef}
                        markers={markers}
                        onClusterClick={onClusterClick}
                        onMarker={clearMapSelectedIssues}
                        withURLState={isMapVisible}
                    />
                </MapContainer>
            </Wrapper>

            <MobileList
                columns={classes}
                rows={issuesToDisplay}
                rowTemplate={getMobileIssueListItemTemplate}
            />
        </>
    );
};

export default IssuesList;

const MapContainer = styled('div')<{isMapVisible: boolean}>`
    display: flex;
    flex: 1;
    min-height: 250px;
    @media (max-width: ${CSSVariables['mobile-threshold-width']}) {
        display: ${props => (props.isMapVisible ? 'flex' : 'none')};
    }
`;

const Wrapper = styled('div')`
    display: flex;
`;

const IssuesContainer = styled('div')<{isMapVisible: boolean}>`
    width: ${props => (props.isMapVisible ? '50%' : '100%')};
    transition: ${MAP_WIDTH_ANIMATION}ms ease width;
    @media (max-width: ${CSSVariables['mobile-threshold-width']}) {
        display: none;
    }
`;
