import './MapMarkerCluster.css';
import {IconButton, styled} from '@mui/material';
import {
    MapContainer,
    MapContainerProps,
    TileLayer,
    useMapEvent,
} from 'react-leaflet';
import {
    LeafletMouseEvent,
    Map as LeafletMap,
    LeafletEvent,
    LatLng,
    divIcon,
} from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import {ClusterClickEvent, MapMarker, MapRefFunctions} from './Map.types';
import {
    ChangeEvent,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import {Button, CustomTextField} from '../../common/components';
import {If} from '../../common/components/If';
import SearchIcon from '@mui/icons-material/Search';
import {useCoordinatesMutation} from '../../api/map/useCoordinatesMutation';
import {Marker} from './Marker';
import {useTranslation} from 'react-i18next';
import {Coordinates} from '../../models';
import {MapSearchParams} from '../../store/zustand/useMapStore';
import {useMapUrlState} from './useMapUrlState';

const createClusterCustomIcon = cluster => {
    const count = cluster.getChildCount();
    const displayCount = count > 999 ? '999+' : count;

    return divIcon({
        html: `<div class="custom-cluster">${displayCount}</div>`,
        className: 'marker-cluster-custom',
    });
};

type Props = {
    city?: string;
    center?: MapMarker;
    markers: MapMarker[];
    onMarker?: (marker: MapMarker) => void;
    withSearch?: boolean;
    onSearchSubmit?: () => void;
    withURLState?: boolean;
    onClusterClick?: (e: ClusterClickEvent) => void;
    singleMarkerMode?: boolean;
} & MapContainerProps;

export const Map = forwardRef<MapRefFunctions, Props>(
    (
        {
            markers,
            onMarker,
            withSearch,
            onSearchSubmit,
            withURLState = false,
            onClusterClick,
            singleMarkerMode = true,
            ...mapProps
        },
        forwardedRef,
    ) => {
        const {t: tLocations} = useTranslation('locations');

        const mapRef = useRef<LeafletMap | null>(null);

        const {zoom, lat, lng, updateState} = useMapUrlState(withURLState);

        const position = useRef<Coordinates>({lat, lng}).current;

        const [search, setSearch] = useState('');
        const [searchedMarker, setSearchedMarker] = useState<MapMarker | null>(
            null,
        );

        const {mutate, data, isPending} = useCoordinatesMutation();

        useImperativeHandle(
            forwardedRef,
            () => ({
                invalidateSize: () => mapRef.current?.invalidateSize(),
            }),
            [],
        );

        useEffect(() => {
            if (!data) {
                return;
            }

            const coords = {lat: Number(data.lat), lng: Number(data.lon)};

            mapRef.current?.flyTo(coords, 13, {duration: 3});
            const marker = {id: 999, ...coords};
            setSearchedMarker(marker);
            onMarker?.(marker);
        }, [data]);

        const fetchCoordinates = useCallback(() => {
            mutate(search);
        }, [search]);

        const onTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value);
        }, []);

        const onMapClick = useCallback(
            (e: LeafletMouseEvent) => {
                onMarker?.({
                    id: Math.random(),
                    name: 'Selected location',
                    lat: e.latlng.lat,
                    lng: e.latlng.lng,
                });
                setSearchedMarker(null);
            },
            [onMarker],
        );

        const onZoom = useCallback(
            (e: LeafletEvent) => {
                updateState(MapSearchParams.ZOOM, e.target._zoom);
            },
            [updateState],
        );

        const onPositionChanged = useCallback(
            ({lat, lng}: LatLng) => {
                updateState(MapSearchParams.LAT, lat);
                updateState(MapSearchParams.LNG, lng);
            },
            [updateState],
        );

        const renderMarkers = useMemo(() => {
            if (searchedMarker) {
                return <Marker key={searchedMarker.id} item={searchedMarker} />;
            }
            return markers.map(marker => (
                <Marker
                    key={marker.id}
                    item={marker}
                    onClusterClick={onClusterClick}
                />
            ));
        }, [markers, searchedMarker]);

        return (
            <MainContainer>
                <Container>
                    <StyledMapContainer
                        {...mapProps}
                        ref={mapRef}
                        zoom={mapProps.zoom ?? zoom}
                        center={mapProps.center ?? position}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url={
                                'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            }
                        />
                        <MarkerClusterGroup
                            key={markers.length}
                            onClick={onClusterClick}
                            spiderfyOnMaxZoom={false}
                            showCoverageOnHover={false}
                            singleMarkerMode={singleMarkerMode}
                            iconCreateFunction={createClusterCustomIcon}
                        >
                            {renderMarkers}
                        </MarkerClusterGroup>
                        <MapClickHandler
                            onClick={onMapClick}
                            onZoom={onZoom}
                            onPositionChanged={onPositionChanged}
                        />
                    </StyledMapContainer>
                    <If condition={withSearch}>
                        <TextFieldContainer>
                            <CustomTextField
                                value={search}
                                onChange={onTextChange}
                                first
                                label={tLocations('map.dialog.search')}
                                onEnter={fetchCoordinates}
                                rightComponent={
                                    <IconButton
                                        sx={{height: '35px'}}
                                        loading={isPending}
                                        onClick={fetchCoordinates}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                }
                            />
                            <SubmitButton
                                variant="outlined"
                                onClick={onSearchSubmit}
                            >
                                {tLocations('map.dialog.submit')}
                            </SubmitButton>
                        </TextFieldContainer>
                    </If>
                </Container>
            </MainContainer>
        );
    },
);

type MapClickHandlerProps = {
    onClick?: (event: LeafletMouseEvent) => void;
    onZoom?: (event: LeafletEvent) => void;
    onPositionChanged?: (latlng: LatLng) => void;
};
const MapClickHandler = ({
    onClick,
    onZoom,
    onPositionChanged,
}: MapClickHandlerProps) => {
    useMapEvent('click', onClick);
    useMapEvent('zoom', onZoom);
    const map = useMapEvent('moveend', () =>
        onPositionChanged?.(map.getCenter()),
    );
    return null;
};

const MainContainer = styled('div')`
    display: flex;
    width: 100%;
    height: 100%;
`;

const Container = styled('div')`
    flex: 1;
    position: relative;
    display: flex;
`;

const StyledMapContainer = styled(MapContainer)`
    width: 100%;
    height: 100%;
`;

const TextFieldContainer = styled('div')`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
    padding: 15px;
    width: 450px;
    max-width: 80%;
    background-color: white;
    z-index: 1000;
    border-radius: 5px;
    gap: 5px;
`;

const SubmitButton = styled(Button)`
    margin-top: auto;
`;
