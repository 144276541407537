import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {fetchElementByDocumentId} from '../../utils/firestoreDocumentUtils/firestoreDocumentUtils';
import {
    BackNavigation,
    CustomCircularProgress,
} from '../../common/components/index';
import ElementDetailsBox from '../../components/ElementDetails/ElementDetailsBox';
import styles from '../shared/mobileStyle.module.css';
import {ELEMENTS} from '../../constants/routes';
import {useParams} from 'react-router-dom';
import {useAppSelector} from '../../store/hooks';
import {Element} from '../../models';

export const ElementDetails = () => {
    const [element, setElement] = useState<Element | null>(null);

    const {t} = useTranslation('elements');

    const eventBased = useAppSelector(
        state => !!state.auth.organizationData?.eventBased,
    );

    const {id} = useParams<{id: string}>();

    useEffect(() => {
        fetchElementByDocumentId(id, setElement);
    }, []);

    if (!element) {
        return <CustomCircularProgress />;
    }

    return (
        <div className={styles.mobileBackground}>
            <div className={styles.row}>
                <BackNavigation
                    text={
                        eventBased
                            ? t('details.eventBased.backNav')
                            : t('details.default.backNav')
                    }
                    header={element.name}
                    backUrl={ELEMENTS}
                />
            </div>
            <ElementDetailsBox
                //@ts-expect-error
                element={element}
                fetchUpdatedElement={fetchElementByDocumentId}
            />
        </div>
    );
};
