import {
    useState,
    useContext,
    Dispatch,
    ChangeEvent,
    SetStateAction,
} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import isEqual from 'react-fast-compare';
import {updateUser, deleteUser} from '../../store/action/user';
import {
    Box,
    CustomButton,
    CustomTextField,
    RolePicker,
    CustomCircularProgress,
} from '../../common/components';
import {USERS} from '../../constants/routes';
import styles from './UserDetailsBox.module.css';
import {NotificationContext} from '../../context/notifications';
import BranchPicker, {
    PickerItem,
} from '../../common/components/branchPicker/BranchPicker';
import {Role} from '../../constants/roles';
import {TYPES} from '../../constants/error';
import PasswordManagerModal from '../PasswordManagerModal/PasswordManagerModal';
import {getUserRole} from '../../store/action/authHelpers';
import {useAppSelector} from '../../store/hooks';
import {PhoneInput} from '../../common/components/PhoneInput/PhoneInput';
import {UserDetails, UserRole} from '../../models';
import {SelectOption} from '../../common/components/Select/CustomSelect';

type Props = {
    user: UserDetails;
    disabled?: boolean;
};
export const UserDetailsBox = ({user, disabled}: Props) => {
    const history = useHistory();
    const {t: tUsers} = useTranslation('users');
    const notificationSystem = useContext(NotificationContext);

    const branches = useAppSelector(state => state.branch.branches);

    const [name, setName] = useState(user.name);
    const [surname, setSurname] = useState(user.surname);
    const [email, setEmail] = useState(user.email);
    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber || '');
    const [role, setRole] = useState<Role>(getUserRole(user));
    const [uid] = useState(user.uid);
    const [selectedBranches, setSelectedBranches] = useState(user.branches);
    const [loading, setLoading] = useState(false);
    const [passwordChangeModalVisible, setPasswordChangeModalVisible] =
        useState(false);

    const isUserAdmin = () => getUserRole() === Role.ADMIN;

    const showNotification = (message: string, type: string) => {
        notificationSystem?.addNotification({message, type});
    };

    const handleFieldChange =
        (setter: Dispatch<SetStateAction<string>>) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            setter(event.target.value);
        };

    const handleRoleChange = ({value}: SelectOption) => {
        const newRole = value as Role;
        setRole(newRole);
        setSelectedBranches(
            newRole === Role.ADMIN ? branches : selectedBranches,
        );
    };

    const handleBranchChange = (selected: PickerItem[]) => {
        setSelectedBranches(
            selected
                ? selected.map(branch => ({
                      id: branch.value,
                      name: branch.label,
                  }))
                : [],
        );
    };

    const handleUpdateUser = () => {
        setLoading(true);
        const updatedUser: UserDetails = {
            ...user,
            name,
            surname,
            email,
            branches: selectedBranches,
            phoneNumber,
            roles: {[role.toLowerCase()]: true} as Record<UserRole, boolean>,
        };
        updateUser(
            uid,
            updatedUser,
            () => {
                setLoading(false);
                showNotification(
                    tUsers('notifications.details.userUpdateSuccess'),
                    'success',
                );
                history.push(USERS);
            },
            () => {
                setLoading(false);
                showNotification(
                    tUsers('notifications.details.userUpdateError'),
                    TYPES.error,
                );
            },
        );
    };

    const handleDeleteUser = () => {
        setLoading(true);
        deleteUser(
            uid,
            () => {
                setLoading(false);
                showNotification(
                    tUsers('notifications.details.userDeleteSuccess'),
                    'success',
                );
                history.push(USERS);
            },
            error => {
                setLoading(false);
                showNotification(tUsers(error), TYPES.error);
            },
        );
    };

    const isNotChangedOrEmpty = () =>
        (name === user.name &&
            surname === user.surname &&
            email === user.email &&
            phoneNumber === user.phoneNumber &&
            isEqual(selectedBranches, user.branches) &&
            role.toLowerCase() === Object.keys(user.roles)[0]) ||
        !name ||
        !surname ||
        !email ||
        selectedBranches.length === 0 ||
        (!!phoneNumber && phoneNumber.length !== 9);

    return (
        <>
            {loading && <CustomCircularProgress />}
            {isUserAdmin() && (
                <PasswordManagerModal
                    open={passwordChangeModalVisible}
                    onClose={() => setPasswordChangeModalVisible(false)}
                    userId={uid}
                />
            )}
            <Box className={styles.detailsBox}>
                <div className={styles.userDetails}>
                    <CustomTextField
                        className={styles.textField}
                        label={tUsers('dialog.name')}
                        placeholder={tUsers('dialog.namePlaceholder')}
                        value={name}
                        onChange={handleFieldChange(setName)}
                    />
                    <CustomTextField
                        className={styles.textField}
                        label={tUsers('dialog.surname')}
                        placeholder={tUsers('dialog.surnamePlaceholder')}
                        value={surname}
                        onChange={handleFieldChange(setSurname)}
                    />
                    <CustomTextField
                        className={styles.textField}
                        label={tUsers('dialog.email')}
                        placeholder={tUsers('dialog.emailPlaceholder')}
                        value={email}
                        onChange={handleFieldChange(setEmail)}
                    />

                    <PhoneInput
                        label={tUsers('dialog.phoneNumber')}
                        placeholder={tUsers('dialog.phoneNumberPlaceholder')}
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                    />

                    <RolePicker
                        selectedRole={role}
                        handleChange={handleRoleChange}
                        menuPlacement="top"
                    />
                    <BranchPicker
                        handleChange={handleBranchChange}
                        branches={branches}
                        selectedBranches={selectedBranches}
                        menuPlacement="top"
                        multi
                        disabled={role === Role.ADMIN}
                    />
                </div>
                <section className={styles.buttons}>
                    <CustomButton
                        className={`${styles.btn} ${styles.deleteBtn}`}
                        text={tUsers('dialog.deleteBtn')}
                        contained={false}
                        onClick={handleDeleteUser}
                        disabled={loading}
                    />
                    {isUserAdmin() && (
                        <CustomButton
                            className={`${styles.btn} ${styles.changePasswordBtn}`}
                            text={tUsers('dialog.changePasswordBtn')}
                            contained={false}
                            onClick={() => setPasswordChangeModalVisible(true)}
                            disabled={loading}
                        />
                    )}
                    <CustomButton
                        className={`${styles.btn} ${styles.saveBtn}`}
                        text={tUsers('dialog.saveBtn')}
                        disabled={loading || isNotChangedOrEmpty()}
                        onClick={handleUpdateUser}
                    />
                </section>
            </Box>
        </>
    );
};
