import {useQuery} from '@tanstack/react-query';
import {QueryKey} from '../query';
import firebase from 'firebase/compat/app';
import {locations} from '../../constants/endpoints';
import {Location} from '../../models';

export const useLocationsQuery = () => {
    return useQuery({
        queryKey: [QueryKey.LOCATIONS],
        queryFn: getLocations,
    });
};

const getLocations = async () => {
    const collection = await firebase.firestore().collection(locations()).get();
    return collection.docs.map(doc => doc.data() as Location);
};
