import {getI18n} from 'react-i18next';

export const getTableHeaderRow = hasCategoriesFeature => {
    const {t} = getI18n();
    let tableHeaders: string[] = [
        t('table.columns.branch', {ns: 'pdfGenerator'}),
        t('table.columns.location', {ns: 'pdfGenerator'}),
        t('table.columns.element', {ns: 'pdfGenerator'}),
        t('table.columns.description', {
            ns: 'pdfGenerator',
        }),
        t('table.columns.creationDate', {
            ns: 'pdfGenerator',
        }),
        t('table.columns.issueReturnDate', {
            ns: 'pdfGenerator',
        }),
        t('table.columns.closedDate', {
            ns: 'pdfGenerator',
        }),
        t('table.columns.status', {
            ns: 'pdfGenerator',
        }),
    ];

    if (hasCategoriesFeature) {
        tableHeaders.push(
            t('table.columns.category', {
                ns: 'pdfGenerator',
            }),
        );
    }

    tableHeaders = [
        ...tableHeaders,
        t('table.columns.reporter', {
            ns: 'pdfGenerator',
        }),
        t('table.columns.reporterPhoneNumber', {
            ns: 'pdfGenerator',
        }),
        t('table.columns.employee', {
            ns: 'pdfGenerator',
        }),
        t('table.columns.solution', {
            ns: 'pdfGenerator',
        }),
        t('table.columns.reactionTime', {
            ns: 'pdfGenerator',
        }),
        t('table.columns.executionTime', {
            ns: 'pdfGenerator',
        }),
    ];

    return tableHeaders;
};
