import {Issue, ISSUE_STATUS, IssuePriority} from '../../models/issue/issue';
import {
    diffTimeInMin,
    MINS_PER_FOUR_HOURS,
    MINS_PER_DAY,
    MINS_PER_THREE_DAYS,
} from '../date/dateUtils';

export const isOutdated = (
    priority: IssuePriority,
    history: Issue['history'],
    status: Issue['status'],
) => {
    const statusChangeDate =
        status !== ISSUE_STATUS.inReview
            ? findDateOfLastStatusChange(history)
            : new Date();
    if (priority === 'low')
        return (
            diffTimeInMin(new Date(), statusChangeDate) > MINS_PER_THREE_DAYS
        );
    if (priority === 'medium')
        return diffTimeInMin(new Date(), statusChangeDate) > MINS_PER_DAY;
    if (priority === 'high')
        return (
            diffTimeInMin(new Date(), statusChangeDate) > MINS_PER_FOUR_HOURS
        );
    return false;
};

const findDateOfLastStatusChange = history =>
    history.reduce((previousValue, currentValue) => {
        if (currentValue.changes.status && previousValue < currentValue.date) {
            return currentValue.date;
        }
    }, null);
