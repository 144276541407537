import {DependencyList, EffectCallback, useEffect, useRef} from 'react';

export const useChangeEffect = (
    effect: EffectCallback,
    deps: DependencyList = [],
) => {
    const first = useRef(true);

    useEffect(() => {
        if (first.current) {
            first.current = false;

            return;
        }

        return effect();
    }, deps);
};
