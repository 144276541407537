import React from 'react';
import styles from './DrawerItems.module.css';
import archiveSrc from '../../../static/images/icons/archive.svg';
import issueSrc from '../../../static/images/icons/folder-file-1.svg';
import usersSrc from '../../../static/images/icons/multiple-neutral-2.svg';
import reportSrc from '../../../static/images/icons/presentation-board-graph.svg';
import elementsSrc from '../../../static/images/icons/task-list-approve.svg';
import locationSrc from '../../../static/images/icons/pin-1.svg';
import taskSrc from '../../../static/images/icons/tasks_list.svg';
import branchSrc from '../../../static/images/icons/buildings.svg';
import categorySrc from '../../../static/images/icons/category.svg';
import crudSrc from '../../../static/images/icons/crud.svg';
import settingsSrc from '../../../static/images/icons/settings.svg';

const archive = () => (
    <img className={styles.draverIcon} alt="" src={archiveSrc} />
);

const issue = () => <img className={styles.draverIcon} alt="" src={issueSrc} />;

const users = () => <img className={styles.draverIcon} alt="" src={usersSrc} />;

const report = () => (
    <img className={styles.draverIcon} alt="" src={reportSrc} />
);

const elements = () => (
    <img className={styles.draverIcon} alt="" src={elementsSrc} />
);

const location = () => (
    <img className={styles.draverIcon} alt="" src={locationSrc} />
);

const task = () => <img className={styles.draverIcon} alt="" src={taskSrc} />;

const branch = () => (
    <img className={styles.draverIcon} alt="" src={branchSrc} />
);

const category = () => (
    <img className={styles.draverIcon} alt="" src={categorySrc} />
);

const settings = () => <img className={styles.draverIcon} alt="" src={settingsSrc} />;

const crud = () => <img className={styles.draverIcon} alt="" src={crudSrc} />;

export default {
    ArchiveIcon: archive,
    IssuesIcon: issue,
    LocationIcon: location,
    ReportsIcon: report,
    UsersIcon: users,
    ElementsIcon: elements,
    TasksIcon: task,
    BranchesIcon: branch,
    CategoriesIcon: category,
    SettingsIcon: settings,
    CRUDIcon: crud,
};
