import {Link} from 'react-router-dom';
import {LOGIN} from '../../constants/routes';
import {WithUser} from '../../hoc/User/WithUser';

export const UserStatus = () => (
    <WithUser>
        <div>
            <Link to={LOGIN} />
        </div>
    </WithUser>
);
