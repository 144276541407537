import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import packageJson from '../package.json';

const {database} = packageJson;

const databaseType = Object.keys(database).find(field => database[field]);
const config = {
    develop: {
        apiKey: 'AIzaSyCrDJIVNZM-MPtBfomia7qfKB-EwBe0nAI',
        authDomain: 'usterka-dev-bf1e0.firebaseapp.com',
        projectId: 'usterka-dev-bf1e0',
        storageBucket: 'usterka-dev-bf1e0.appspot.com',
        messagingSenderId: '625912086924',
        appId: '1:625912086924:web:2c09330939bbc199af56a9',
        measurementId: 'G-X65XQGFKB3',
    },
    uat: {
        apiKey: 'AIzaSyAHyLDNiSc-GCQOBzh81CIhOSnJyYOROvA',
        authDomain: 'usterka-uat-fff61.firebaseapp.com',
        projectId: 'usterka-uat-fff61',
        storageBucket: 'usterka-uat-fff61.appspot.com',
        messagingSenderId: '835535802422',
        appId: '1:835535802422:web:a68facc4e87450e49dc897',
    },
    production: {
        apiKey: 'AIzaSyAxA1uR3LQqVFg0NdqYcYa7KEOE-D2ZRZg',
        authDomain: 'usterka-prod.firebaseapp.com',
        projectId: 'usterka-prod',
        storageBucket: 'usterka-prod.appspot.com',
        messagingSenderId: '377942379158',
        appId: '1:377942379158:web:d1e33f81574b3ae3b950b7',
        measurementId: 'G-V3WRPSNJFE',
    },
};

if (databaseType) {
    firebase.initializeApp(config[databaseType]!);
} else {
    console.error('Invalid Firebase database type');
}
