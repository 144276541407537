import React from 'react';
import styles from './customCircularProgress.module.css';
import {CircularProgress} from '@mui/material';

const customCircularProgress = () => (
    <div className={styles.circle}>
        <CircularProgress />
    </div>
);

export default customCircularProgress;
