import {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import ArchivedIssuesList from '../../components/Issues/ArchivedIssueList/ArchivedIssueList';
import {fetchIssues} from '../../store/action';
import {
    ListTitle,
    SearchField,
    CustomCircularProgress,
} from '../../common/components';
import {
    compareIssueWithQueryString,
    getSearchTextFromQueryParams,
} from '../../utils/filtering/filtering';
import {
    getComparator,
    issueClosedDateComparator,
} from '../../utils/sorting/sorting';
import {DESC} from '../../constants/sortingDirections';
import ArchivedIssuesService from '../../services/ArchivedIssuesService';
import {AppStorage, StorageItem} from '../../storage/AppStorage';
import {FilterIssueColumnDialog} from '../../components/Issues/FilterIssueColumnDialog/FilterIssueColumnDialog';
import {ArchiveColumn} from '../../components/Issues/ArchivedIssueList/ArchiveIssueList.types';
import {RootState} from '../../store/configureStore';

type Props = {
    organizationData: any;
    qrCodeData: any;
    fetchIssues: any;
    archivedIssues: any[];
    selectedBranches: any[];
    userData: any;
    t: any;
    location?: Location;
    history?: any;
    fetchArchivedIssues: (snapshot: any, callback: any) => void;
};

class ArchivedIssuePage extends Component<Props> {
    state = {
        indicateActivity: true,
        columnFilterDialogVisible: false,
        visibleColumns:
            AppStorage.get(StorageItem.columnsFilterArchive) ||
            Object.values(ArchiveColumn),
    };

    unsubscribeArchivedIssues;

    async componentDidMount() {
        await this.subscribeOnArchivedIssues();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
            prevProps.selectedBranches.length
        ) {
            this.unsubscribeArchivedIssues && this.unsubscribeArchivedIssues();
            await this.subscribeOnArchivedIssues();
        }
    }

    subscribeOnArchivedIssues = async () => {
        this.unsubscribeArchivedIssues =
            await ArchivedIssuesService.subscribeOnArchivedIssues(
                this.props.userData,
                this.props.organizationData,
                this.props.selectedBranches.map(branch => branch.id),
                this.setActivityIndicator,
                this.props.fetchArchivedIssues,
            );
    };

    componentWillUnmount() {
        this.unsubscribeArchivedIssues();
    }

    onSearchTextChangeHandler = event => {
        const params = new URLSearchParams({search: event.target.value});
        this.props.history.replace({
            pathname: this.props.location?.pathname,
            search: params.toString(),
        });
    };

    filterIssues = issues => {
        const searchString = getSearchTextFromQueryParams(
            this.props.location?.search,
        );
        return issues.filter(
            issue => issue && compareIssueWithQueryString(issue, searchString),
        );
    };

    sortIssues = issues =>
        [...issues].sort(getComparator(issueClosedDateComparator, DESC));

    setActivityIndicator = showIndicator => {
        this.setState({indicateActivity: showIndicator});
    };

    openFilterIssueColumnFilter = () => {
        this.setState({columnFilterDialogVisible: true});
    };

    onFilterIssueColumnSubmit = (payload: ArchiveColumn[]) => {
        AppStorage.set(StorageItem.columnsFilterArchive, payload);
        this.setState({
            columnFilterDialogVisible: false,
            visibleColumns: payload,
        });
    };

    onFilterIssueColumnClose = () => {
        this.setState({columnFilterDialogVisible: false});
    };

    render() {
        const issues = this.filterIssues(this.props.archivedIssues);
        const sortedIssues = this.sortIssues(issues);
        const searchText = getSearchTextFromQueryParams(
            this.props.location?.search,
        );
        const {t} = this.props;

        const archivedIssueList = this.state.indicateActivity ? (
            <CustomCircularProgress />
        ) : (
            <ArchivedIssuesList
                issues={sortedIssues}
                onIssueClick={this.onIssueClickHandler}
                visibleColumns={this.state.visibleColumns}
            />
        );
        return (
            <>
                <ListTitle title={t('main.title')} />
                <SearchField
                    placeholder={t('main.searchPlaceholder')}
                    onSearchTextChange={this.onSearchTextChangeHandler}
                    searchText={searchText}
                    onSettingsIconClick={this.openFilterIssueColumnFilter}
                />
                {archivedIssueList}

                <FilterIssueColumnDialog
                    variant="archive"
                    visibleColumns={this.state.visibleColumns}
                    visible={this.state.columnFilterDialogVisible}
                    onSubmit={this.onFilterIssueColumnSubmit}
                    handleClose={this.onFilterIssueColumnClose}
                />
            </>
        );
    }

    onIssueClickHandler = issueId => () => {
        this.props.history.push(`/issue/archived/${issueId}`, {
            search: this.props.location?.search,
        });
    };
}

const mapStateToProps = (state: RootState) => ({
    archivedIssues: state.issue.archivedIssues,
    selectedBranches: state.branch.selectedBranches,
    userData: state.auth.userData,
    organizationData: state.auth.organizationData,
});

const mapDispatchToProps = dispatch => ({
    fetchArchivedIssues: (snapshot, callback) =>
        dispatch(fetchIssues(snapshot, true, callback)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation('archive')(ArchivedIssuePage));
