import {SET_SELECTED_BRANCHES, SET_BRANCHES} from '../action/actionTypes';
import {Branch} from '../../models/branch';
import {SetBranchesAction, SetSelectedBranchesAction} from '../action';

type State = {
    selectedBranches: Branch[];
    branches: Branch[];
};

const initialState: State = {
    selectedBranches: [],
    branches: [],
};

type Action = SetSelectedBranchesAction | SetBranchesAction;

const reducer = (state = initialState, action: Action): State => {
    switch (action.type) {
        case SET_SELECTED_BRANCHES:
            return {
                ...state,
                selectedBranches: action.selectedBranches,
            };
        case SET_BRANCHES:
            return {
                ...state,
                branches: action.branches,
            };
        default:
            return state;
    }
};

export default reducer;
