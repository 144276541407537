import React from 'react';
import classNames from 'classnames';
import styles from './ListItem.module.css';
import TrashIcon from '../../../../static/images/icons/trash.svg';
import {TableCell, TableRow} from '@mui/material';

type Props = {
    className?: string;
    columns?: string[];
    deletable?: boolean;
    onClick?: () => void;
    onDelete?: () => void;
    children: React.ReactNode;
};

const ListItem = (props: Props) => (
    <TableRow
        classes={{root: classNames(styles.listItem, props.className)}}
        hover
    >
        {React.Children.map(props.children, (child, index) => {
            const classForCell = props.columns && styles[props.columns[index]];
            if (!child) {
                return null;
            }

            return (
                <TableCell
                    classes={{
                        root: classNames(styles.cell, classForCell),
                    }}
                    onClick={props.onClick}
                >
                    {child}
                </TableCell>
            );
        })}
        {props.deletable && (
            <td className={styles.cell} onClick={props.onDelete}>
                <img
                    src={TrashIcon}
                    className={styles.bin}
                    alt="Usuń element"
                />
            </td>
        )}
    </TableRow>
);

export default ListItem;
