import React, {Component} from 'react';
import {ASC, DESC} from '../../../constants/sortingDirections';
import ListHeader from './listHeader/ListHeader';
import {descendingSort} from '../../../utils/StringUtils/StringUtils';
import {sortRows, getComparator} from '../../../utils/sorting/sorting';
import styles from './List.module.css';
import ListColumnCreator from './listColumn/ListColumnCreator';
import {connect} from 'react-redux';
import {updateSortedIssuesIds} from '../../../store/action';
import {Table, TableBody} from '@mui/material';
import {CustomScrollbar} from '../CustomScrollbar/CustomScrollbar';
import {AppStorage, StorageItem} from '../../../storage/AppStorage';

type Props = {
    className?: string;
    columns: ListColumnCreator[];
    rows: any[];
    rowTemplate: (row: any) => React.ReactNode;
    name: string;
    updateSortedIssuesIds: (sortedIds: string[]) => void;
};

type State = {
    sortDirection: string;
    orderBy: string;
    openedFilterNumber: number;
    page?: number;
};

const PER_PAGE = 100;

class List extends Component<Props, State> {
    state = {
        sortDirection: DESC,
        orderBy: '',
        openedFilterNumber: -1,
        page: 1,
    };

    customComparator;

    componentDidMount(): void {
        const storageKey = AppStorage.getDynamicKey(
            StorageItem.tableColumnsSort,
            this.props.name,
        );

        const cachedSort = AppStorage.get(storageKey);

        this.setState(initialState => ({
            ...initialState,
            ...cachedSort,
        }));

        const column = this.props.columns.find(
            item => item.id === cachedSort?.orderBy,
        );

        this.customComparator = column?.customComparator;
    }

    componentDidUpdate() {
        const sorted = this.getSortedRows()?.map(issue => issue?.id) || [];
        this.props.updateSortedIssuesIds(sorted);
    }

    getSortedRows = () => {
        const {sortDirection, orderBy} = this.state;
        const {columns, rows} = this.props;

        if (orderBy) {
            const accessor =
                orderBy &&
                columns.find(item => item.id === orderBy)?.accessAttribute;

            if (!accessor) {
                return [...rows];
            }

            return sortRows(
                this.getSortingComparator(sortDirection),
                accessor,
                rows,
            );
        } else {
            const storageKey = AppStorage.getDynamicKey(
                StorageItem.tableColumnsSort,
                this.props.name,
            );

            const cachedSort = AppStorage.get(storageKey);

            if (!cachedSort || !cachedSort.orderBy) {
                return [...rows];
            }

            const accessor =
                cachedSort.orderBy &&
                columns.find(item => item.id === cachedSort.orderBy)
                    ?.accessAttribute;

            if (!accessor) {
                return [...rows];
            }

            if (cachedSort?.orderBy) {
                return sortRows(
                    this.getSortingComparator(cachedSort.sortDirection),
                    accessor,
                    rows,
                );
            }
        }

        return [];
    };

    onFilterIconClick = index => () => {
        this.setState(prevState => ({
            openedFilterNumber:
                prevState.openedFilterNumber === index ? -1 : index,
        }));
    };

    changeOrder = (property, customComparator) => () => {
        const orderBy = property;
        let sortDirection = DESC;

        if (
            this.state.orderBy === property &&
            this.state.sortDirection === DESC
        ) {
            sortDirection = ASC;
        }

        this.customComparator = customComparator;
        this.setState({sortDirection, orderBy});

        const storageKey = AppStorage.getDynamicKey(
            StorageItem.tableColumnsSort,
            this.props.name,
        );

        AppStorage.set(storageKey, {sortDirection, orderBy});
    };

    getSortingComparator(sortDirection) {
        return getComparator(
            this.customComparator ? this.customComparator : descendingSort,
            sortDirection,
        );
    }

    onScrollReachedEnd = () => {
        this.setState({page: this.state.page + 1});
    };

    render() {
        const {sortDirection, orderBy, openedFilterNumber} = this.state;
        const {columns, rowTemplate} = this.props;
        const sortedRows = this.getSortedRows().slice(
            0,
            this.state.page * PER_PAGE,
        );

        return (
            <div
                className={`${styles.list} ${this.props.className} ${styles.noDisplayMobile}`}
            >
                {columns.length ? (
                    <CustomScrollbar
                        onScrollReachedEnd={this.onScrollReachedEnd}
                        onScrollReachedEndThreshold={0.8}
                    >
                        <Table>
                            <ListHeader
                                columns={columns}
                                sortDirection={sortDirection}
                                orderBy={orderBy}
                                sortHandler={this.changeOrder}
                                onFilterIconClick={this.onFilterIconClick}
                                openedFilterNumber={openedFilterNumber}
                            />
                            <TableBody>
                                {sortedRows?.map(rowTemplate)}
                            </TableBody>
                        </Table>
                    </CustomScrollbar>
                ) : (
                    'Loading...'
                )}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    updateSortedIssuesIds: sortedIds =>
        dispatch(updateSortedIssuesIds(sortedIds)),
});

export default connect(null, mapDispatchToProps)(List);
