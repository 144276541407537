import axios from '../../config/axios/axiosConfig';
import {
    IMPORT_ENVIRONMENT,
    DELETE_ENVIRONMENT,
    UPDATE_ENVIRONMENT,
} from '../../constants/CloudFunctions';
import {
    ISSUES_ON_MAP,
    SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE,
    SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE,
} from '../../constants/environment';
import {Organization} from '../../models';

const IMPORT_TIMEOUT = 300000;

type SendDataToImport = {
    environmentName: string;
    environmentFile: File;
} & Organization;

export const sendDataToImport = ({
    environmentName,
    environmentFile,
    reporterCanSeeAllIssues = false,
    sendEmailWithNotificationToUserAfterAssignToIssue = false,
    showRecentlyArchivedIssuesOnIssuesList = false,
    sendEmailWithNotificationToAdminsOnIssueCreate = false,
    sendEmailWithNotificationToSupervisorsOnIssueCreate = false,
    isIssuesCategoriesFeatureEnabled = false,
    maintainerHasNoPermissionToAssignUsersToIssue = false,
    supervisorHasAccessToTasks = false,
    eventBased = false,
    issueExpiration = false,
    reporterHasNoPermissionToEditIssueDescription = false,
    maintainerHasNoPermissionToEditIssueDescription = false,
    maintainerHasNoPermissionToAddIssue = false,
    reporterCanSeeIssueSolution = false,
    maintainerGetPushOnAllIssuesCreated = false,
    supervisorAndMaintainerGetChangeMail = false,
    reporterGetChangeMail = false,
    smsLastResetTimestamp = 0,
    smsSentCount = 0,
    smsLimitCount = 0,
    smsNotifications = false,
    issuesOnMap = false,
}: SendDataToImport) => {
    const formData = new FormData();

    formData.append('file', environmentFile);
    formData.append('environmentName', environmentName);
    formData.append(
        'reporterCanSeeAllIssues',
        String(+reporterCanSeeAllIssues),
    );
    formData.append(
        'sendEmailWithNotificationToUserAfterAssignToIssue',
        String(+sendEmailWithNotificationToUserAfterAssignToIssue),
    );
    formData.append(
        'showRecentlyArchivedIssuesOnIssuesList',
        String(+showRecentlyArchivedIssuesOnIssuesList),
    );
    formData.append(
        SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE,
        String(+sendEmailWithNotificationToAdminsOnIssueCreate),
    );
    formData.append(
        SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE,
        String(+sendEmailWithNotificationToSupervisorsOnIssueCreate),
    );
    formData.append(
        'isIssuesCategoriesFeatureEnabled',
        String(+isIssuesCategoriesFeatureEnabled),
    );
    formData.append(
        'maintainerHasNoPermissionToAssignUsersToIssue',
        String(+maintainerHasNoPermissionToAssignUsersToIssue),
    );
    formData.append(
        'supervisorHasAccessToTasks',
        String(+supervisorHasAccessToTasks),
    );
    formData.append('eventBased', String(+eventBased));
    formData.append('issueExpiration', String(+issueExpiration));
    formData.append(
        'reporterHasNoPermissionToEditIssueDescription',
        String(+reporterHasNoPermissionToEditIssueDescription),
    );
    formData.append(
        'maintainerHasNoPermissionToEditIssueDescription',
        String(+maintainerHasNoPermissionToEditIssueDescription),
    );
    formData.append(
        'maintainerHasNoPermissionToAddIssue',
        String(+maintainerHasNoPermissionToAddIssue),
    );
    formData.append(
        'reporterCanSeeIssueSolution',
        String(+reporterCanSeeIssueSolution),
    );
    formData.append(
        'maintainerGetPushOnAllIssuesCreated',
        String(+maintainerGetPushOnAllIssuesCreated),
    );

    formData.append(
        'supervisorAndMaintainerGetChangeMail',
        String(+supervisorAndMaintainerGetChangeMail),
    );

    formData.append('reporterGetChangeMail', String(+reporterGetChangeMail));

    formData.append('smsNotifications', String(+smsNotifications));

    formData.append('smsSentCount', String(smsSentCount));

    formData.append('smsLimitCount', String(smsLimitCount));

    formData.append('smsLastResetTimestamp', String(smsLastResetTimestamp));

    formData.append(ISSUES_ON_MAP, String(+issuesOnMap));

    return axios.post(IMPORT_ENVIRONMENT, formData, {timeout: IMPORT_TIMEOUT});
};

type SendDataToUpdate = {
    oldEnvironmentName: string;
    newEnvironmentName: string;
    environmentFile?: File;
    overwriteEnvironment?: boolean;
} & Organization;

export const sendDataToUpdate = ({
    oldEnvironmentName,
    newEnvironmentName,
    environmentFile,
    reporterCanSeeAllIssues = false,
    sendEmailWithNotificationToUserAfterAssignToIssue = false,
    showRecentlyArchivedIssuesOnIssuesList = false,
    sendEmailWithNotificationToAdminsOnIssueCreate = false,
    sendEmailWithNotificationToSupervisorsOnIssueCreate = false,
    isIssuesCategoriesFeatureEnabled = false,
    maintainerHasNoPermissionToAssignUsersToIssue = false,
    supervisorHasAccessToTasks = false,
    eventBased = false,
    issueExpiration = false,
    reporterHasNoPermissionToEditIssueDescription = false,
    maintainerHasNoPermissionToEditIssueDescription = false,
    maintainerHasNoPermissionToAddIssue = false,
    reporterCanSeeIssueSolution = false,
    maintainerGetPushOnAllIssuesCreated = false,
    supervisorAndMaintainerGetChangeMail = false,
    reporterGetChangeMail = false,
    overwriteEnvironment = false,
    smsNotifications = false,
    smsSentCount = 0,
    smsLimitCount = 0,
    smsLastResetTimestamp = 0,
    issuesOnMap = false,
}: SendDataToUpdate) => {
    const formData = new FormData();

    environmentFile && formData.append('file', environmentFile);
    formData.append('oldEnvironmentName', oldEnvironmentName);
    formData.append('newEnvironmentName', newEnvironmentName);
    formData.append(
        'reporterCanSeeAllIssues',
        String(+reporterCanSeeAllIssues),
    );
    formData.append(
        'sendEmailWithNotificationToUserAfterAssignToIssue',
        String(+sendEmailWithNotificationToUserAfterAssignToIssue),
    );
    formData.append(
        'showRecentlyArchivedIssuesOnIssuesList',
        String(+showRecentlyArchivedIssuesOnIssuesList),
    );
    formData.append(
        SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE,
        String(+sendEmailWithNotificationToAdminsOnIssueCreate),
    );
    formData.append(
        SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE,
        String(+sendEmailWithNotificationToSupervisorsOnIssueCreate),
    );
    formData.append(
        'isIssuesCategoriesFeatureEnabled',
        String(+isIssuesCategoriesFeatureEnabled),
    );
    formData.append(
        'maintainerHasNoPermissionToAssignUsersToIssue',
        String(+maintainerHasNoPermissionToAssignUsersToIssue),
    );
    formData.append(
        'supervisorHasAccessToTasks',
        String(+supervisorHasAccessToTasks),
    );
    formData.append('eventBased', String(+eventBased));
    formData.append('issueExpiration', String(+issueExpiration));
    formData.append(
        'reporterHasNoPermissionToEditIssueDescription',
        String(+reporterHasNoPermissionToEditIssueDescription),
    );
    formData.append(
        'maintainerHasNoPermissionToEditIssueDescription',
        String(+maintainerHasNoPermissionToEditIssueDescription),
    );
    formData.append(
        'maintainerHasNoPermissionToAddIssue',
        String(+maintainerHasNoPermissionToAddIssue),
    );
    formData.append(
        'reporterCanSeeIssueSolution',
        String(+reporterCanSeeIssueSolution),
    );
    formData.append(
        'maintainerGetPushOnAllIssuesCreated',
        String(+maintainerGetPushOnAllIssuesCreated),
    );

    formData.append(
        'supervisorAndMaintainerGetChangeMail',
        String(+supervisorAndMaintainerGetChangeMail),
    );

    formData.append('reporterGetChangeMail', String(+reporterGetChangeMail));

    formData.append('overwriteEnvironment', String(+overwriteEnvironment));

    formData.append('smsNotifications', String(+smsNotifications));

    formData.append('smsSentCount', String(smsSentCount));

    formData.append('smsLimitCount', String(smsLimitCount));

    formData.append('smsLastResetTimestamp', String(smsLastResetTimestamp));

    formData.append(ISSUES_ON_MAP, String(+issuesOnMap));

    return axios.post(UPDATE_ENVIRONMENT, formData, {timeout: IMPORT_TIMEOUT});
};

export const deleteEnvironment = environment =>
    axios.delete(DELETE_ENVIRONMENT, {params: {environment}});
