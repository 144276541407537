import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Dialog from '../../../common/components/Dialog/Dialog';
import {CustomTextField, ImageDropzone} from '../../../common/components';
import BranchPicker, {
    PickerItem,
} from '../../../common/components/branchPicker/BranchPicker';
import {TYPES} from '../../../constants/error';
import {
    Coordinates,
    Location,
    MAX_LOCATION_MAP_SIZE,
} from '../../../models/location';
import {getBrowserImageDimensions} from '../../../utils/getBrowserImageSize';
import {useAppSelector} from '../../../store/hooks';
import {CoordinatesPicker} from '../../../common/components/CoordinatesPicker/CoordinatesPicker';

type LocationWithoutId = Omit<Location, 'id'>;

type AddLocationDialogProps = {
    dialogTitle: string;
    handleClose: () => void;
    onAddLocation: (location: LocationWithoutId, file: File | null) => void;
    showNotification: (message: string, type: string) => void;
};

export const AddLocationDialog = ({
    dialogTitle,
    handleClose,
    onAddLocation,
    showNotification,
}: AddLocationDialogProps) => {
    const {t} = useTranslation('locations');
    const [dialogElement, setDialogElement] = useState<HTMLDivElement | null>(
        null,
    );
    const branches = useAppSelector(state => state.branch.branches);
    const organizationData = useAppSelector(
        state => state.auth.organizationData,
    );

    const [newLocationName, setNewLocationName] = useState('');
    const [branch, setBranch] = useState(branches.at(0) || null);
    const [imagePickerMapSource, setImagePickerMapSource] = useState<
        string | null
    >(null);
    const [locationMap, setLocationMap] = useState<File | null>(null);
    const [coordinates, setCoordinates] = useState<Coordinates | null>(null);

    useEffect(() => {
        if (!branch && branches.length === 1) {
            setBranch(branches[0]);
        }
    }, [branches]);

    const getLocationMapObject = async () => {
        if (!locationMap) return null;
        const imageSize = await getBrowserImageDimensions(locationMap);
        return {
            image: '',
            width: imageSize.width,
            height: imageSize.height,
            size: locationMap.size,
            name: locationMap.name,
        };
    };

    const onSubmitNewLocationHandler = async () => {
        if (!branch) {
            return;
        }

        const locationMapData = await getLocationMapObject();
        const locationToSet: LocationWithoutId = {
            name: newLocationName.trim(),
            branch,
            locationMap: locationMapData,
            coordinates,
        };
        onAddLocation(locationToSet, locationMap);
        handleClose();
    };

    const onRemoveLocationMapImage = () => {
        setLocationMap(null);
        setImagePickerMapSource(null);
    };

    const onLocationMapImageDrop = (files: File[]) => {
        const singleImage = files[0];
        if (singleImage.size > MAX_LOCATION_MAP_SIZE) {
            showNotification(
                t('notifications.dialog.tooLargePhotoError'),
                TYPES.error,
            );
            return;
        }
        setLocationMap(singleImage);
        setImagePickerMapSource(URL.createObjectURL(singleImage));
    };

    const onBranchPickerChange = ({label, value}: PickerItem) => {
        setBranch({id: value, name: label});
    };

    const submitButtonDisabled = !newLocationName || !branch;

    return (
        <Dialog
            ref={setDialogElement}
            loading={false}
            handleClose={handleClose}
            dialogTitle={dialogTitle}
            submitButtonDisabled={submitButtonDisabled}
            onSubmitHandler={onSubmitNewLocationHandler}
            submitButtonText={dialogTitle}
        >
            <BranchPicker
                handleChange={onBranchPickerChange}
                selectedBranch={branch}
                branches={branches}
                menuPortalTarget={dialogElement}
            />
            <CustomTextField
                label={
                    organizationData.eventBased
                        ? t('dialog.eventBased.name')
                        : t('dialog.default.name')
                }
                onChange={e => setNewLocationName(e.target.value)}
                fullWidth
            />
            <CoordinatesPicker
                initialValue={coordinates}
                onChange={setCoordinates}
            />
            {organizationData.isIssueLocalizationEnabled && (
                <ImageDropzone
                    label={t('details.locationMap')}
                    multiple={false}
                    onDrop={onLocationMapImageDrop}
                    imagesSrc={
                        [imagePickerMapSource].filter(Boolean) as string[]
                    }
                    onRemoveImage={onRemoveLocationMapImage}
                />
            )}
        </Dialog>
    );
};
