import {useState, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './PasswordManagerModal.module.css';
import {CustomTextField} from '../../common/components';
import Dialog from '../../common/components/Dialog/Dialog';
import {updateUserPassword} from '../../store/action';
import {NotificationContext} from '../../context/notifications';

const PasswordManagerModal = ({open, onClose, userId}) => {
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const notificationSystem = useContext(NotificationContext);

    const {t} = useTranslation('auth');

    const changePassword = async () => {
        if (checkPasswordValid()) {
            setLoading(true);
            await updateUserPassword(userId, newPassword, onSuccess, onError);
        }
    };

    const checkPasswordValid = () => {
        let valid = true;
        if (newPassword.length < 6) {
            setError(
                t('passwordManagerModal.notifications.tooShortPasswordWarning'),
            );
            valid = false;
        }
        return valid;
    };

    const onSuccess = () => {
        setLoading(false);
        showNotification(
            t('passwordManagerModal.notifications.passwordUpdateSuccess'),
            'success',
        );
        onClose();
    };

    const onError = error => {
        setLoading(false);
        setError(error);
    };

    const newPasswordChange = event => {
        setError('');
        setNewPassword(event.target.value);
    };

    const showNotification = (message, type) => {
        if (notificationSystem) {
            notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    return open ? (
        <Dialog
            loading={loading}
            handleClose={onClose}
            dialogTitle={t('passwordManagerModal.dialog.title')}
            submitButtonDisabled={!newPassword}
            onSubmitHandler={changePassword}
            submitButtonText={t('passwordManagerModal.dialog.actionBtn')}
        >
            <CustomTextField
                className={styles.textField}
                label={t('passwordManagerModal.dialog.newPassword')}
                placeholder={t(
                    'passwordManagerModal.dialog.newPasswordPlaceholder',
                )}
                defaultValue={newPassword}
                onChange={newPasswordChange}
                type="password"
            />
            <p className={styles.errorText}>{error}</p>
        </Dialog>
    ) : null;
};

export default PasswordManagerModal;
