import firebase from 'firebase/compat/app';
import {
    AUTH_SET_USER_DATA,
    AUTH_SET_ORGANIZATION_DATA,
    USER_LOGOUT,
} from './actionTypes';
import {setUserSelectedBranches} from './branch';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';
import {AppStorage, StorageItem} from '../../storage/AppStorage';
import {UserDetails} from '../../models';
import {Dispatch} from 'react';
import {AnyAction} from 'redux';
import {store} from '../configureStore';

type AuthData = {
    login: string;
    password: string;
};

enum AuthErrorCode {
    INVALID_EMAIL = 'auth/invalid-email',
    INVALID_PASSWORD = 'auth/invalid-password',
    USER_DISABLED = 'auth/user-disabled',
    USER_NOT_FOUND = 'auth/user-not-found',
}

export const tryAuth =
    (
        authData: AuthData,
        onLoginFailed: (error: unknown) => void,
        onLoginSuccess: () => void,
    ) =>
    dispatch => {
        firebase
            .auth()
            .signInWithEmailAndPassword(authData.login, authData.password)
            .then(result => {
                if (!result.user?.uid) {
                    throw 'User not found';
                }

                fetchUserData(result.user.uid)
                    .then(doc => {
                        const userData = doc.data() as UserDetails;
                        AppStorage.set(StorageItem.userData, userData);
                        dispatch(authSetUserData(userData || null));
                        dispatch(setUserSelectedBranches(userData.branches));
                        onLoginSuccess();
                    })
                    .catch(errorHandler);
            })
            .catch(error => {
                onLoginFailedHandler(error.code, onLoginFailed);
            });
    };

const onLoginFailedHandler = (code: AuthErrorCode, onError) => {
    switch (code) {
        case AuthErrorCode.INVALID_EMAIL:
        case AuthErrorCode.INVALID_PASSWORD:
            onError('login.notifications.wrongCredentialsError');
            break;
        case AuthErrorCode.USER_DISABLED:
            onError('login.notifications.userDisabledError');
            break;
        case AuthErrorCode.USER_NOT_FOUND:
            onError('login.notifications.userNotFoundError');
            break;
        default:
            onError('login.notifications.defaultError');
    }
};

const onResetFailedHandler = (code, onError) => {
    switch (code) {
        case AuthErrorCode.INVALID_EMAIL:
        case AuthErrorCode.INVALID_PASSWORD:
            onError('resetPassword.notifications.wrongCredentialsError');
            break;
        case AuthErrorCode.USER_DISABLED:
            onError('resetPassword.notifications.userDisabledError');
            break;
        case AuthErrorCode.USER_NOT_FOUND:
            onError('resetPassword.notifications.userNotFoundError');
            break;
        default:
            onError('resetPassword.notifications.defaultError');
    }
};

export const fetchUserData = (userId: string) => {
    const userRef = firebase.firestore().collection('user').doc(userId);
    return userRef.get();
};

export const fetchOrganizationData = (organization: string) => {
    const organizationRef = firebase
        .firestore()
        .collection('organization')
        .doc(organization);
    return organizationRef.get();
};

export const sendResetPasswordEmail =
    (email: string, onError: (e: unknown) => void) =>
    (dispatch: Dispatch<AnyAction>) => {
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .catch(error => onResetFailedHandler(error.code, onError));
    };

export const logOut = () => (dispatch: Dispatch<AnyAction>) => {
    firebase
        .auth()
        .signOut()
        .then(() => {
            store.dispatch({type: USER_LOGOUT});
            AppStorage.clear();
        })
        .catch(error => {
            errorHandler(error);
        });
};

export const authSetUserData = userData => ({
    type: AUTH_SET_USER_DATA,
    userData,
});

export const authSetOrganizationData = organizationData => ({
    type: AUTH_SET_ORGANIZATION_DATA,
    organizationData,
});
