import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import IssuesList from '../../components/Issues/IssuesList/IssuesList';
import {fetchIssues} from '../../store/action';
import {IssuesPageControl} from '../../components/Issues/IssuesPageControl/IssuesPageControl';
import AddIssueDialog from '../../components/Issues/AddIssueDialog/AddIssueDialog';
import {
    compareIssueWithQueryString,
    getSearchTextFromQueryParams,
} from '../../utils/filtering/filtering';
import {CustomCircularProgress} from '../../common/components';
import IssuesService from '../../services/IssuesService';
import {countBy, debounce} from 'lodash';
import {IssuesCount} from './IssuesCount';
import {If} from '../../common/components/If';
import {FilterIssueColumnDialog} from '../../components/Issues/FilterIssueColumnDialog/FilterIssueColumnDialog';
import {
    IssueColumn,
    IssuesFilters,
} from '../../components/Issues/IssuesList/IssueList.types';
import {AppStorage, StorageItem} from '../../storage/AppStorage';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {Issue} from '../../models';
import {useToggle} from '../../hooks/useToggle';
import {useMapStore} from '../../store/zustand/useMapStore';
import {useIssuesWithLocations} from './useIssuesWithLocations';
import {styled} from '@mui/material';
import {useChangeEffect} from '../../hooks/useChangeEffect';
export const HomePage = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();

    const issues = useIssuesWithLocations(true);
    const selectedBranches = useAppSelector(
        state => state.branch.selectedBranches,
    );
    const userData = useAppSelector(state => state.auth.userData);
    const organizationData = useAppSelector(
        state => state.auth.organizationData,
    );
    const qrCodeData = useAppSelector(state => state.qrCode);

    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);

    const [columnFilterDialogVisible, openFilterDialog, closeFilterDialog] =
        useToggle(false);

    const issuesOnMapEnabled = useAppSelector(
        state => !!state.auth.organizationData.issuesOnMap,
    );
    const mapEnabled = useMapStore(
        state => state.enabled && issuesOnMapEnabled,
    );
    const isMapVisible = useMapStore(state => state.visible && mapEnabled);
    const setMapVisible = useMapStore(state => state.setVisible);

    const [indicateActivity, setIndicateActivity] = useState(!issues.length);
    const [visibleColumns, setVisibleColumns] = useState<IssueColumn[]>(
        AppStorage.get(StorageItem.columnsFilterIssues) ||
            Object.values(IssueColumn),
    );
    const [filter, setFilter] = useState<IssuesFilters>({
        open: true,
        inProgress: true,
        inReview: true,
        done: organizationData?.showRecentlyArchivedIssuesOnIssuesList || false,
        low: true,
        medium: true,
        high: true,
    });

    useChangeEffect(() => {
        const savedFilters =
            AppStorage.get(StorageItem.issuesListFilters) || {};

        setFilter(prev => ({...prev, ...savedFilters}));
        getIssues();

        if (qrCodeData.id) {
            setAddDialogOpen(true);
        }
    }, [qrCodeData.id]);

    const _getIssues = useCallback(async () => {
        await IssuesService.subscribeOnIssues(
            userData,
            organizationData,
            selectedBranches.map(branch => branch.id),
            !issues.length ? setIndicateActivity : undefined,
            (snapshot: any, callback: any) =>
                fetchIssues(snapshot, false, callback)(dispatch),
        );
    }, [userData, organizationData, selectedBranches, dispatch]);

    const getIssues = debounce(_getIssues, 500);

    useEffect(() => {
        getIssues();
    }, [selectedBranches, organizationData]);

    const filterIssues = useCallback(
        (issues: Issue[]) => {
            const searchString = getSearchTextFromQueryParams(location?.search);
            return issues?.filter(
                issue =>
                    issue &&
                    filter[issue.status] &&
                    filter[issue.priority] &&
                    (!searchString ||
                        compareIssueWithQueryString(issue, searchString)),
            );
        },
        [filter, location?.search],
    );

    const onMapIconClick = useCallback(() => {
        setMapVisible(!isMapVisible);
    }, [isMapVisible]);

    const onSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const params = new URLSearchParams({search: event.target.value});
        history.replace({
            pathname: location?.pathname,
            search: params.toString(),
        });
    };

    const onIssueClickHandler = ({id, status}: Issue) => {
        history.push(
            status === 'done' ? `/issue/archived/${id}` : `/issue/${id}`,
            {
                search: location?.search,
            },
        );
    };

    const onCheckboxClick = (field: string) => () => {
        setFilter(prev => {
            const updatedFilter = {...prev, [field]: !prev[field]};
            AppStorage.set(StorageItem.issuesListFilters, updatedFilter);
            return updatedFilter;
        });
    };

    const onFilterIssueColumnSubmit = (payload: IssueColumn[]) => {
        AppStorage.set(StorageItem.columnsFilterIssues, payload);
        setVisibleColumns(payload);
        closeFilterDialog();
    };

    const issuesCount = useMemo(() => countBy(issues, 'status'), [issues]);
    const searchText = getSearchTextFromQueryParams(location?.search);

    return (
        <Container>
            <IssuesPageControl
                onSearchTextChange={onSearchTextChange}
                onToggleAddDialogHandler={() => setAddDialogOpen(prev => !prev)}
                onFilterIconClickHandler={() => setOpenFilter(prev => !prev)}
                openFilter={openFilter}
                filterState={filter}
                onCheckboxClick={onCheckboxClick}
                onSettingsIconClick={openFilterDialog}
                showAddIssueButton={
                    !organizationData.maintainerHasNoPermissionToAddIssue ||
                    !userData?.roles.maintainer
                }
                searchText={searchText}
                additionalHeaderComponent={<IssuesCount {...issuesCount} />}
                onMapIconClick={mapEnabled ? onMapIconClick : undefined}
            />

            <If condition={addDialogOpen}>
                <AddIssueDialog
                    handleClose={() => setAddDialogOpen(false)}
                    loading={indicateActivity}
                    refreshIssueList={getIssues}
                />
            </If>

            {indicateActivity ? (
                <CustomCircularProgress />
            ) : (
                <IssuesList
                    isMapVisible={isMapVisible}
                    issues={filterIssues(issues)}
                    onIssueClick={onIssueClickHandler}
                    visibleColumns={visibleColumns}
                />
            )}

            <FilterIssueColumnDialog
                variant="issues"
                visibleColumns={visibleColumns}
                visible={columnFilterDialogVisible}
                onSubmit={onFilterIssueColumnSubmit}
                handleClose={closeFilterDialog}
            />
        </Container>
    );
};

const Container = styled('div')`
    margin-bottom: 40px;
`;
