import {
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
} from '@mui/material';
import {styled} from '@mui/system';
import classNames from 'classnames';

type ColorSet = {
    color: string;
    backgroundColor: string;
    borderColor?: string;
};

type CustomColor = 'error' | 'grey' | 'primary';

type ButtonVariant = 'text' | 'outlined' | 'contained';

const getColorSet = (customColor: CustomColor, variant: ButtonVariant) => {
    if (customColor && customColor in colorSets) {
        if (variant in colorSets[customColor]) {
            return colorSets[customColor][variant];
        }
    }
};

const colorSets: Record<CustomColor, Record<ButtonVariant, ColorSet>> = {
    error: {
        outlined: {
            color: 'var(--red)',
            backgroundColor: 'none',
            borderColor: 'var(--red)',
        },
        contained: {
            color: 'var(--red)',
            backgroundColor: 'none',
            borderColor: 'var(--red)',
        },
        text: {
            color: 'var(--red)',
            backgroundColor: 'none',
            borderColor: 'var(--red)',
        },
    },
    grey: {
        outlined: {
            color: 'var(--dark-grey)',
            backgroundColor: 'none',
            borderColor: 'var(--dark-grey)',
        },
        contained: {
            color: 'var(--dark-grey)',
            backgroundColor: 'none',
            borderColor: 'var(--dark-grey)',
        },
        text: {
            color: 'var(--dark-grey)',
            backgroundColor: 'none',
            borderColor: 'var(--dark-grey)',
        },
    },
    primary: {
        contained: {
            color: 'var(--white)',
            backgroundColor: 'var(--light-blue)',
        },
        text: {
            color: 'var(--light-blue)',
            backgroundColor: 'none',
        },
        outlined: {
            color: 'var(--light-blue)',
            backgroundColor: 'none',
        },
    },
};

const StyledButton = styled(MuiButton)<{
    variant?: ButtonVariant;
    customColor?: 'error' | 'grey' | 'primary';
}>(({customColor, variant = 'contained'}) => {
    const colorSet = customColor ? getColorSet(customColor, variant) : {};
    return {
        height: '40px',
        borderRadius: '4px',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.02em',
        textTransform: 'none',
        ...(colorSet || {}),
    };
});

type ButtonProps = {
    marginTop?: number;
    marginBottom?: number;
    className?: string;
    customColor?: 'error' | 'grey' | 'primary';
} & Omit<MuiButtonProps, 'color'>;

export const Button = ({
    marginBottom,
    marginTop,
    className,
    customColor,
    style,
    ...props
}: ButtonProps) => {
    return (
        <StyledButton
            {...props}
            style={{
                marginTop,
                marginBottom,
                ...style,
            }}
            customColor={customColor}
            className={classNames(className)}
        />
    );
};
