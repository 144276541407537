import React, {useMemo, useState} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import styles from './Status.module.css';
import {Button} from '../../../../../../common/components';
import Dialog from '../../../../../../common/components/Dialog/Dialog';
import {If} from '../../../../../../common/components/If';
import {updateIssue} from '../../../../../../store/action';
import {Issue, ISSUE_STATUS, IssueStatus} from '../../../../../../models';
import {useAppDispatch, useAppSelector} from '../../../../../../store/hooks';

type StatusProps = {
    issue?: Issue;
};

const Status = ({issue}: StatusProps) => {
    const {t} = useTranslation('issues');

    const dispatch = useAppDispatch();
    const roles = useAppSelector(state => state.auth.userData?.roles);

    const [isChangeModalVisible, setIsChangeModalVisible] = useState(false);

    const handleOpenModal = () => setIsChangeModalVisible(true);

    const handleCloseModal = () => setIsChangeModalVisible(false);

    const isAllowedToChangeStatus =
        roles?.admin || roles?.supervisor || roles?.maintainer;

    const statusOptions = useMemo(() => {
        const allStatuses = Object.values(ISSUE_STATUS);

        if (roles?.admin || roles?.supervisor) {
            return allStatuses;
        }

        if (roles?.maintainer) {
            return allStatuses.filter(item => item !== 'done');
        }

        return [];
    }, []);

    const statusLabels = useMemo(
        () => ({
            open: t('statusLabels.open'),
            inProgress: t('statusLabels.inProgress'),
            inReview: t('statusLabels.inReview'),
            done: t('statusLabels.done'),
        }),
        [t],
    );

    const onStatusChange = (newStatus: IssueStatus) => {
        updateIssue(dispatch, undefined)(
            {...issue, status: newStatus},
            issue,
            {
                new: [],
                uploaded: issue?.issueImagePaths || [],
                removed: [],
            },
            {
                new: [],
                uploaded: issue?.solutionImagePaths || [],
                removed: [],
            },
            handleCloseModal,
            handleCloseModal,
        );
    };

    return (
        <>
            <If condition={isAllowedToChangeStatus}>
                <Button
                    onClick={e => {
                        e.stopPropagation();
                        handleOpenModal();
                    }}
                >
                    <span
                        className={classNames(
                            styles.icon,
                            issue?.status && styles[issue.status],
                        )}
                    />
                    <span>
                        {issue?.status ? statusLabels[issue?.status] : ''}
                    </span>
                </Button>
            </If>
            <If condition={!isAllowedToChangeStatus}>
                <div>
                    <span
                        className={classNames(
                            styles.icon,
                            issue?.status && styles[issue.status],
                        )}
                    />
                    <span>
                        {issue?.status ? statusLabels[issue?.status] : ''}
                    </span>
                </div>
            </If>

            <If condition={isChangeModalVisible}>
                <Dialog
                    onSubmitHandler={handleCloseModal}
                    handleClose={handleCloseModal}
                    dialogTitle={t('changeStatusModal.title')}
                    cancelButtonVisible
                    confirmButtonVisible={false}
                >
                    <div
                        className={classNames(
                            styles.statusModal,
                            styles.options,
                        )}
                    >
                        {statusOptions.map((status, index) => (
                            <Button
                                key={`${status}-${index}`}
                                size={'large'}
                                onClick={() => onStatusChange(status)}
                                className={classNames(
                                    styles.statusModal,
                                    styles.optionButton,
                                )}
                            >
                                <span
                                    className={classNames(
                                        styles.icon,
                                        styles[status],
                                    )}
                                />
                                <span
                                    className={classNames(
                                        styles.mobileIcon,
                                        styles[status],
                                    )}
                                >
                                    {statusLabels[status]}
                                </span>
                            </Button>
                        ))}
                    </div>
                </Dialog>
            </If>
        </>
    );
};

export default Status;
