import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import {getI18n} from 'react-i18next';
import {Issue, ISSUE_STATUS, ISSUE_TYPE} from '../../../../models/issue/issue';
import {pdfDocumentStyles} from './pdfDocumentStyles';
import {PDFTableAmount, REPORT_TYPES} from '../../reportGenerator';

// TODO -  this file is who knows how old, we need to refactor it

export const generateIssuesReport = (
    startDate,
    endDate,
    reportType,
    issuesForReport,
    hasCategoriesFeature,
    branch,
) => {
    const {t} = getI18n();
    const document = createIssuesReport(
        t,
        issuesForReport,
        reportType,
        moment(startDate).format('DD/MM/YYYY'),
        moment(endDate).format('DD/MM/YYYY'),
        hasCategoriesFeature,
        branch,
    );
    if (document.content) {
        generatePDF(
            document,
            moment(startDate).format('DD/MM/YYYY'),
            moment(endDate).format('DD/MM/YYYY'),
        );
    }
};

const generatePDF = (document, startDate, endDate) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(document).download(`report  ${startDate}  ${endDate}`);
};

const getHeaderBasedOnReportType = (
    translate,
    reportType,
    startDate,
    endDate,
    branch,
) => {
    switch (reportType) {
        case REPORT_TYPES.branch_general:
            return `${translate('main.titleTemplatePrefix', {
                ns: 'pdfGenerator',
            })} ${startDate} ${translate('main.titleTemplateSuffix', {
                ns: 'pdfGenerator',
            })} ${endDate}
            ${translate('main.branchGeneralTitle', {
                ns: 'pdfGenerator',
                branch: branch,
            })}`;
        default:
            return `${translate('main.titleTemplatePrefix', {
                ns: 'pdfGenerator',
            })} ${startDate} ${translate('main.titleTemplateSuffix', {
                ns: 'pdfGenerator',
            })} ${endDate}`;
    }
};

const createIssuesReport = (
    translate,
    issuesForReport,
    reportType,
    startDate,
    endDate,
    hasCategoriesFeature,
    branch,
) => {
    let documentDefinition = {
        content: [],
        pageOrientation:
            reportType === REPORT_TYPES.detailed ? 'landscape' : 'portrait',
    };

    const actualIssuesExist =
        issuesForReport.actualIssues &&
        Object.keys(issuesForReport.actualIssues).length !== 0;

    const title = getHeaderBasedOnReportType(
        translate,
        reportType,
        startDate,
        endDate,
        branch?.name ?? '',
    );

    documentDefinition = addHeader(documentDefinition, true, title);

    documentDefinition = addSummaryToReportDefinition(
        documentDefinition,
        translate,
        {
            openIssuesAmount: issuesForReport.summary.openIssuesAmount ?? 0,
            inProgressIssuesAmount:
                issuesForReport.summary.inProgressIssuesAmount ?? 0,
            inReviewIssuesAmount:
                issuesForReport.summary.inReviewIssuesAmount ?? 0,
            doneIssuesAmount: issuesForReport.summary.doneIssuesAmount ?? 0,
        },
    );

    if (Object.keys(issuesForReport.archivedIssues).length !== 0) {
        documentDefinition = createIssuesReportHelper(
            translate,
            documentDefinition,
            reportType,
            issuesForReport.archivedIssues,
            ISSUE_TYPE.archived,
            false,
            translate('main.archivedIssues', {ns: 'pdfGenerator'}),
            !!actualIssuesExist,
            hasCategoriesFeature,
        );
    }

    if (actualIssuesExist) {
        documentDefinition = createIssuesReportHelper(
            translate,
            documentDefinition,
            reportType,
            issuesForReport.actualIssues,
            ISSUE_TYPE.actual,
            false,
            translate('main.currentIssues', {ns: 'pdfGenerator'}),
            false,
            hasCategoriesFeature,
        );
    }

    return documentDefinition;
};

const addSummaryToReportDefinition = (
    documentDefinition,
    translate,
    summaryData,
) => {
    const summaryTitle = {
        text: translate('summary.title', {ns: 'pdfGenerator'}),
        fontSize: 16,
        margin: [0, 10, 0, 10],
    };

    const summary = {
        ul: [
            translate('summary.openIssuesAmount', {
                ns: 'pdfGenerator',
                number: summaryData.openIssuesAmount,
            }),
            translate('summary.inProgressIssuesAmount', {
                ns: 'pdfGenerator',
                number: summaryData.inProgressIssuesAmount,
            }),
            translate('summary.inReviewIssuesAmount', {
                ns: 'pdfGenerator',
                number: summaryData.inReviewIssuesAmount,
            }),
            translate('summary.doneIssuesAmount', {
                ns: 'pdfGenerator',
                number: summaryData.doneIssuesAmount,
            }),
        ],
        margin: [0, 0, 0, 20],
    };

    documentDefinition.content = [
        ...documentDefinition.content,
        summaryTitle,
        summary,
    ];

    return documentDefinition;
};

const createIssuesReportHelper = (
    translate,
    documentDefinition,
    reportType,
    issues,
    issueType,
    isFirstPage,
    title,
    breakAfter,
    hasCategoriesFeature,
) => {
    documentDefinition = addHeader(documentDefinition, isFirstPage, title);

    const tableRows = createTableRowsForIssues(
        translate,
        issues,
        reportType,
        issueType,
        hasCategoriesFeature,
    );

    documentDefinition = addTableToDocument(
        documentDefinition,
        tableRows,
        reportType,
        breakAfter,
        hasCategoriesFeature,
    );

    return documentDefinition;
};

type Place = [string, Record<string, Issue[]>];

const createTableRowsForIssues = (
    translate,
    issuesForReport,
    reportType,
    issuesType,
    hasCategoriesFeature,
) => {
    const places = Object.entries(issuesForReport) as Place[];
    const reportTypeIsDetailedIssues = reportType === REPORT_TYPES.detailed;
    const reportTypeIsBranchIssues = reportType === REPORT_TYPES.branch_general;
    let tableRows = [
        [
            {
                text: translate('table.columns.element', {ns: 'pdfGenerator'}),
                style: pdfDocumentStyles.tableHeader,
            },
            {
                text: translate('table.columns.description', {
                    ns: 'pdfGenerator',
                }),
                style: pdfDocumentStyles.tableHeader,
            },
            {
                text: translate('table.columns.creationDate', {
                    ns: 'pdfGenerator',
                }),
                style: pdfDocumentStyles.tableHeader,
            },
        ],
    ];

    if (issuesType === ISSUE_TYPE.actual) {
        tableRows[0].push({
            text: translate('table.columns.status', {
                ns: 'pdfGenerator',
            }),
            style: pdfDocumentStyles.tableHeader,
        });
    } else {
        tableRows[0].push({
            text: translate('table.columns.closedDate', {
                ns: 'pdfGenerator',
            }),
            style: pdfDocumentStyles.tableHeader,
        });
    }

    if (reportTypeIsDetailedIssues) {
        if (hasCategoriesFeature)
            tableRows[0].push({
                text: translate('table.columns.category', {ns: 'pdfGenerator'}),
                style: pdfDocumentStyles.tableHeader,
            });

        tableRows[0] = [
            ...tableRows[0],
            {
                text: translate('table.columns.reporter', {
                    ns: 'pdfGenerator',
                }),
                style: pdfDocumentStyles.tableHeader,
            },
            {
                text: translate('table.columns.reporterPhoneNumber', {
                    ns: 'pdfGenerator',
                }),
                style: pdfDocumentStyles.tableHeader,
            },
            {
                text: translate('table.columns.employee', {ns: 'pdfGenerator'}),
                style: pdfDocumentStyles.tableHeader,
            },
            {
                text: translate('table.columns.solution', {ns: 'pdfGenerator'}),
                style: pdfDocumentStyles.tableHeader,
            },
            {
                text: translate('table.columns.reactionTime', {
                    ns: 'pdfGenerator',
                }),
                style: pdfDocumentStyles.tableHeader,
            },
            {
                text: translate('table.columns.executionTime', {
                    ns: 'pdfGenerator',
                }),
                style: pdfDocumentStyles.tableHeader,
            },
        ];
    }

    if (reportTypeIsBranchIssues)
        tableRows[0] = [
            ...tableRows[0],
            {
                text: translate('table.columns.issueSolution', {
                    ns: 'pdfGenerator',
                }),
                style: pdfDocumentStyles.tableHeader,
            },
        ];

    let colSpan = PDFTableAmount.DEFAULT;
    if (reportTypeIsDetailedIssues && hasCategoriesFeature) {
        colSpan = PDFTableAmount.DETAILED_WITH_CATEGORIES;
    } else if (reportTypeIsDetailedIssues) {
        colSpan = PDFTableAmount.DETAILED;
    } else if (reportTypeIsBranchIssues) {
        colSpan = PDFTableAmount.BRANCH_GENERAL;
    }

    places.forEach(place => {
        const placeKey = place[0];
        const placeValue = place[1];
        let index = 0;

        const placeRow = [
            {
                text: placeKey,
                style: pdfDocumentStyles.tableSubHeader,
                colSpan: colSpan,
            },
        ];
        tableRows.push(placeRow);
        const items = Object.entries(placeValue);
        items.forEach(item => {
            const itemKey = item[0];
            const issues = item[1];
            const subTable: string[][] = [];
            issues.forEach((issue, issueIndex) => {
                const user = issue.assignedTo
                    ? `${issue.assignedTo.name} ${issue.assignedTo.surname}`
                    : translate('table.cells.nullShort', {ns: 'pdfGenerator'});
                const reactionDuration =
                    issuesType === ISSUE_TYPE.archived
                        ? calcDurationsArchivedIssueHistory(
                              translate,
                              issue.history,
                          )
                        : calcDurationsActualIssueHistory(
                              translate,
                              issue.history,
                          );
                let issueRow = [
                    '',
                    issue.issueDescription || '-',
                    moment(issue.createdDate).format('DD/MM/YYYY'),
                    moment(issue.closedDate).format('DD/MM/YYYY'),
                ];

                if (issuesType === ISSUE_TYPE.actual) {
                    const status = translate(`statusLabels.${issue.status}`, {
                        ns: 'issues',
                    });
                    issueRow = [
                        '',
                        issue.issueDescription || '-',
                        moment(issue.createdDate).format('DD/MM/YYYY'),
                        status,
                    ];
                    if (reportTypeIsDetailedIssues && !hasCategoriesFeature) {
                        issueRow = [
                            '',
                            issue.issueDescription || '-',
                            moment(issue.createdDate).format('DD/MM/YYYY'),
                            status,
                            issue.reporter
                                ? `${issue.reporter.name} ${issue.reporter.surname}`
                                : '-',
                            issue.phoneNumber || '-',
                            user,
                            issue.solutionDescription || '-',
                            reactionDuration.reaction,
                            reactionDuration.execution,
                        ];
                    } else if (reportTypeIsDetailedIssues) {
                        issueRow = [
                            '',
                            issue.issueDescription || '-',
                            moment(issue.createdDate).format('DD/MM/YYYY'),
                            status,
                            issue.category?.name === 'null' ||
                            !issue.category?.name
                                ? '-'
                                : issue.category?.name,
                            issue.reporter
                                ? `${issue.reporter.name} ${issue.reporter.surname}`
                                : '-',
                            issue.phoneNumber || '-',
                            user,
                            issue.solutionDescription || '-',
                            reactionDuration.reaction,
                            reactionDuration.execution,
                        ];
                    } else if (reportTypeIsBranchIssues) {
                        issueRow = [
                            '',
                            issue.issueDescription || '-',
                            moment(issue.createdDate).format('DD/MM/YYYY'),
                            status,
                            issue.solutionDescription || '-',
                        ];
                    }
                } else if (issuesType === ISSUE_TYPE.archived) {
                    if (reportTypeIsDetailedIssues && !hasCategoriesFeature) {
                        issueRow = [
                            '',
                            issue.issueDescription || '-',
                            moment(issue.createdDate).format('DD/MM/YYYY'),
                            moment(issue.closedDate).format('DD/MM/YYYY'),
                            issue.reporter
                                ? `${issue.reporter.name} ${issue.reporter.surname}`
                                : '-',
                            issue.phoneNumber || '-',
                            user,
                            issue.solutionDescription || '-',
                            reactionDuration.reaction,
                            reactionDuration.execution,
                        ];
                    } else if (reportTypeIsDetailedIssues) {
                        issueRow = [
                            '',
                            issue.issueDescription || '-',
                            moment(issue.createdDate).format('DD/MM/YYYY'),
                            moment(issue.closedDate).format('DD/MM/YYYY'),
                            issue.category?.name === 'null' ||
                            !issue.category?.name
                                ? '-'
                                : issue.category?.name,
                            issue.reporter
                                ? `${issue.reporter.name} ${issue.reporter.surname}`
                                : '-',
                            issue.phoneNumber || '-',
                            user,
                            issue.solutionDescription || '-',
                            reactionDuration.reaction,
                            reactionDuration.execution,
                        ];
                    } else if (reportTypeIsBranchIssues) {
                        issueRow = [
                            '',
                            issue.issueDescription || '-',
                            moment(issue.createdDate).format('DD/MM/YYYY'),
                            moment(issue.closedDate).format('DD/MM/YYYY'),
                            issue.solutionDescription || '-',
                        ];
                    }
                }
                index = issueIndex;
                subTable.push(issueRow);
            });
            const itemRow = [
                {text: itemKey, rowSpan: index + 1},
                ...subTable[0].slice(1),
            ];
            tableRows.push(itemRow as any);
            subTable.shift();
            tableRows = [...tableRows, ...subTable] as any;
        });
    });
    return tableRows;
};

export const calcDurationsArchivedIssueHistory = (translate, issueHistory) => {
    let openDate = '';
    let inProgressDate = '';
    let doneDate = '';
    issueHistory.forEach(history => {
        if (history.changes.status === ISSUE_STATUS.open) {
            openDate = history.date;
        } else if (history.changes.status === ISSUE_STATUS.inProgress) {
            inProgressDate = history.date;
        } else if (history.changes.status === ISSUE_STATUS.done) {
            doneDate = history.date;
        }
    });
    const reaction = moment(inProgressDate).diff(moment(openDate), 'hours');
    const execution = moment(doneDate).diff(moment(inProgressDate), 'hours');
    const noProgressDuration = moment(doneDate).diff(moment(openDate), 'hours');

    if (inProgressDate)
        return {
            reaction: stringForDuration(
                translate,
                reaction,
                openDate,
                inProgressDate,
            ),
            execution: stringForDuration(
                translate,
                execution,
                inProgressDate,
                doneDate,
            ),
        };

    return {
        reaction: translate('table.cells.nullFull', {ns: 'pdfGenerator'}),
        execution: stringForDuration(
            translate,
            noProgressDuration,
            openDate,
            doneDate,
        ),
    };
};

export const calcDurationsActualIssueHistory = (translate, issueHistory) => {
    let openDate = '';
    let inProgressDate = '';
    let doneDate = '';
    issueHistory.forEach(history => {
        if (history.changes.status === ISSUE_STATUS.open) {
            openDate = history.date;
        } else if (history.changes.status === ISSUE_STATUS.inProgress) {
            inProgressDate = history.date;
            doneDate = '';
        } else {
            doneDate = history.date;
        }
    });
    const reaction = moment(inProgressDate).diff(moment(openDate), 'hours');
    const execution = moment(doneDate).diff(moment(inProgressDate), 'hours');
    const noProgressDuration = moment(doneDate).diff(moment(openDate), 'hours');

    if (doneDate && inProgressDate)
        return {
            reaction: stringForDuration(
                translate,
                reaction,
                openDate,
                inProgressDate,
            ),
            execution: stringForDuration(
                translate,
                execution,
                inProgressDate,
                doneDate,
            ),
        };
    if (inProgressDate) {
        return {
            reaction: stringForDuration(
                translate,
                reaction,
                openDate,
                inProgressDate,
            ),
            execution: '-',
        };
    }
    if (doneDate) {
        return {
            reaction: '-',
            execution: stringForDuration(
                translate,
                noProgressDuration,
                openDate,
                doneDate,
            ),
        };
    }
    return {
        reaction: '-',
        execution: '-',
    };
};

const stringForDuration = (translate, duration, fromDate, date) =>
    duration > 0
        ? `${duration} ${translate('table.cells.hoursUnit', {
              ns: 'pdfGenerator',
          })}`
        : `${moment(date).diff(moment(fromDate), 'minutes')} ${translate(
              'table.cells.minutesUnit',
              {ns: 'pdfGenerator'},
          )}`;

const addHeader = (document, beginningOfPage, header) => {
    if (beginningOfPage) {
        document.content = [
            {
                text: header,
                style: pdfDocumentStyles.header,
                margin: pdfDocumentStyles.headerMargin,
            },
            ...document.content,
        ];
    } else {
        document.content = [
            ...document.content,
            {
                text: header,
                style: pdfDocumentStyles.subheader,
                margin: pdfDocumentStyles.headerMargin,
            },
        ];
    }

    return document;
};

const addTableToDocument = (
    document,
    rows,
    reportType,
    breakAfter,
    hasCategoriesFeature,
) => {
    let width = PDFTableAmount.DEFAULT;
    if (reportType === REPORT_TYPES.detailed && hasCategoriesFeature) {
        width = PDFTableAmount.DETAILED_WITH_CATEGORIES;
    } else if (reportType === REPORT_TYPES.detailed) {
        width = PDFTableAmount.DETAILED;
    } else if (reportType === REPORT_TYPES.branch_general) {
        width = PDFTableAmount.BRANCH_GENERAL;
    }

    const table = {
        table: {
            widths: Array(width).fill('auto'),
            body: rows,
        },
        ...(breakAfter && {pageBreak: 'after'}),
    };

    document.content = [...document.content, table];
    return document;
};
