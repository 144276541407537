export const pdfDocumentStyles = {
    header: {
        bold: true,
        fontSize: 18,
    },
    headerMargin: [0, 0, 0, 10],
    subheader: {
        bold: true,
        fontSize: 14,
        margin: [0, 10, 0, 5],
    },
    tableExample: {
        margin: [0, 5, 0, 15],
    },
    tableHeader: {
        bold: true,
        color: 'black',
        fontSize: 13,
    },
    tableSubHeader: {
        bold: true,
        color: 'black',
        fillColor: '#EEEEEE',
        fontSize: 13,
    },
} as const;
