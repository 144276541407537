import React from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '..';
import searchIcon from '../../../static/images/icons/search.svg';
import filterIcon from '../../../static/images/icons/filter.svg';
import SettingsIcon from '@mui/icons-material/Settings';
import MapIcon from '@mui/icons-material/Map';

import styles from './SearchField.module.css';
import {If} from '../If';
import {IconButton, TextField} from '@mui/material';

export type SearchFieldProps = {
    placeholder?: string;
    onSearchTextChange: (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => void;
    withFilter?: boolean;
    openFilter?: boolean;
    filterState?: Record<string, boolean>;
    onCheckboxClick?: (
        filed: string,
    ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFilterIconClickHandler?: () => void;
    onSettingsIconClick?: () => void;
    onMapIconClick?: () => void;
    searchText?: string;
};

const SearchField = ({
    placeholder,
    onSearchTextChange,
    withFilter,
    openFilter,
    filterState,
    onCheckboxClick,
    onFilterIconClickHandler,
    onSettingsIconClick,
    onMapIconClick,
    searchText,
}: SearchFieldProps) => {
    const {t} = useTranslation('issues');

    return (
        <div className={styles.container}>
            <div className={styles.header}>{t('search.title')}</div>
            <div className={styles.body}>
                <img src={searchIcon} alt="" className={styles.icon} />

                <TextField
                    variant="outlined"
                    className={styles.searchField}
                    placeholder={placeholder ? placeholder : t('search.title')}
                    slotProps={{
                        input: {
                            className: styles.innerContainer,
                            classes: {
                                notchedOutline: styles.outline,
                            },
                        },
                        htmlInput: {
                            className: styles.input,
                        },
                    }}
                    onChange={onSearchTextChange}
                    value={searchText}
                />
                <If condition={onMapIconClick}>
                    <IconButton onClick={onMapIconClick}>
                        <MapIcon className={styles.button} />
                    </IconButton>
                </If>
                <If condition={onSettingsIconClick}>
                    <IconButton onClick={onSettingsIconClick}>
                        <SettingsIcon className={styles.button} />
                    </IconButton>
                </If>
                <If condition={withFilter}>
                    <IconButton onClick={onFilterIconClickHandler}>
                        <img src={filterIcon} alt="" />
                    </IconButton>
                </If>
            </div>
            <If condition={withFilter && openFilter}>
                <>
                    <div className={styles.filterDrawer}>
                        <Checkbox
                            className={styles.checkboxMargin}
                            label={t('statusLabels.open')}
                            checked={filterState?.open}
                            onChange={onCheckboxClick?.('open')}
                        />
                        <Checkbox
                            className={styles.checkboxMargin}
                            label={t('statusLabels.inProgress')}
                            checked={filterState?.inProgress}
                            onChange={onCheckboxClick?.('inProgress')}
                        />
                        <Checkbox
                            className={styles.checkboxMargin}
                            label={t('statusLabels.inReview')}
                            checked={filterState?.inReview}
                            onChange={onCheckboxClick?.('inReview')}
                        />
                        <Checkbox
                            className={styles.checkboxMargin}
                            label={`${t('priorityLabels.low')} ${t(
                                'priority',
                            )}`}
                            checked={filterState?.low}
                            onChange={onCheckboxClick?.('low')}
                        />
                        <Checkbox
                            className={styles.checkboxMargin}
                            label={`${t('priorityLabels.medium')} ${t(
                                'priority',
                            )}`}
                            checked={filterState?.medium}
                            onChange={onCheckboxClick?.('medium')}
                        />
                        <Checkbox
                            className={styles.checkboxMargin}
                            label={`${t('priorityLabels.high')} ${t(
                                'priority',
                            )}`}
                            checked={filterState?.high}
                            onChange={onCheckboxClick?.('high')}
                        />
                    </div>
                    <div
                        className={styles.closeWrapper}
                        onClick={onFilterIconClickHandler}
                    />
                </>
            </If>
        </div>
    );
};

export default SearchField;
