import {useCallback, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import firebase from 'firebase/compat/app';
import {HOME, LOGIN} from '../../constants/routes';
import {
    authSetUserData,
    fetchUserData,
    setUserSelectedBranches,
    fetchOrganizationData,
    authSetOrganizationData,
    fetchBranches,
} from '../../store/action';
import {getUserRole} from '../../store/action/authHelpers';
import {setScannedElement} from '../../store/action/qrCode';
import * as endpoints from '../../constants/endpoints';
import {AppStorage, StorageItem} from '../../storage/AppStorage';
import {UserDetails} from '../../models';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {Role} from '../../constants/roles';
import {mapEnabledRoles, useMapStore} from '../../store/zustand/useMapStore';

export const withAuthentication = (
    Component: any,
    redirect: boolean,
    allowedRoles: Role[],
    props: any = {},
) => {
    return function AuthenticatedComponent(additionalProps) {
        const history = useHistory();
        const location = useLocation();
        const dispatch = useAppDispatch();

        const userData = useAppSelector(state => state.auth.userData);
        const selectedBranches = useAppSelector(
            state => state.branch.selectedBranches,
        );

        const isLoginPage = location.pathname === LOGIN;

        const saveQrCodeIfExists = useCallback(() => {
            const params = new URLSearchParams(location.search);
            const locationId = params.get('locationId');
            const elementId = params.get('elementId');
            if (locationId && elementId) {
                dispatch(setScannedElement({locationId, id: elementId}));
            }
        }, [location.search, dispatch]);

        const fetchBranchesData = useCallback(async () => {
            try {
                const branchesDoc = await firebase
                    .firestore()
                    .collection(endpoints.branches())
                    .get();
                fetchBranches(dispatch)(branchesDoc);
            } catch (error) {
                console.error('Error fetching branches:', error);
            }
        }, [dispatch]);

        const checkAuthState = useCallback(async () => {
            saveQrCodeIfExists();
            firebase.auth().onAuthStateChanged(async user => {
                if (user) {
                    const userDoc = await fetchUserData(user.uid);
                    const userData = userDoc.data() as UserDetails;

                    AppStorage.set(StorageItem.userData, userData);
                    useMapStore.setState({
                        enabled: mapEnabledRoles.includes(
                            getUserRole(userData),
                        ),
                    });
                    const storedBranches = AppStorage.get(
                        StorageItem.selectedBranches,
                    );
                    if (storedBranches) {
                        setUserSelectedBranches(storedBranches)(dispatch);
                    } else if (!selectedBranches.length) {
                        setUserSelectedBranches(userData.branches)(dispatch);
                    }

                    const organizationDoc = await fetchOrganizationData(
                        userData.organization,
                    );
                    dispatch(authSetOrganizationData(organizationDoc.data()));

                    await fetchBranchesData();

                    dispatch(authSetUserData(userData || {}));
                }

                if (user && isLoginPage) {
                    history.replace(HOME);
                    return;
                }

                if (!user && redirect) {
                    saveQrCodeIfExists();
                    history.push(LOGIN);
                }
            });
        }, [
            saveQrCodeIfExists,
            fetchBranchesData,
            dispatch,
            history,
            isLoginPage,
            redirect,
            selectedBranches,
        ]);

        useEffect(() => {
            checkAuthState();
        }, []);

        const shouldDisplayComponent = userData || isLoginPage;
        const hasAccess = allowedRoles.includes(getUserRole(userData));

        if (!shouldDisplayComponent || !hasAccess) {
            return <div />;
        }

        return <Component {...additionalProps} {...props} />;
    };
};
