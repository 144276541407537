import React, {useMemo} from 'react';
import classnames from 'classnames';
import styles from './IssuePriorityCell.module.css';
import LowPriority from '../../../../../../../src/static/images/priority/priority-low.svg';
import MediumPriority from '../../../../../../../src/static/images/priority/priority-medium.svg';
import HighPriority from '../../../../../../../src/static/images/priority/priority-high.svg';
import {IssuePriority} from '../../../../../../models';
import {useTranslation} from 'react-i18next';

type IssuePriorityProps = {
    priority?: IssuePriority;
};

export const IssuePriorityCell = ({priority}: IssuePriorityProps) => {
    const {t: tIssues} = useTranslation('issues');

    const {icon, label} = useMemo(() => {
        switch (priority) {
            case 'high':
                return {
                    icon: HighPriority,
                    label: tIssues('priorityLabels.high'),
                };
            case 'medium':
                return {
                    icon: MediumPriority,
                    label: tIssues('priorityLabels.medium'),
                };
            case 'low':
                return {
                    icon: LowPriority,
                    label: tIssues('priorityLabels.low'),
                };
            default:
                return {icon: undefined, label: ''};
        }
    }, [priority]);

    return (
        <div
            className={classnames(styles.cellContainer)}
        >
            <img src={icon} alt={label}/>
        </div>
    );
};
