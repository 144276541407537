import {Task} from '../../models/task';
import {SET_TASKS} from '../action/actionTypes';

type State = {
    tasks: Task[];
};

type Action = {
    type: typeof SET_TASKS;
    tasks: State['tasks'];
};

const initialState: State = {
    tasks: [],
};

const reducer = (state = initialState, action: Action): State => {
    switch (action.type) {
        case SET_TASKS:
            return {
                ...state,
                tasks: action.tasks,
            };

        default:
            return state;
    }
};

export default reducer;
