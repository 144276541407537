import {useTranslation} from 'react-i18next';
import Picker from '../picker/Picker';
import {useMemo} from 'react';
import {PickerItem} from '../branchPicker/BranchPicker';
import {Category} from '../../../models';

type Props = {
    label: string;
    categories: Category[];
    selectedCategory: Category;
    handleChange: (payload: PickerItem | PickerItem[]) => void;
    className?: string;
    disabled?: boolean;
    clearable?: boolean;
    placeholder?: string;
    menuPortalTarget?: HTMLElement;
};

const CategoryPicker = ({
    label,
    selectedCategory,
    categories,
    handleChange,
    className,
    disabled,
    clearable,
    placeholder,
    menuPortalTarget,
}: Props) => {
    const items = categories?.map(category => ({
        value: category.id,
        label: category.name,
    }));
    const selectedValue =
        selectedCategory && selectedCategory.id !== 'null'
            ? {
                  value: selectedCategory.id,
                  label: selectedCategory.name,
              }
            : null;
    const {t} = useTranslation('common');

    const emptyItem = useMemo(
        () => ({
            value: null,
            label: t('categoryPicker.noCategory'),
        }),
        [t],
    );

    const pickerItems = useMemo(
        () => [emptyItem, ...items],
        [emptyItem, items],
    );

    return (
        <Picker
            clearable={clearable}
            label={label}
            options={pickerItems}
            handleChange={handleChange}
            selectedValue={selectedValue}
            name="category"
            placeholder={placeholder || t('categoryPicker.placeholder')}
            className={className}
            disabled={disabled}
            menuPortalTarget={menuPortalTarget}
        />
    );
};

export default CategoryPicker;
