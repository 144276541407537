import {Category} from '../../models';
import {SET_CATEGORIES} from './actionTypes';
import firebase from 'firebase/compat/app';

export const fetchCategories =
    dispatch =>
    (
        querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
        setActivityIndicator?: (payload: boolean) => void,
    ) => {
        setActivityIndicator && setActivityIndicator(true);
        const categories = querySnapshot.docs?.map(doc => ({
            ...doc.data(),
            id: doc.id,
        })) as Category[];
        setActivityIndicator && setActivityIndicator(false);
        dispatch(setCategories(categories));
    };

export const setCategories = (categories: Category[]) => ({
    categories,
    type: SET_CATEGORIES,
});
