import {Category} from '../../models';
import {SET_CATEGORIES} from '../action/actionTypes';

type State = {
    categories: Category[];
};

type Action = {
    type: typeof SET_CATEGORIES;
    categories: State['categories'];
};

const initialState: State = {
    categories: [],
};

const reducer = (state = initialState, action: Action): State => {
    switch (action.type) {
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.categories,
            };
        default:
            return state;
    }
};

export default reducer;
