import {MenuItem} from '@mui/material';
import './NavigationItem.css';
import {NavLink} from 'react-router-dom';

const NavigationItem = props => (
    <div className="navigationItem">
        <MenuItem className="button">
            <NavLink
                to={props.path}
                exact
                className="navLink"
                activeClassName="active"
            >
                {props.icon}
                {props.title}
            </NavLink>
        </MenuItem>
    </div>
);

export default NavigationItem;
