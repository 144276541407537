import './firebase';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import App from './App';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import {store} from './store/configureStore';
import './services/translation/i18nConfig';
import {ThemeProvider} from '@mui/material';
import {MainTheme} from './utils/Themes/Themes';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root')!);

const queryClient = new QueryClient();

root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={MainTheme}>
                <App />
            </ThemeProvider>
        </QueryClientProvider>
    </Provider>,
);

registerServiceWorker();
