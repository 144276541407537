import {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import Dialog from '../../common/components/Dialog/Dialog';
import {CustomTextField, ImageDropzone} from '../../common/components';
import BranchPicker from '../../common/components/branchPicker/BranchPicker';
import {TYPES} from '../../constants/error';
import {
    Coordinates,
    Location,
    MAX_LOCATION_MAP_SIZE,
} from '../../models/location';
import {getBrowserImageDimensions} from '../../utils/getBrowserImageSize';
import {createPromisesToUploadPhotosAndGetPath} from '../../store/action/utilHelper';
import {locationsImages} from '../../constants/endpoints';
import firebase from 'firebase/compat/app';
import {downloadPhotos} from '../../store/action';
import {CoordinatesPicker} from '../../common/components/CoordinatesPicker/CoordinatesPicker';
import {parseCoordinatesToString} from '../../common/components/CoordinatesPicker/CoordinatesPicker.utils';

type State = {
    id: string | null;
    name: string;
    branch: {id: string; name: string} | null;
    dialogRef: any;
    locationMap: File | null;
    isLoading: boolean;
    imagePickerMapSource: string | null;
    coordinates: Coordinates | null;
};

type Props = any;

class ChangeLocationDialog extends Component<Props, State> {
    state = {
        id: null,
        name: '',
        branch: null,
        dialogRef: null,
        locationMap: null,
        isLoading: false,
        imagePickerMapSource: null,
        coordinates: null,
    };

    constructor(props) {
        super(props);
        this.state.id = props.location.id;
        this.state.name = props.location.name;
        this.state.branch = props.location.branch;
        this.state.locationMap = props.location.locationMap;
        this.state.coordinates = props.location.coordinates;

        if (props.location.locationMap) {
            this.downloadLocationMap(props.location.locationMap.image);
        }
    }

    downloadLocationMap = async (photoStoragePath: string) => {
        try {
            const paths = await downloadPhotos([photoStoragePath]);

            this.setState({
                imagePickerMapSource: paths[0].uri,
            });
        } catch (error) {
            console.error(error);
            this.props.showNotification(
                this.props.t('details.couldNotLoadMap'),
                TYPES.error,
            );
        }
    };

    getTextField(topLabel, onChange, value) {
        return (
            <CustomTextField
                label={topLabel}
                onChange={onChange}
                value={value}
                fullWidth
            />
        );
    }

    onLocationNameChange = event => {
        this.setState({name: event.target.value});
    };

    onBranchChange = change => {
        const branch = {id: change.value, name: change.label};
        this.setState({branch});
    };

    isSubmitButtonDisabled = () => {
        const {name, branch, locationMap, isLoading} = this.state as State;
        const {location} = this.props;

        const hasNameChanged = name !== location.name;

        const hasBranchChanged = branch?.id !== location.branch.id;

        const hasLocationMapChanged = locationMap !== location.locationMap;

        const hasCoordinatesChanged =
            parseCoordinatesToString(this.state.coordinates) !==
            parseCoordinatesToString(location.coordinates);

        return (
            !name ||
            !branch ||
            isLoading ||
            (!hasNameChanged &&
                !hasBranchChanged &&
                !hasLocationMapChanged &&
                !hasCoordinatesChanged)
        );
    };

    onClose = () => {
        this.props.handleClose();
    };

    getLocationMapObject = async () => {
        const {locationMap, id: locationId} = this.state as State;

        if (!locationMap || !locationId) {
            return null;
        }

        const storagePath = locationsImages() + locationId;
        const storageRef = firebase.storage().ref().child(storagePath);
        const imageSize = await getBrowserImageDimensions(locationMap);
        const imagePath = await createPromisesToUploadPhotosAndGetPath(
            [locationMap],
            storageRef,
            {customImageName: locationId},
        )[0];

        return {
            image: imagePath,
            width: imageSize.width,
            height: imageSize.height,
            size: locationMap.size,
            name: locationMap.name,
        };
    };

    onSubmitNewLocationHandler = async () => {
        this.setState({isLoading: true});
        const {branch, name, id, coordinates} = this.state as State;

        if (!id || !branch) {
            return;
        }

        try {
            const locationMap = await this.getLocationMapObject();

            const locationToSet: Location = {
                id,
                name: name.trim(),
                branch: {
                    id: branch.id,
                    name: branch.name,
                },
                locationMap: locationMap || null,
                coordinates,
            };

            this.props.onUpdateLocationEvent(
                locationToSet,
                () => {
                    this.props.showNotification(
                        this.props.t('notifications.elementUpdateSuccess'),
                        TYPES.success,
                    );
                },
                message => {
                    this.props.showNotification(message, TYPES.error);
                },
            );
            this.onClose();
        } catch (error) {
            console.error(error);
            this.props.showNotification(error.message, TYPES.error);
        } finally {
            this.setState({isLoading: false});
        }
    };

    onLocationMapImageDrop = files => {
        const {t} = this.props;
        const singleImage = files[0];
        const isFileTooLarge = singleImage.size > MAX_LOCATION_MAP_SIZE;

        if (isFileTooLarge) {
            this.props.showNotification(
                t('notifications.dialog.tooLargePhotoError'),
                TYPES.error,
            );
        }

        this.setState({
            locationMap: singleImage,
            imagePickerMapSource: URL.createObjectURL(singleImage),
        });
    };

    onRemoveLocationMapImage = () => {
        this.setState({locationMap: null, imagePickerMapSource: null});
    };

    render() {
        const {dialogTitle, t} = this.props;
        const {name, branch, isLoading, imagePickerMapSource} = this
            .state as State;

        return (
            <Dialog
                ref={dialogRef =>
                    !this.state.dialogRef && this.setState({dialogRef})
                }
                loading={isLoading}
                handleClose={this.onClose}
                dialogTitle={dialogTitle}
                submitButtonDisabled={this.isSubmitButtonDisabled()}
                onSubmitHandler={this.onSubmitNewLocationHandler}
            >
                <BranchPicker
                    handleChange={this.onBranchChange}
                    branches={this.props.branches}
                    selectedBranch={branch}
                    menuPortalTarget={this.state.dialogRef}
                />
                {this.getTextField(
                    t('details.name'),
                    this.onLocationNameChange,
                    name,
                )}
                <CoordinatesPicker
                    initialValue={this.state.coordinates}
                    onChange={coordinates => {
                        this.setState({coordinates});
                    }}
                />
                {this.props.featureSwitchers.isIssueLocalizationEnabled && (
                    <ImageDropzone
                        label={t('details.locationMap')}
                        multiple={false}
                        onDrop={this.onLocationMapImageDrop}
                        imagesSrc={
                            [imagePickerMapSource].filter(Boolean) as string[]
                        }
                        onRemoveImage={this.onRemoveLocationMapImage}
                    />
                )}
            </Dialog>
        );
    }
}

const mapStateToProps = store => ({
    branches: store.branch.branches,
    featureSwitchers: store.auth.organizationData,
});

export default connect(mapStateToProps)(
    withTranslation('locations')(ChangeLocationDialog),
);
