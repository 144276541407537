import {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import Alert from '../../components/Alert/Alert';
import {fetchLocations, setUsers, fetchElements} from '../../store/action';
import ReportForm from '../../components/Reports/ReportForm/ReportForm';
import CircularProgress from '../../common/components/CustomCircularProgress/customCircularProgress';
import getLocationsQuery from '../../utils/queryBuilder/LocationQueryBuilder';
import getUsersQuery from '../../utils/queryBuilder/UsersQueryBuilder';
import getElementQuery from '../../utils/queryBuilder/ElementsQueryBuilder';
import {NotificationContext} from '../../context/notifications';
import {
    PickerReportFormat,
    PickerReportType,
    reportFormats,
    reportsTypes,
} from './reports';
import {TYPES} from '../../constants/error';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';
import {Location} from '../../models/location';
import {type Element} from '../../models/element';
import {REPORT_TYPES} from '../../services/raportGenerator/reportGenerator';
import {generateIssueReport} from './generateIssueReport';
import {RootState} from '../../store/configureStore';

type State = {
    errorMessage: string;
    indicator: boolean;
    startDate: string;
    endDate: string;
    reportType: PickerReportType;
    user: any | null;
    branch: any | null;
    location: Location | null;
    element: Element | null;
    reportWithActualIssues: boolean;
    reportFormat: PickerReportFormat;
};

class ReportsPage extends Component<any, State> {
    constructor(props) {
        super(props);
        const {t} = props;
        const defaultReportFormat = reportFormats[0];
        const defaultReportType = reportsTypes[0];

        this.state = {
            errorMessage: '',
            indicator: false,
            startDate: '',
            endDate: '',
            reportType: {
                value: defaultReportType.value,
                label: t(defaultReportType.label),
            },
            user: null,
            location: null,
            element: null,
            branch: null,
            reportWithActualIssues: false,
            reportFormat: {
                value: defaultReportFormat.value,
                label: t(defaultReportFormat.label),
            },
        };
    }

    static contextType = NotificationContext;

    unsubscribeLocations;

    unsubscribeElements;

    notificationSystem: any = null;

    componentDidMount() {
        this.subscribeOnCollections();
        this.notificationSystem = this.context;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
                prevProps.selectedBranches.length ||
            (!prevState.location && this.state.location) ||
            prevState.location?.id !== this.state.location?.id ||
            prevState.user?.id !== this.state.user?.id
        ) {
            this.unsubscribeLocations && this.unsubscribeLocations();
            this.unsubscribeElements && this.unsubscribeElements();
            this.subscribeOnCollections();
        }
    }

    subscribeOnCollections = () => {
        this.setActivityIndicator(true);
        let branchIds = this.props.selectedBranches.map(branch => branch.id);
        if (this.state.user) {
            branchIds = this.props.users
                .find(user => user.uid === this.state.user.uid)
                .branches.map(branch => branch.id);
        }
        this.unsubscribeLocations = getLocationsQuery(branchIds).onSnapshot(
            this.props.fetchLocations,
            errorHandler,
        );
        if (this.state.location) {
            this.unsubscribeElements = getElementQuery(branchIds)
                .withLocation(this.state.location.id)
                .onSnapshot(this.props.fetchElements, errorHandler);
        }
        getUsersQuery()
            .withBranches(this.props.selectedBranches)
            .get()
            .then(users => {
                this.props.setUsers(users);
                this.setActivityIndicator(false);
            })
            .catch(error => {
                this.showNotification(
                    this.props.t('notifications.usersLoadError', {
                        ns: 'reports',
                    }),
                    TYPES.error,
                );
                this.setActivityIndicator(false);
            });
    };

    render() {
        const alert = this.state.errorMessage && (
            <Alert>{this.state.errorMessage}</Alert>
        );

        const {
            startDate,
            endDate,
            reportType,
            location,
            element,
            user,
            branch,
            reportWithActualIssues,
            reportFormat,
        } = this.state;
        const {users, locations, elements, branches, userData} = this.props;
        const reportFormatsPickerValues = reportFormats.map(
            ({value, label}) => ({
                value,
                label: this.props.t(label),
            }),
        );
        const reportTypes = reportsTypes.map(({value, label}) => ({
            value,
            label: this.props.t(label),
        }));

        //Only for Admin and Supervisor
        (userData.roles.admin || userData.roles.supervisor) &&
            reportTypes.push({
                value: REPORT_TYPES.branch_general,
                label: this.props.t('reportTypes.branchGeneral'),
            });

        return (
            <div>
                <ReportForm
                    reportPickerTypes={reportTypes}
                    users={users}
                    locations={locations}
                    elements={elements}
                    reportType={reportType}
                    branches={branches}
                    location={location}
                    element={element}
                    user={user}
                    branch={branch}
                    onGenerate={this.onGenerateReportedClickHandler}
                    onDatePickerChangeHandler={this.onDatePickerChangeHandler}
                    endDate={endDate}
                    startDate={startDate}
                    onReportTypeChange={this.onReportTypeChangeHandler}
                    onUserChange={this.onUserChangeHandler}
                    onBranchChange={this.onBranchChangeHandler}
                    onLocationChange={this.onPickerValueChangeHandler(
                        'location',
                        'locations',
                    )}
                    onElementChange={this.onPickerValueChangeHandler(
                        'element',
                        'elements',
                    )}
                    showElementPicker={Boolean(location)}
                    reportWithActualIssues={reportWithActualIssues}
                    toggleReportWithActualIssuesCheckboxValue={
                        this.toggleReportWithActualIssuesCheckboxValue
                    }
                    reportFormat={reportFormat}
                    handleFormatChange={this.handleFormatChange}
                    reportFormatsPickerValues={reportFormatsPickerValues}
                />
                {this.state.indicator && <CircularProgress />}
                {alert}
            </div>
        );
    }

    handleFormatChange = reportFormat => {
        this.setState({reportFormat});
    };

    onDatePickerChangeHandler = (
        dateType: 'startDate' | 'endDate',
        value: Date,
    ) => {
        this.setState(prevState => ({...prevState, [dateType]: value}));
    };

    onPickerValueChangeHandler =
        (property: 'location' | 'element', collection) => event => {
            const itemsCollection = this.props[collection];
            let item = null;
            if (event) {
                item = {
                    ...itemsCollection.find(i => event?.value === i.id),
                };
            }

            this.setState(prevState => ({
                ...prevState,
                [property]: item,
                ...(property === 'location' && {element: null}),
            }));
        };

    onUserChangeHandler = event => {
        const {users} = this.props;
        const user = event
            ? {
                  ...users.find(u => event.value === u.uid),
              }
            : null;
        this.setState({user});
    };

    onBranchChangeHandler = event => {
        const {branches} = this.props;
        const branch = event
            ? {
                  ...branches.find(b => event.value === b.id),
              }
            : null;
        this.setState({branch});
    };

    onReportTypeChangeHandler = type => {
        const shouldClearDetailsFilters =
            type.value === REPORT_TYPES.general ||
            type.value === REPORT_TYPES.branch_general;
        const shouldClearBranchFilters =
            type.value === REPORT_TYPES.general ||
            type.value === REPORT_TYPES.detailed;

        this.setState(prevState => ({
            ...prevState,
            reportType: type,
            ...(shouldClearDetailsFilters
                ? {
                      user: null,
                      location: null,
                      element: null,
                      reportFormat: {
                          value: reportFormats[0].value,
                          label: this.props.t(reportFormats[0].label),
                      },
                  }
                : {}),
            ...(shouldClearBranchFilters ? {branch: null} : {}),
        }));
    };

    toggleReportWithActualIssuesCheckboxValue = () => {
        this.setState({
            reportWithActualIssues: !this.state.reportWithActualIssues,
        });
    };

    setActivityIndicator = bool => {
        this.setState({indicator: bool});
    };

    onGenerateReportedClickHandler = () => {
        const {
            startDate,
            endDate,
            user,
            branch,
            location,
            element,
            reportType,
            reportWithActualIssues,
            reportFormat,
        } = this.state;
        const startDateMoment = moment(startDate).startOf('day').toDate();
        const endDateMoment = moment(endDate).endOf('day').toDate();

        this.setErrorMessage('');
        this.setState({indicator: true});
        generateIssueReport(
            reportWithActualIssues,
            startDateMoment,
            endDateMoment,
            this.setErrorMessage,
            user?.uid,
            branch,
            location?.id,
            reportType.value,
            element?.id,
            () => this.setState({indicator: false}),
            reportFormat.value,
            this.props.organizationData.isIssuesCategoriesFeatureEnabled,
        );
    };

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message,
                type,
            });
        }
    };

    setErrorMessage = message => {
        this.setState({errorMessage: message});
    };
}

const mapStateToProps = (state: RootState) => ({
    users: state.user.users,
    locations: state.location.locations,
    elements: state.element.elements,
    selectedBranches: state.branch.selectedBranches,
    organizationData: state.auth.organizationData,
    branches: state.branch.branches,
    userData: state.auth.userData,
});

const mapDispatchToProps = {
    fetchLocations,
    setUsers,
    fetchElements,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation(['reports', 'pdfGenerator'])(ReportsPage));
