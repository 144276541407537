import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {CustomTextField, Checkbox} from '../../../common/components/index';
import Dialog from '../../../common/components/Dialog/Dialog';
import FileDropzone from '../../../common/components/FileDropzone/filesDropzone';
import {MAX_XLSX_FILES} from '../../../constants/files';
import {TYPES} from '../../../constants/error';
import {NotificationContext} from '../../../context/notifications';
import {
    REPORTER_CAN_SEE_ALL_ISSUES,
    SEND_EMAIL_WITH_NOTIFICATION_TO_USER_AFTER_ASSIGN_TO_ISSUE,
    SHOW_RECENTLY_ARCHIVED_ISSUES_ON_ISSUES_LIST,
    IS_ISSUES_CATEGORIES_FEATURE_ENABLED,
    MAINTAINER_HAS_NO_PERMISSION_TO_ASSIGN_USERS_TO_ISSUE,
    SUPERVISOR_HAS_ACCESS_TO_TASKS,
    FAIR_ENVIRONMENT,
    ISSUE_EXPIRATION,
    REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION,
    MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION,
    MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE,
    REPORTER_CAN_SEE_ISSUE_SOLUTION,
    OVERWRITE_ENVIRONMENT,
    MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED,
    SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL,
    REPORTER_GET_CHANGE_MAIL,
    SMS_NOTIFICATIONS,
    SMS_SENT_COUNT,
    SMS_LIMIT_COUNT,
    SMS_LAST_RESET_TIMESTAMP,
    ISSUES_ON_MAP,
    SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE,
    SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE,
} from '../../../constants/environment';
import CustomCircularProgress from '../../../common/components/CustomCircularProgress/customCircularProgress';

class EditEnvironment extends Component {
    state = {
        newEnvironmentName: '',
        oldEnvironmentName: '',
        environmentFile: null,
        reporterCanSeeAllIssues: false,
        sendEmailWithNotificationToUserAfterAssignToIssue: false,
        showRecentlyArchivedIssuesOnIssuesList: false,
        [SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE]: false,
        [SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE]: false,
        isIssuesCategoriesFeatureEnabled: false,
        maintainerHasNoPermissionToAssignUsersToIssue: false,
        supervisorHasAccessToTasks: false,
        fairEnvironment: false,
        issueExpiration: false,
        reporterHasNoPermissionToEditIssueDescription: false,
        maintainerHasNoPermissionToEditIssueDescription: false,
        maintainerHasNoPermissionToAddIssue: false,
        reporterCanSeeIssueSolution: false,
        maintainerGetPushOnAllIssuesCreated: false,
        supervisorAndMaintainerGetChangeMail: false,
        reporterGetChangeMail: false,
        overwriteEnvironment: false,
        [SMS_NOTIFICATIONS]: false,
        [SMS_SENT_COUNT]: 0,
        [SMS_LIMIT_COUNT]: 0,
        [SMS_LAST_RESET_TIMESTAMP]: 0,
        [ISSUES_ON_MAP]: false,
    };

    constructor(props) {
        super(props);
        this.state.newEnvironmentName = props.environment.name;
        this.state.oldEnvironmentName = props.environment.name;
        this.state.reporterCanSeeAllIssues =
            props.environment.reporterCanSeeAllIssues;
        this.state.sendEmailWithNotificationToUserAfterAssignToIssue =
            props.environment.sendEmailWithNotificationToUserAfterAssignToIssue;
        this.state.showRecentlyArchivedIssuesOnIssuesList =
            props.environment.showRecentlyArchivedIssuesOnIssuesList;
        this.state[SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE] =
            props.environment[
                SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE
            ];
        this.state[
            SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE
        ] =
            props.environment[
                SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE
            ];
        this.state.isIssuesCategoriesFeatureEnabled =
            props.environment.isIssuesCategoriesFeatureEnabled;
        this.state.maintainerHasNoPermissionToAssignUsersToIssue =
            props.environment.maintainerHasNoPermissionToAssignUsersToIssue;
        this.state.eventBased = props.environment.eventBased;
        this.state.issueExpiration = props.environment.issueExpiration;
        this.state.reporterHasNoPermissionToEditIssueDescription =
            props.environment.reporterHasNoPermissionToEditIssueDescription;
        this.state.maintainerHasNoPermissionToEditIssueDescription =
            props.environment.maintainerHasNoPermissionToEditIssueDescription;
        this.state.maintainerHasNoPermissionToAddIssue =
            props.environment.maintainerHasNoPermissionToAddIssue;
        this.state.reporterCanSeeIssueSolution =
            props.environment.reporterCanSeeIssueSolution;
        this.state.maintainerGetPushOnAllIssuesCreated =
            props.environment.maintainerGetPushOnAllIssuesCreated;
        this.state.supervisorAndMaintainerGetChangeMail =
            props.environment.supervisorAndMaintainerGetChangeMail;
        this.state.reporterGetChangeMail =
            props.environment.reporterGetChangeMail;

        this.state[SMS_NOTIFICATIONS] = props.environment[SMS_NOTIFICATIONS];
        this.state[SMS_SENT_COUNT] = props.environment[SMS_SENT_COUNT] ?? 0;
        this.state[SMS_LIMIT_COUNT] = props.environment[SMS_LIMIT_COUNT] ?? 0;
        this.state[SMS_LAST_RESET_TIMESTAMP] =
            props.environment[SMS_LAST_RESET_TIMESTAMP];
        this.state[ISSUES_ON_MAP] = props.environment[ISSUES_ON_MAP];
    }

    static contextType = NotificationContext;

    notificationSystem = this.context;

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message,
                type,
            });
        }
    };

    onEnvironmentNameChange = event => {
        this.setState({newEnvironmentName: event.target.value});
    };

    onRemoveFile = () => this.setState({environmentFile: null});

    onFileDrop = files => {
        if (files.length > MAX_XLSX_FILES) {
            this.showNotification(
                this.t('notifications.tooMuchFilesError'),
                TYPES.error,
            );
        } else {
            this.setState({environmentFile: files});
        }
    };

    onSubmitEditEnvironmentHandler = () => {
        this.props.onSubmitHandler({
            ...this.state,
            newEnvironmentName: this.state.newEnvironmentName.trim(),
            environmentFile: this.state.environmentFile
                ? this.state.environmentFile[0]
                : null,
        });
    };

    toggleCheckboxValue = event => {
        this.setState({[event.target.id]: !this.state[event.target.id]});
    };

    onSmsLimitChange = event => {
        const value = event.target.value ? Number(event.target.value) : '';

        if (Number.isNaN(value)) {
            return;
        }

        this.setState({[SMS_LIMIT_COUNT]: value});
    };

    render() {
        const {dialogTitle, handleClose, loading} = this.props;
        const {newEnvironmentName} = this.state;
        const submitButtonDisabled = !newEnvironmentName.trim() || loading;
        this.t = this.props.t;

        return (
            <Dialog
                loading={loading}
                handleClose={handleClose}
                dialogTitle={dialogTitle}
                submitButtonDisabled={submitButtonDisabled}
                onSubmitHandler={this.onSubmitEditEnvironmentHandler}
                submitButtonText={dialogTitle}
            >
                {loading && <CustomCircularProgress />}
                <CustomTextField
                    label={this.t('dialog.name')}
                    onChange={this.onEnvironmentNameChange}
                    fullWidth
                    value={this.state.newEnvironmentName}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.reporterCanSeeAllIssues')}
                    id={REPORTER_CAN_SEE_ALL_ISSUES}
                    checked={this.state[REPORTER_CAN_SEE_ALL_ISSUES]}
                    onChange={this.toggleCheckboxValue}
                    value={this.state.reporterCanSeeAllIssues}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.sendEmailWithNotificationToUserAfterAssignToIssue',
                    )}
                    id={
                        SEND_EMAIL_WITH_NOTIFICATION_TO_USER_AFTER_ASSIGN_TO_ISSUE
                    }
                    checked={
                        this.state[
                            SEND_EMAIL_WITH_NOTIFICATION_TO_USER_AFTER_ASSIGN_TO_ISSUE
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                    value={
                        this.state
                            .sendEmailWithNotificationToUserAfterAssignToIssue
                    }
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.showRecentlyArchivedIssuesOnIssueList',
                    )}
                    id={SHOW_RECENTLY_ARCHIVED_ISSUES_ON_ISSUES_LIST}
                    checked={
                        this.state[SHOW_RECENTLY_ARCHIVED_ISSUES_ON_ISSUES_LIST]
                    }
                    onChange={this.toggleCheckboxValue}
                    value={this.state.showRecentlyArchivedIssuesOnIssuesList}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.sendEmailWithNotificationToAdminsOnIssueCreate',
                    )}
                    id={SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE}
                    checked={
                        this.state[
                            SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.sendEmailWithNotificationToSupervisorsOnIssueCreate',
                    )}
                    id={
                        SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE
                    }
                    checked={
                        this.state[
                            SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.isIssuesCategoriesFeatureEnabled',
                    )}
                    id={IS_ISSUES_CATEGORIES_FEATURE_ENABLED}
                    checked={this.state[IS_ISSUES_CATEGORIES_FEATURE_ENABLED]}
                    onChange={this.toggleCheckboxValue}
                    value={this.state.isIssuesCategoriesFeatureEnabled}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.maintainerHasNoPermissionToAssignUsersToIssue',
                    )}
                    id={MAINTAINER_HAS_NO_PERMISSION_TO_ASSIGN_USERS_TO_ISSUE}
                    checked={
                        this.state[
                            MAINTAINER_HAS_NO_PERMISSION_TO_ASSIGN_USERS_TO_ISSUE
                        ]
                    }
                    value={
                        this.state[
                            MAINTAINER_HAS_NO_PERMISSION_TO_ASSIGN_USERS_TO_ISSUE
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.supervisorHasAccessToTasks',
                    )}
                    id={SUPERVISOR_HAS_ACCESS_TO_TASKS}
                    checked={this.state[SUPERVISOR_HAS_ACCESS_TO_TASKS]}
                    value={this.state[SUPERVISOR_HAS_ACCESS_TO_TASKS]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.expoEnvironment')}
                    id={FAIR_ENVIRONMENT}
                    checked={this.state[FAIR_ENVIRONMENT]}
                    value={this.state[FAIR_ENVIRONMENT]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.issueExpiration')}
                    id={ISSUE_EXPIRATION}
                    checked={this.state[ISSUE_EXPIRATION]}
                    value={this.state[ISSUE_EXPIRATION]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.reporterHasNoPermissionToEditIssueDescription',
                    )}
                    id={REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION}
                    checked={
                        this.state[
                            REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION
                        ]
                    }
                    value={
                        this.state[
                            REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.maintainerHasNoPermissionToEditIssueDescription',
                    )}
                    id={MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION}
                    checked={
                        this.state[
                            MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION
                        ]
                    }
                    value={
                        this.state[
                            MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.maintainerHasNoPermissionToAddIssue',
                    )}
                    id={MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE}
                    checked={
                        this.state[MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE]
                    }
                    value={
                        this.state[MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.reporterCanSeeIssueSolution',
                    )}
                    id={REPORTER_CAN_SEE_ISSUE_SOLUTION}
                    checked={this.state[REPORTER_CAN_SEE_ISSUE_SOLUTION]}
                    value={this.state[REPORTER_CAN_SEE_ISSUE_SOLUTION]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.maintainerGetPushOnAllIssuesCreated',
                    )}
                    id={MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED}
                    checked={
                        this.state[MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED]
                    }
                    value={
                        this.state[MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.supervisorAndMaintainerGetChangeMail',
                    )}
                    id={SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL}
                    checked={
                        this.state[SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL]
                    }
                    value={
                        this.state[SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.reporterGetChangeMail')}
                    id={REPORTER_GET_CHANGE_MAIL}
                    checked={this.state[REPORTER_GET_CHANGE_MAIL]}
                    value={this.state[REPORTER_GET_CHANGE_MAIL]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.issuesOnMap')}
                    id={ISSUES_ON_MAP}
                    checked={this.state[ISSUES_ON_MAP]}
                    value={this.state[ISSUES_ON_MAP]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.smsNotifications')}
                    id={SMS_NOTIFICATIONS}
                    checked={this.state[SMS_NOTIFICATIONS]}
                    value={this.state[SMS_NOTIFICATIONS]}
                    onChange={this.toggleCheckboxValue}
                />
                <CustomTextField
                    label={this.t('dialog.label.smsLimit', {
                        count: this.state[SMS_SENT_COUNT],
                    })}
                    type="number"
                    onChange={this.onSmsLimitChange}
                    fullWidth
                    value={this.state[SMS_LIMIT_COUNT]}
                    disabled={!this.state[SMS_NOTIFICATIONS]}
                />

                <FileDropzone
                    label={this.t('dialog.filedrop')}
                    onDrop={this.onFileDrop}
                    onRemoveFile={this.onRemoveFile}
                    filesSrc={this.state.environmentFile}
                    onlyXLSX
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.overwriteEnvironment')}
                    id={OVERWRITE_ENVIRONMENT}
                    checked={this.state[OVERWRITE_ENVIRONMENT]}
                    value={this.state[OVERWRITE_ENVIRONMENT]}
                    onChange={this.toggleCheckboxValue}
                    disabled={!this.state.environmentFile}
                />
            </Dialog>
        );
    }
}

export default withTranslation('environments')(EditEnvironment);
