export const USER_LOGOUT = 'USER_LOGOUT';
export const AUTH_SET_USER_DATA = 'AUTH_SET_USER_DATA';
export const AUTH_SET_ORGANIZATION_DATA = 'AUTH_SET_ORGANIZATION_DATA';

export const SET_ELEMENTS = 'SET_ELEMENTS';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';

export const SET_ISSUES = 'SET_ISSUES';
export const SET_ARCHIVED_ISSUES = 'SET_ARCHIVED_ISSUES';
export const SET_SORTED_ISSUES_IDS = 'SET_SORTED_ISSUES_IDS';

export const SET_USERS = 'SET_USERS';

export const SET_TASKS = 'SET_TASKS';

export const SET_SELECTED_BRANCHES = 'SET_SELECTED_BRANCHES';
export const SET_BRANCHES = 'SET_BRANCHES';
export const SET_CATEGORIES = 'SET_CATEGORIES';

export const SET_SCANNED_ELEMENT = 'SET_SCANNED_ELEMENT';
export const CLEAR_SCANNED_ELEMENT = 'CLEAR_SCANNED_ELEMENT';
