import {DESC} from '../../constants/sortingDirections';
import {rolesPriority} from '../../constants/roles';
import {ascendingSort, descendingSort} from '../StringUtils/StringUtils';
import {isSameDay} from '../date/dateUtils';
import {
    splitNumericPrefixFromElement,
    numericSegmentSeparatorRegex,
} from '../../constants/elements';

enum Priority {
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high',
}

enum Status {
    OPEN = 'open',
    IN_PROGRESS = 'inProgress',
    IN_REVIEW = 'inReview',
    DONE = 'done',
}

export const sortRows = (compare, accessAttribute, rows) => {
    const sortedRows = [...rows];
    sortedRows.sort((a, b) => compare(accessAttribute(a), accessAttribute(b)));
    return sortedRows;
};

export const statusComparator = (a: Status, b: Status) => {
    switch (b) {
        case Status.OPEN:
            return 1;
        case Status.DONE:
            return -1;
        default:
            break;
    }

    switch (a) {
        case Status.OPEN:
            return -1;
        case Status.DONE:
            return 1;
        default:
            break;
    }

    switch (b) {
        case Status.IN_PROGRESS:
            return 1;
        case Status.IN_REVIEW:
            return -1;
        default:
            break;
    }

    return 0;
};

export const compareNumericPrefix = (a, b) => {
    const aPrefix = a.split(numericSegmentSeparatorRegex);
    const bPrefix = b.split(numericSegmentSeparatorRegex);

    if (aPrefix.length && bPrefix.length) {
        for (let i = 0; i < Math.max(aPrefix.length, bPrefix.length); i++) {
            const aNumber = parseInt(aPrefix[i]);
            const bNumber = parseInt(bPrefix[i]);

            if (aNumber !== bNumber) {
                return bNumber - aNumber;
            }
        }
    }

    return 0;
};

export const compareStringWithNumericPrefixes = (a, b) => {
    const aNumericPrefix = splitNumericPrefixFromElement(a);
    const bNumericPrefix = splitNumericPrefixFromElement(b);
    const aString = a.substr(aNumericPrefix.length);
    const bString = b.substr(bNumericPrefix.length);

    if (!aNumericPrefix || !bNumericPrefix) {
        if (!aNumericPrefix && !bNumericPrefix) {
            return descendingSort(aString, bString);
        }

        return aNumericPrefix ? 1 : -1;
    }

    const numericPrefixComparisonResult = compareNumericPrefix(
        aNumericPrefix,
        bNumericPrefix,
    );

    if (!numericPrefixComparisonResult) {
        return descendingSort(aString, bString);
    }

    return numericPrefixComparisonResult;
};

export const getComparator = (comparator, sortDirection) => (a, b) =>
    sortDirection === DESC ? comparator(a, b) : -comparator(a, b);

export const maintainerComparator = (a, b) => {
    const fullNameA = (a && `${a.name} ${a.surname}`) || '';
    const fullNameB = (b && `${b.name} ${b.surname}`) || '';

    return fullNameB.localeCompare(fullNameA);
};

export const roleComparator = (a, b) => {
    const roleA = Object.keys(a)[0].toUpperCase();
    const roleB = Object.keys(b)[0].toUpperCase();
    return rolesPriority[roleB] - rolesPriority[roleA];
};

export const priorityComparator = (a, b) => {
    if (a === Priority.LOW && b === Priority.LOW) return 0;
    if (a === Priority.MEDIUM && b === Priority.MEDIUM) return 0;
    if (a === Priority.HIGH && b === Priority.HIGH) return 0;

    if (a === Priority.LOW && b === Priority.MEDIUM) return 1;
    if (a === Priority.LOW && b === Priority.HIGH) return 1;
    if (a === Priority.MEDIUM && b === Priority.HIGH) return 1;

    if (a === Priority.MEDIUM && b === Priority.LOW) return -1;
    if (a === Priority.HIGH && b === Priority.LOW) return -1;
    if (a === Priority.HIGH && b === Priority.MEDIUM) return -1;
};

export const issueCreateDateComparator = (a, b) =>
    ascendingSort(a.createdDate, b.createdDate);

export const issuePriorityComparator = (a, b) =>
    priorityComparator(a.priority, b.priority);

export const elementLocationComparator = (a, b) =>
    ascendingSort(a.location.name, b.location.name);

export const elementNameComparator = (a, b) => ascendingSort(a.name, b.name);

export const issueClosedDateComparator = (a, b) =>
    descendingSort(a.closedDate, b.closedDate);

export const userSurnameComparator = (a, b) =>
    descendingSort(a.surname, b.surname);

export const byDate = (a, b) => {
    if (isSameDay(a, b)) {
        return 0;
    }

    return a.getTime() < b.getTime() ? -1 : 1;
};

export const byTruthiness = (a, b) => {
    if (a === b) {
        return 0;
    }

    return a ? -1 : 1;
};
