import {Location} from '../../models';
import {SET_LOCATIONS, SET_SELECTED_LOCATION} from '../action/actionTypes';

type State = {
    locations: Location[];
    selectedLocation: Location | null;
};

type Action =
    | {
          type: typeof SET_LOCATIONS;
          locations: State['locations'];
      }
    | {
          type: typeof SET_SELECTED_LOCATION;
          selectedLocation: State['selectedLocation'];
      };

const initialState: State = {
    locations: [],
    selectedLocation: null,
};

const reducer = (state = initialState, action: Action): State => {
    switch (action.type) {
        case SET_LOCATIONS:
            return {
                ...state,
                locations: action.locations,
            };
        case SET_SELECTED_LOCATION:
            return {
                ...state,
                selectedLocation: action.selectedLocation,
            };
        default:
            return state;
    }
};

export default reducer;
