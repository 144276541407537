import {useEffect, useState, useCallback, ChangeEvent} from 'react';
import firebase from 'firebase/compat/app';
import TaskList from '../../components/Tasks/TasksList/TaskList';
import {fetchTasks} from '../../store/action';
import PageControl from '../../components/Tasks/TasksPageControl/TasksPageControl';
import {compareTaskWithQueryString} from '../../utils/filtering/filtering';
import {fetchAllTasks, updateTask} from '../../utils/task/task';
import {CustomCircularProgress} from '../../common/components/index';
import {TASK} from '../../constants/routes';
import CreateNewTask from '../../components/Tasks/CreateNewTask/CreateNewTask';
import {byTruthiness, byDate} from '../../utils/sorting/sorting';
import {fetchCategories} from '../../store/action/category';
import {categories} from '../../constants/endpoints';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';
import {useHistory} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {If} from '../../common/components/If';
import {TaskColumn} from '../../components/Tasks/TasksList/TaskList.types';
import {AppStorage, StorageItem} from '../../storage/AppStorage';
import {FilterIssueColumnDialog} from '../../components/Issues/FilterIssueColumnDialog/FilterIssueColumnDialog';
import {Task} from '../../models/task';

const TaskPage = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const tasks = useAppSelector(state => state.task.tasks);
    const selectedBranches = useAppSelector(
        state => state.branch.selectedBranches,
    );

    const [searchString, setSearchString] = useState('');
    const [indicateActivity, setIndicateActivity] = useState(true);
    const [newTaskDialogOpen, setNewTaskDialogOpen] = useState(false);
    const [columnFilterDialogVisible, setColumnFilterDialogVisible] =
        useState(false);
    const [visibleColumns, setVisibleColumns] = useState<TaskColumn[]>(
        AppStorage.get(StorageItem.columnsFilterTasks) ||
            Object.values(TaskColumn),
    );

    useEffect(() => {
        const unsubscribeTasks = fetchAllTasks(snapshot =>
            fetchTasks(snapshot, setIndicateActivity),
        );
        const unsubscribeCategories = firebase
            .firestore()
            .collection(categories())
            .onSnapshot(
                snapshot => fetchCategories(dispatch)(snapshot),
                errorHandler,
            );
        return () => {
            unsubscribeTasks();
            unsubscribeCategories();
        };
    }, [dispatch]);

    const tasksByActive = useCallback(
        (a: any, b: any) => byTruthiness(a?.active, b?.active),
        [],
    );
    const tasksByDate = useCallback(
        (a: any, b: any) =>
            a?.active
                ? byDate(a?.nextOccurrenceDate, b?.nextOccurrenceDate)
                : byDate(b?.nextOccurrenceDate, a?.nextOccurrenceDate),
        [],
    );

    const filterTasks = useCallback(
        (tasks: any[]) => {
            const taskList = tasks.filter(task =>
                selectedBranches.some(branch => branch.id === task?.branch?.id),
            );
            return searchString
                ? taskList.filter(task =>
                      compareTaskWithQueryString(task, searchString),
                  )
                : taskList;
        },
        [searchString, selectedBranches],
    );

    const onTaskClickHandler = useCallback(
        (taskId: string) => () => history.push(TASK(taskId)),
        [history],
    );

    const onToggleAddDialogHandler = useCallback(() => {
        setNewTaskDialogOpen(prev => !prev);
    }, []);

    const onActiveStateChange = useCallback(
        (taskId: string, active: boolean) => {
            const foundTask = tasks.find(task => task.id === taskId);

            if (!foundTask) {
                console.warn(`Task with id ${taskId} not found`);
                return;
            }

            updateTask(taskId, {...foundTask, active});
        },
        [tasks],
    );

    const onSearchTextChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setSearchString(event.target.value);
        },
        [],
    );

    const openFilterIssueColumnFilter = () => {
        setColumnFilterDialogVisible(true);
    };

    const onFilterIssueColumnSubmit = (payload: TaskColumn[]) => {
        AppStorage.set(StorageItem.columnsFilterTasks, payload);
        setVisibleColumns(payload);
        setColumnFilterDialogVisible(false);
    };

    const onFilterIssueColumnClose = () => {
        setColumnFilterDialogVisible(false);
    };

    return (
        <>
            <PageControl
                onSearchTextChange={onSearchTextChange}
                onToggleAddDialogHandler={onToggleAddDialogHandler}
                onSettingsIconClick={openFilterIssueColumnFilter}
            />
            <If condition={newTaskDialogOpen}>
                <CreateNewTask
                    //@ts-expect-error
                    onClose={onToggleAddDialogHandler}
                    loading={indicateActivity}
                />
            </If>
            <div>
                {indicateActivity ? (
                    <CustomCircularProgress />
                ) : (
                    <TaskList
                        tasks={filterTasks(
                            tasks.sort(tasksByDate).sort(tasksByActive),
                        )}
                        onTaskClick={onTaskClickHandler}
                        onActiveStateChange={onActiveStateChange}
                        visibleColumns={visibleColumns}
                    />
                )}
            </div>

            <FilterIssueColumnDialog
                variant="tasks"
                visibleColumns={visibleColumns}
                visible={columnFilterDialogVisible}
                onSubmit={onFilterIssueColumnSubmit}
                handleClose={onFilterIssueColumnClose}
            />
        </>
    );
};

export default TaskPage;
