import {Task} from '../../models/task';
import {store} from '../configureStore';
import {SET_TASKS} from './actionTypes';

export const fetchTasks = (querySnaphot, setActivityIndicator) => {
    setActivityIndicator && setActivityIndicator(true);
    const tasks = querySnaphot.docs?.map(doc => ({
        ...doc.data(),
    }));
    setActivityIndicator && setActivityIndicator(false);
    store.dispatch(setTasks(tasks));
};

const setTasks = (tasks: Task[]) => ({
    tasks,
    type: SET_TASKS,
});
