import {Role} from '../../constants/roles';

const mappings = {};

mappings[Role.MAINTAINER] = {};

mappings[Role.SUPERVISOR] = {};

mappings[Role.ADMIN] = {};

mappings[Role.SYSTEMADMIN] = {};

export {mappings};
