import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ISSUES} from '../../../constants/routes';
import NavigationItem from '../NavigationItem/NavigationItem';
import styles from './DrawerItems.module.css';
import Icons from './DrawerIcons';
import UserLogoutButton from '../../UserStatusPanel/UserLogoutButton';
import {GlobalBranchPicker} from '../../../common/components';

const DrawerItems = ({
    innerComponents: [
        LocationsNavItem,
        TasksNavItem,
        ElementsNavItem,
        ArchivedIssuesNavItem,
        ReportNavItem,
        UsersNavItem,
        BranchNavItem,
        CategoriesNavItem,
        CRUDNavItem,
    ],
    organizationData,
}) => {
    const {t} = useTranslation('sidebar');

    return (
        <div className={styles.drawerItems}>
            <NavigationItem
                path={ISSUES}
                title={t('tabs.default.issues')}
                icon={<Icons.IssuesIcon />}
            />
            {TasksNavItem({
                supervisorHasAccessToTasks:
                    organizationData?.supervisorHasAccessToTasks,
                eventBased: organizationData.eventBased,
            })}
            {ArchivedIssuesNavItem()}
            {ReportNavItem()}
            {ElementsNavItem({eventBased: organizationData.eventBased})}
            {LocationsNavItem({eventBased: organizationData.eventBased})}
            {BranchNavItem({eventBased: organizationData.eventBased})}
            {organizationData?.isIssuesCategoriesFeatureEnabled &&
                CategoriesNavItem()}
            {UsersNavItem()}
            {CRUDNavItem()}
            <hr className={styles.line} />
            <GlobalBranchPicker />
            <span className={styles.onlyMobile}>
                <UserLogoutButton />
            </span>
        </div>
    );
};

const mapStateToProps = store => ({
    branches: store.branch.branches,
    organizationData: store.auth.organizationData,
});

export default connect(mapStateToProps)(DrawerItems);
