import {Issue} from '../../models';

export const addHistoryEntry = (
    history: Issue['history'],
    change: Issue['history'][0]['changes'],
) => {
    const entry = {
        date: new Date(),
        changes: change,
    };
    history.push(entry);
};
