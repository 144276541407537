import firebase from 'firebase/compat/app';
import {SET_LOCATIONS, SET_SELECTED_LOCATION} from './actionTypes';
import {
    fetchDocumentById,
    getItemFromDocumentAndPassToCallback,
} from '../../utils/firestoreDocumentUtils/firestoreDocumentUtils';
import * as Endpoints from '../../constants/endpoints';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';
import {
    Location,
    LocationId,
    uploadLocationMapObject,
} from '../../models/location';

const compareLocationsByName = (a, b) => a.name.localeCompare(b.name);

export const addLocation =
    (
        {
            location,
            locationMapFile,
        }: {location: Location; locationMapFile?: File},
        onSuccess: (locationId: LocationId) => void,
        onError: () => void,
    ) =>
    async () => {
        try {
            const result = await firebase
                .firestore()
                .collection(Endpoints.locations())
                .add(location);

            try {
                const id = result.id;

                if (locationMapFile) {
                    const filePath = await uploadLocationMapObject({
                        id,
                        locationMap: locationMapFile,
                    });
                    const locationWithFilePath: Location = {
                        ...location,
                        locationMap: {
                            ...location?.locationMap,
                            name: location.locationMap?.name || '',
                            height: location.locationMap?.height || 0,
                            width: location.locationMap?.width || 0,
                            size: location.locationMap?.size || 0,
                            image: filePath,
                        },
                    };

                    await updateLocation(
                        locationWithFilePath,
                        id,
                        () => {},
                        () => {
                            throw new Error(
                                'location update with image path failed',
                            );
                        },
                    )();
                }

                onSuccess(id);
            } catch (error) {
                deleteLocation(
                    result.id,
                    () => {},
                    () => {
                        throw new Error('rollback location delete failed');
                    },
                )();
                throw error;
            }
        } catch (error) {
            onError();
            errorHandler(error);
        }
    };

export const updateLocation =
    (
        location: Location,
        key: LocationId,
        onSuccess: () => void,
        onError: () => void,
    ) =>
    () => {
        firebase
            .firestore()
            .collection(Endpoints.locations())
            .doc(key)
            .update(location)
            .then(onSuccess)
            .catch(error => {
                onError();
                errorHandler(error);
            });
    };

export const deleteLocation =
    (locationId: LocationId, onSuccess: () => void, onError: () => void) =>
    () => {
        firebase
            .firestore()
            .collection(Endpoints.locations())
            .doc(locationId)
            .delete()
            .then(result => {
                onSuccess();
            })
            .catch(error => {
                onError();
                errorHandler(error);
            });
    };

export const fetchLocations =
    (locations, setActivityIndicator?: (newState: boolean) => void) =>
    dispatch => {
        setActivityIndicator && setActivityIndicator(true);
        locations = locations.sort(compareLocationsByName);
        setActivityIndicator && setActivityIndicator(false);
        dispatch(setLocations(locations));
    };

export const fetchLocationByDocumentId = documentId => dispatch => {
    const locationDocument = fetchDocumentById(
        Endpoints.locations(),
        documentId,
    );
    getItemFromDocumentAndPassToCallback(locationDocument, location => {
        dispatch(setSelectedLocation(location));
    });
};

export const checkIfLocationExist = async location => {
    const querySnapshot = await firebase
        .firestore()
        .collection(Endpoints.locations())
        .where('name', '==', location.name)
        .where('branch.id', '==', location.branch.id)
        .get();
    return querySnapshot.docs.length !== 0;
};

export const checkIfLocationsExistsByBranch = async branch => {
    const querySnapshot = await firebase
        .firestore()
        .collection(Endpoints.locations())
        .where('branch.id', '==', branch.id)
        .get();
    return querySnapshot.docs.length !== 0;
};

export const fetchAllLocations = () => dispatch => {
    firebase
        .firestore()
        .collection('locations')
        .get()
        .then(collection => {
            if (!collection.empty) {
                dispatch(fetchLocations(collection));
            }
        })
        .catch(error => {
            errorHandler(error);
        });
};

export const setLocations = locations => ({
    locations,
    type: SET_LOCATIONS,
});

export const setSelectedLocation = location => ({
    selectedLocation: location,
    type: SET_SELECTED_LOCATION,
});
