import {Marker as LeafletMarker, Popup} from 'react-leaflet';
import {ClusterClickEvent, MapMarker} from './Map.types';

type Props = {
    item: MapMarker;
    onClusterClick?: (e: ClusterClickEvent) => void;
};
export const Marker = ({item, onClusterClick}: Props) => {
    return (
        <LeafletMarker
            eventHandlers={onClusterClick ? {click: onClusterClick} : undefined}
            position={[item.lat, item.lng]}
        ></LeafletMarker>
    );
};
