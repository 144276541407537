import {Element} from '../../models';
import {SET_ELEMENTS} from '../action/actionTypes';

type State = {
    elements: Element[];
};

type Action = {
    type: string;
    elements?: State['elements'];
};

const initialState: State = {
    elements: [],
};

const reducer = (state = initialState, action: Action): State => {
    if (action.type === SET_ELEMENTS && action.elements) {
        return {...state, elements: action.elements};
    }
    return state;
};

export default reducer;
