export enum Role {
    ADMIN = 'ADMIN',
    SUPERVISOR = 'SUPERVISOR',
    MAINTAINER = 'MAINTAINER',
    REPORTER = 'REPORTER',
    SYSTEMADMIN = 'SYSTEMADMIN',
}

export const allRoles = [
    Role.ADMIN,
    Role.MAINTAINER,
    Role.SUPERVISOR,
    Role.REPORTER,
    Role.SYSTEMADMIN,
];

export const rolesPriority = {
    [Role.SYSTEMADMIN]: 5,
    [Role.ADMIN]: 4,
    [Role.SUPERVISOR]: 3,
    [Role.MAINTAINER]: 2,
    [Role.REPORTER]: 1,
};

export const labels = {
    ADMIN: 'Admin',
    SUPERVISOR: 'Supervisor',
    MAINTAINER: 'Maintainer',
    REPORTER: 'Reporter',
    SYSTEMADMIN: 'System admin',
};
