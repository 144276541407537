import {ReactNode, UIEvent, useCallback, useRef} from 'react';
import styles from './CustomScrollbar.module.css';

type Props = {
    children: ReactNode;
    onScrollReachedEnd?: () => void;
    onScrollReachedEndThreshold?: number;
};

export const CustomScrollbar = ({
    children,
    onScrollReachedEnd,
    onScrollReachedEndThreshold = 1,
}: Props) => {
    const prevScrollHeightRef = useRef(0);

    const onScroll = useCallback(
        (e: UIEvent<HTMLDivElement>) => {
            const {scrollTop, scrollHeight, clientHeight} = e.currentTarget;

            const isReachedEnd =
                scrollTop + clientHeight >=
                scrollHeight * onScrollReachedEndThreshold;

            if (isReachedEnd && prevScrollHeightRef.current !== scrollHeight) {
                prevScrollHeightRef.current = scrollHeight;
                onScrollReachedEnd?.();
            }
        },
        [onScrollReachedEnd],
    );

    return (
        <div className={styles.scrollContainer} onScroll={onScroll}>
            {children}
        </div>
    );
};
