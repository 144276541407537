import {useCallback, useEffect, useState} from 'react';
import CustomTextField from '../textField/CustomTextField';
import {Button} from '../Button/Button';
import {Dialog, IconButton, styled} from '@mui/material';
import {Map} from '../../../components/Map/Map';
import {useToggle} from '../../../hooks/useToggle';
import {MapMarker} from '../../../components/Map/Map.types';
import {useTranslation} from 'react-i18next';
import {Coordinates} from '../../../models';
import {parseCoordinatesToMarker} from './CoordinatesPicker.utils';
import TrashIcon from '../../../static/images/icons/trash.svg';
import {If} from '../If';
import {polandCenterMap} from '../../../store/zustand/useMapStore';

type Props = {
    initialValue: Coordinates | null;
    onChange: (coordinates: Coordinates | null) => void;
};

export const CoordinatesPicker = ({onChange, initialValue}: Props) => {
    const [coordinates, setCoordinates] = useState<Coordinates | null>(
        initialValue || null,
    );
    const [marker, setMarker] = useState<MapMarker | undefined>(
        parseCoordinatesToMarker(initialValue),
    );

    const [mapVisible, _, __, mapToggle] = useToggle(false);

    const {t: tLocations} = useTranslation('locations');

    useEffect(() => {
        onChange(coordinates);
    }, [coordinates]);

    useEffect(() => {
        if (mapVisible) {
            return;
        }

        setMarker(
            coordinates ? parseCoordinatesToMarker(coordinates) : undefined,
        );
    }, [mapVisible, coordinates]);

    const clearPicker = useCallback(() => {
        setCoordinates(null);
        setMarker(undefined);
    }, []);

    const onSubmit = useCallback(() => {
        if (marker) {
            setCoordinates({lat: marker.lat, lng: marker.lng});
        }
        mapToggle();
    }, [marker, mapToggle]);

    return (
        <>
            <div>
                <CustomTextField
                    label={tLocations('details.coordinates.label')}
                    value={
                        coordinates
                            ? `${coordinates.lat} ${coordinates.lng}`
                            : ''
                    }
                    placeholder={tLocations('details.coordinates.placeholder')}
                    fullWidth
                    rightComponent={
                        <>
                            <If condition={coordinates}>
                                <IconButton onClick={clearPicker}>
                                    <img src={TrashIcon} alt="Usuń element" />
                                </IconButton>
                            </If>
                            <RightComponent
                                variant="outlined"
                                onClick={mapToggle}
                            >
                                {tLocations('map.dialog.selectOnMap')}
                            </RightComponent>
                        </>
                    }
                    disabled
                />
                <Dialog open={mapVisible} onClose={mapToggle} maxWidth="lg">
                    <MapContainer>
                        <Map
                            center={marker ?? polandCenterMap}
                            markers={marker ? [marker] : []}
                            onMarker={setMarker}
                            zoom={marker ? 13 : 7}
                            withSearch
                            onSearchSubmit={onSubmit}
                            singleMarkerMode={false}
                        />
                    </MapContainer>
                </Dialog>
            </div>
        </>
    );
};

const RightComponent = styled(Button)`
    margin: 5px;
    height: 100%;
    min-width: 150px;
    padding: 5px 10px;
    display: flex;
`;

const MapContainer = styled('div')`
    display: flex;
    height: 85vh;
    width: 1200px;
    max-width: 100%;
`;
