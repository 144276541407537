import {Switch} from '@mui/material';
import {styled} from '@mui/system';
import styles from './Switch.module.css';

export const SwitchField = ({switchComponent, label}) => (
    <div className={styles.switch}>
        <label>{label}</label>
        {switchComponent}
    </div>
);

export const IOSSwitch = styled(Switch)(({theme}) => ({
    width: 30,
    height: 18,
    padding: 0,
    margin: theme.spacing(1),
    '& .MuiSwitch-switchBase': {
        padding: 1,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: 'var(--white)',
            '& + .MuiSwitch-track': {
                backgroundColor: 'var(--green)',
                opacity: 1,
                border: 'none',
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: 'var(--green)',
            border: '6px solid var(--white)',
        },
    },
    '& .MuiSwitch-thumb': {
        width: 16,
        height: 16,
    },
    '& .MuiSwitch-track': {
        borderRadius: 9,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
    },
}));

export const IOSSwitchBigger = styled(Switch)(({theme}) => ({
    width: 42,
    height: 25,
    padding: 0,
    margin: theme.spacing(1),
    '& .MuiSwitch-switchBase': {
        padding: 1,
        '&.Mui-checked': {
            transform: 'translateX(17px)',
            color: 'var(--white)',
            '& + .MuiSwitch-track': {
                backgroundColor: 'var(--green)',
                opacity: 1,
                border: 'none',
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: 'var(--green)',
            border: '6px solid var(--white)',
        },
    },
    '& .MuiSwitch-thumb': {
        width: 23,
        height: 23,
    },
    '& .MuiSwitch-track': {
        borderRadius: 12,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
    },
}));
