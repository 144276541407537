import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';
import './App.css';
import firebase from 'firebase/compat/app';
import {shallowEqual} from 'react-redux';
import {
    HOME,
    ELEMENTS,
    ISSUES,
    LOGIN,
    ARCHIVED_ISSUES,
    REPORTS,
    LOCATIONS,
    USERS,
    USER,
    TASKS,
    TASK,
    BRANCHES,
    CRUD,
    CATEGORIES,
    ENVIRONMENT,
} from './constants/routes';
import ArchivedIssuePage from './containers/ArchivedIssuePage/ArchivedIssuePage';
import ElementsPage from './containers/ElementsPage/ElementsPage';
import {ElementDetails} from './containers/ElementDetails/ElementDetails';
import LoginPage from './containers/LoginPage/LoginPage';
import withLayout from './hoc/Layout/WithLayout';
import {allRoles, Role} from './constants/roles';
import ReportsPage from './containers/Reports/ReportsPage';
import LocationsPage from './containers/LocationsPage/LocationsPage';
import TasksPage from './containers/TasksPage/TasksPage';
import UsersPage from './containers/Users/UsersPage';
import UserDetails from './containers/UserDetails/UserDetails';
import {NotificationProvider} from './context/notifications';
import TaskDetails from './containers/TaskDetails/TaskDetails';
import BranchesPage from './containers/BranchesPage/BranchesPage';
import EnvironmentPage from './containers/Environment/EnvironmentPage';
import AdminEnvironmentPage from './containers/AdminEnvironment/AdminEnvironmentPage';
import packageJson from '../package.json';
import CategoriesPage from './containers/CategoriesPage/CategoriesPage';
import {withLocationRefresh} from './hoc/LocationRefresh/WithLocationRefresh';
import {useAppSelector} from './store/hooks';
import {HomePage} from './containers/HomePage/HomePage';
import {IssueDetails} from './containers/IssueDetails/IssueDetails';
import {withAuthentication} from './hoc/Authentication/WithAuthentication';

const {version, database} = packageJson;

console.info(`Version: ${version}`);
firebase.auth().useDeviceLanguage();

const App = () => {
    const supervisorHasAccessToTasks = useAppSelector(
        state => state.auth.organizationData.supervisorHasAccessToTasks,
        shallowEqual,
    );

    return (
        <BrowserRouter>
            <NotificationProvider>
                <Switch>
                    <Redirect exact from={HOME} to={ISSUES} />
                    <Route
                        path={ISSUES}
                        exact
                        component={withLayout(
                            withAuthentication(HomePage, true, allRoles),
                            true,
                        )}
                    />
                </Switch>
                <Route
                    path={LOGIN}
                    exact
                    component={withAuthentication(LoginPage, true, allRoles)}
                />
                <Route
                    path="/issue/:id"
                    exact
                    component={withLocationRefresh(
                        withLayout(
                            withAuthentication(IssueDetails, true, allRoles, {
                                archived: false,
                            }),
                        ),
                    )}
                />
                <Route
                    path="/issue/archived/:id"
                    exact
                    component={withLocationRefresh(
                        withLayout(
                            withAuthentication(
                                IssueDetails,
                                true,
                                [
                                    Role.REPORTER,
                                    Role.MAINTAINER,
                                    Role.SUPERVISOR,
                                    Role.ADMIN,
                                    Role.SYSTEMADMIN,
                                ],
                                {
                                    archived: true,
                                },
                            ),
                        ),
                    )}
                />
                <Route
                    path="/element/:id"
                    component={withLayout(
                        withAuthentication(ElementDetails, true, [
                            Role.MAINTAINER,
                            Role.SUPERVISOR,
                            Role.ADMIN,
                            Role.SYSTEMADMIN,
                        ]),
                    )}
                />
                <Route
                    path={ARCHIVED_ISSUES}
                    exact
                    component={withLayout(
                        withAuthentication(ArchivedIssuePage, true, [
                            Role.MAINTAINER,
                            Role.SUPERVISOR,
                            Role.ADMIN,
                            Role.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={ELEMENTS}
                    exact
                    component={withLayout(
                        withAuthentication(ElementsPage, true, [
                            Role.MAINTAINER,
                            Role.SUPERVISOR,
                            Role.ADMIN,
                            Role.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={REPORTS}
                    exact
                    component={withLayout(
                        withAuthentication(ReportsPage, true, [
                            Role.ADMIN,
                            Role.SUPERVISOR,
                            Role.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={LOCATIONS}
                    exact
                    component={withLayout(
                        withAuthentication(LocationsPage, true, [
                            Role.MAINTAINER,
                            Role.SUPERVISOR,
                            Role.ADMIN,
                            Role.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={USERS}
                    exact
                    component={withLayout(
                        withAuthentication(UsersPage, true, [
                            Role.SUPERVISOR,
                            Role.ADMIN,
                            Role.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={USER(':id')}
                    exact
                    component={withLayout(
                        withAuthentication(UserDetails, true, [
                            Role.ADMIN,
                            Role.SYSTEMADMIN,
                        ]),
                    )}
                />
                <Route
                    path={TASKS}
                    exact
                    component={withLayout(
                        withAuthentication(TasksPage, true, [
                            Role.ADMIN,
                            Role.SYSTEMADMIN,
                            ...(supervisorHasAccessToTasks
                                ? [Role.SUPERVISOR]
                                : []),
                        ]),
                        true,
                    )}
                />
                <Route
                    path={TASK(':id')}
                    exact
                    component={withLayout(
                        withAuthentication(TaskDetails, true, [
                            Role.ADMIN,
                            Role.SYSTEMADMIN,
                            ...(supervisorHasAccessToTasks
                                ? [Role.SUPERVISOR]
                                : []),
                        ]),
                    )}
                />
                <Route
                    path={BRANCHES}
                    exact
                    component={withLayout(
                        withAuthentication(BranchesPage, true, [
                            Role.ADMIN,
                            Role.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={CATEGORIES}
                    exact
                    component={withLayout(
                        withAuthentication(CategoriesPage, true, [
                            Role.ADMIN,
                            Role.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={CRUD}
                    exact
                    component={withLayout(
                        withAuthentication(EnvironmentPage, true, [
                            Role.SYSTEMADMIN,
                        ]),
                        true,
                    )}
                />
                <Route
                    path={ENVIRONMENT}
                    exact
                    component={withLayout(
                        withAuthentication(AdminEnvironmentPage, true, [
                            Role.ADMIN,
                        ]),
                        true,
                    )}
                />
            </NotificationProvider>
        </BrowserRouter>
    );
};

export default App;
