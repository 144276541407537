import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
    List,
    ListItem,
    ListColumnCreator,
    IOSSwitch,
    MobileList,
    ListItemMobile,
    MobileHeader,
    MobileDataDisplay,
} from '../../../common/components/index';
import CyclicIcon from './display/cyclicIcon/cyclicIcon';
import {maintainerComparator} from '../../../utils/sorting/sorting';
import Elements from './display/element/elements';
import {TaskColumn} from './TaskList.types';
import moment from 'moment';
import {useAppSelector} from '../../../store/hooks';

const ISSUE_COLUMN_ORDER = Object.values(TaskColumn);

const sortColumns = (a: TaskColumn, b: TaskColumn): number => {
    return ISSUE_COLUMN_ORDER.indexOf(a) - ISSUE_COLUMN_ORDER.indexOf(b);
};

type Props = {
    tasks: any[];
    onTaskClick: (taskId: string) => () => void;
    onActiveStateChange: (taskId: string, active: boolean) => void;
    visibleColumns: TaskColumn[];
};

const TasksList = ({
    tasks,
    onTaskClick,
    onActiveStateChange,
    visibleColumns,
}: Props) => {
    const {t} = useTranslation('tasks');

    const eventBased = useAppSelector(
        state => state.auth.organizationData.eventBased,
    );

    const columnsMap = new Map<TaskColumn, any>([
        [
            TaskColumn.ELEMENT_NAME,
            new ListColumnCreator('name', t('table.columns.default.name'), {}),
        ],
        [
            TaskColumn.DEADLINE,
            new ListColumnCreator(
                'startDate',
                t('table.columns.default.deadline'),
                {},
            ),
        ],
        [
            TaskColumn.SELECTED_ELEMENTS,
            new ListColumnCreator(
                'elements',
                eventBased
                    ? t('table.columns.eventBased.selectedWorkStations')
                    : t('table.columns.default.selectedElements'),
                {
                    accessAttribute: task => task.elements[0]?.name,
                },
            ),
        ],
        [
            TaskColumn.ASSIGNED_TO,
            new ListColumnCreator(
                'assignedTo',
                t('table.columns.default.assignedTo'),
                {
                    customComparator: maintainerComparator,
                },
            ),
        ],
        [
            TaskColumn.CYCLIC,
            new ListColumnCreator(
                'cyclic',
                t('table.columns.default.cyclic'),
                {},
            ),
        ],
        [
            TaskColumn.ACTIVE,
            new ListColumnCreator(
                'active',
                t('table.columns.default.active'),
                {},
            ),
        ],
    ]);

    const columns = useMemo(() => {
        return visibleColumns
            .sort(sortColumns)
            .map(column => columnsMap.get(column));
    }, [visibleColumns]);

    const isColumnVisible = (value: TaskColumn) =>
        visibleColumns.includes(value);

    const columnIds = useMemo(
        () => [...columnsMap.keys()].map(column => columnsMap.get(column).id),
        [columnsMap],
    );

    const getTaskListItemTemplate = task => (
        <ListItem
            key={task.id}
            onClick={onTaskClick(task.id)}
            columns={columnIds}
        >
            {isColumnVisible(TaskColumn.ELEMENT_NAME) && `${task.name}`}
            {isColumnVisible(TaskColumn.DEADLINE) &&
                moment(task.startDate).format('DD/MM/YYYY')}
            {isColumnVisible(TaskColumn.SELECTED_ELEMENTS) && (
                <Elements elements={task.elements} />
            )}
            {isColumnVisible(TaskColumn.ASSIGNED_TO) &&
                (task.assignedTo
                    ? `${task.assignedTo.name} ${task.assignedTo.surname}`
                    : ' ')}

            {isColumnVisible(TaskColumn.CYCLIC) && (
                <CyclicIcon active={task.repeat} />
            )}
            {isColumnVisible(TaskColumn.ACTIVE) && (
                <IOSSwitch
                    checked={task.active}
                    name={task.id}
                    onClick={(event: any) => {
                        event.stopPropagation();
                        const taskId = event.target.name;
                        const active = event.target.checked;
                        onActiveStateChange(taskId, active);
                    }}
                />
            )}
        </ListItem>
    );

    const getMobileTaskListItemTemplate = task => {
        const locationsNumber = task.elements.reduce((set, element) => {
            set.add(element.location.name);
            return set;
        }, new Set()).size;
        return (
            <ListItemMobile
                key={task.id}
                onClick={onTaskClick(task.id)}
                classes={columnIds}
            >
                <MobileHeader classes={['date']}>
                    {moment(task.startDate).format('DD/MM/YYYY')}
                </MobileHeader>
                <MobileDataDisplay
                    image={undefined}
                    title={task.name}
                    withoutImage
                    left={t('plurals.location', {count: locationsNumber})}
                    right={t('plurals.element', {count: task.elements.length})}
                />
            </ListItemMobile>
        );
    };

    return (
        <>
            <List
                columns={columns}
                rows={tasks}
                rowTemplate={getTaskListItemTemplate}
                name="tasks"
            />
            <MobileList
                columns={columns}
                rows={tasks}
                rowTemplate={getMobileTaskListItemTemplate}
            />
        </>
    );
};

export default TasksList;
