import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import Picker from '../picker/Picker';
import styles from './globalBranchPicker.module.css';
import {setUserSelectedBranches} from '../../../store/action';
import {
    Option,
    MultiValue,
    ValueContainer,
} from './components/componentsForPicker';

class GlobalBranchPicker extends Component {
    allBranch = {
        id: 'all',
        name: 'globalBranchPicker.pickAll',
    };

    possibleOptions = [];

    componentDidMount() {
        this.allBranch = this.translateOption(this.allBranch);
        this.setPossibleOptions();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.branches.length !== this.props.branches.length) {
            this.setPossibleOptions();
        }
    }

    translateOption = option => ({
        id: option.id,
        name: this.t(option.name),
    });

    setPossibleOptions = () => {
        const {branches} = this.props;
        const allBranch = this.mapBranchToPicker(this.allBranch);
        const mappedBranches = branches.map(this.mapBranchToPicker);
        this.possibleOptions = [allBranch, ...mappedBranches];
    };

    handleChange = branches => {
        const userBranches = this.props.branches;

        if (!branches || !branches.length) {
            branches = [];
        } else if (this.allBranchesSelected()) {
            branches = this.allBranchPresent(branches)
                ? branches.filter(branch => branch.value !== this.allBranch.id)
                : [];
        }

        if (this.allBranchPresent(branches)) {
            branches = userBranches.map(this.mapBranchToPicker);
        }

        this.props.setUserSelectedBranches(
            branches.map(this.mapBranchFromPicker),
        );
    };

    allBranchPresent = branches =>
        branches.some(branch => branch.value === this.allBranch.id);

    allBranchesSelected = () => {
        const {branches, selectedBranches} = this.props;
        return branches.length === selectedBranches.length;
    };

    mapBranchToPicker = branch => ({
        value: branch.id,
        label: branch.name,
    });

    mapBranchFromPicker = change => ({
        id: change.value,
        name: change.label,
    });

    render() {
        this.t = this.props.t;
        const selectedBranches = this.props.selectedBranches.map(
            this.mapBranchToPicker,
        );
        if (this.allBranchesSelected()) {
            selectedBranches.push(this.mapBranchToPicker(this.allBranch));
        }

        return (
            this.props.branches.length > 1 && (
                <Picker
                    headerClassName={styles.whiteHeader}
                    globalBranchPicker
                    componentsForPicker={{Option, MultiValue, ValueContainer}}
                    label={
                        this.props.organizationData.eventBased
                            ? this.t('globalBranchPicker.eventBased.name')
                            : this.t('globalBranchPicker.default.name')
                    }
                    selectedValue={selectedBranches}
                    options={this.possibleOptions}
                    name={
                        this.props.organizationData.eventBased
                            ? this.t('globalBranchPicker.eventBased.name')
                            : this.t('globalBranchPicker.default.name')
                    }
                    handleChange={this.handleChange}
                    placeholder={
                        this.props.organizationData.eventBased
                            ? this.t(
                                  'globalBranchPicker.eventBased.placeholder',
                              )
                            : this.t('globalBranchPicker.default.placeholder')
                    }
                    clearable={false}
                    closeMenuOnSelect={false}
                    searchable={false}
                    menuPlacement="top"
                    multi
                />
            )
        );
    }
}

GlobalBranchPicker.defaultProps = {
    bottomLabelActive: true,
};

const mapStateToProps = state => ({
    selectedBranches: state.branch.selectedBranches,
    branches: state.auth.userData?.branches || [],
    organizationData: state.auth.organizationData,
});

const mapDispatchToProps = {
    setUserSelectedBranches: setUserSelectedBranches,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation('common')(GlobalBranchPicker));
