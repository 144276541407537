import firebase from 'firebase/compat/app';
import {locations} from '../../constants/endpoints';
import QueryBuilder from './QueryBuilder';

class LocationQueryBuilder {
    constructor(branchIds) {
        const createQuery = () => firebase.firestore().collection(locations());
        this._queryBuilder = new QueryBuilder(createQuery, branchIds, {});
    }

    static query(branchIds) {
        return new LocationQueryBuilder(branchIds);
    }

    onSnapshot(callback, onError) {
        return this._queryBuilder.onSnapshot(callback, onError);
    }

    get() {
        return this._queryBuilder.get();
    }
}

const getLocationsQuery = LocationQueryBuilder.query;

export default getLocationsQuery;
