import {useCallback, useState} from 'react';

export const useToggle = (defaultValue: boolean) => {
    const [state, setState] = useState(defaultValue);

    const setTrue = useCallback(() => setState(true), []);
    const setFalse = useCallback(() => setState(false), []);

    const toggle = useCallback(() => setState(prev => !prev), []);

    return [state, setTrue, setFalse, toggle] as const;
};
