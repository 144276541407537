import {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/compat/app';
import {withTranslation} from 'react-i18next';
import {
    fetchLocations,
    getElementsByLocationId,
    onCreateNewIssue,
    setElements,
} from '../../../store/action';
import AddIssueLayout from './AddIssueLayout';
import {MAX_PHOTOS, MAX_PHOTO_SIZE} from '../../../constants/files';
import {mappings} from './AddIssueRoleRoleMapper';
import {withUserRole} from '../../../hoc/User/WithUserRole';
import {NotificationContext} from '../../../context/notifications';
import getUsersQuery from '../../../utils/queryBuilder/UsersQueryBuilder';
import getLocationsQuery from '../../../utils/queryBuilder/LocationQueryBuilder';
import {TYPES} from '../../../constants/error';
import {getUserRole} from '../../../store/action/authHelpers';
import {fetchCategories} from '../../../store/action/category';
import * as endpoints from '../../../constants/endpoints';
import {
    compareStringWithNumericPrefixes,
    getComparator,
    maintainerComparator,
} from '../../../utils/sorting/sorting';
import errorHandler from '../../../common/components/ExceptionReporting/ErrorReporting';
import {Role} from '../../../constants/roles';
import {clearScannedElement} from '../../../store/action/qrCode';
import {LatLng} from 'leaflet';
import {mapBoundsStartingPosition} from '../../../utils/map/type';
import {
    type Category,
    type Element,
    type Location,
    ISSUE_PRIORITY,
    type IssueLocationMarker,
    type IssuePriority,
    findLocationFromQRCode,
    findElementFromQRCode,
    UserDetails,
} from '../../../models';
import {QrCodeState} from '../../../store/reducer/qrCode';
import {RootState} from '../../../store/configureStore';
import {SelectOption} from '../../../common/components/Select/CustomSelect';

const defaultPriority = ISSUE_PRIORITY.low;

type Props = {
    qrCodeData: QrCodeState;
} & any;

type State = {
    selectedLocation: Location | null;
    selectedCategory: Category | null;
    selectedElement: Element | null;
    assignedEmployee: any | null;
    issueImageFiles: any[];
    description: string;
    phoneNumber: string;
    elementIconUrl: string;
    users: UserDetails[];
    priority: IssuePriority;
    locationMarker?: IssueLocationMarker | null;
};

class AddIssueDialog extends Component<Props, State> {
    state: State = {
        selectedLocation: null,
        selectedCategory: null,
        selectedElement: null,
        assignedEmployee: null,
        issueImageFiles: [],
        description: '',
        phoneNumber: '',
        elementIconUrl: '',
        users: [],
        priority: defaultPriority,
        locationMarker: null,
    };

    static contextType = NotificationContext;

    unsubscribeLocations: (() => void) | undefined;

    unsubscribeCategories: (() => void) | undefined;

    notificationSystem: any = null;

    categoriesReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | null =
        null;

    componentDidMount() {
        const {selectedBranches} = this.props;
        const branchIds = selectedBranches.map(branch => branch.id);
        this.subscribeOnLocations(branchIds);
        this.categoriesReference = firebase
            .firestore()
            .collection(endpoints.categories());

        if (this.props.organizationData.isIssuesCategoriesFeatureEnabled) {
            this.subscribeOnCategories();
        }
        this.notificationSystem = this.context;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.selectedBranches.length !==
            prevProps.selectedBranches.length
        ) {
            const branchIds = this.props.selectedBranches.map(
                branch => branch.id,
            );
            this.unsubscribeLocations?.();
            this.subscribeOnLocations(branchIds);
        }
        if (
            this.props.locations !== prevProps.locations &&
            this.props.locations.length > 0
        ) {
            this.getLocationFromQRCode();
        }
        if (
            this.props.elements !== prevProps.elements &&
            this.props.elements.length > 0
        ) {
            this.getElementFromQRCode();
        }
    }

    getLocationFromQRCode() {
        const {qrCodeData, locations} = this.props;
        if (!qrCodeData.locationId) return;
        const location = findLocationFromQRCode(qrCodeData, locations);
        if (!location) return;

        this.props.getElementsByLocationId(location.id);
        this.setState({
            selectedLocation: location,
        });
    }

    getElementFromQRCode() {
        const {qrCodeData, elements} = this.props;
        if (!qrCodeData.id) return;
        const element = findElementFromQRCode(qrCodeData.id, elements);
        if (!element) return;

        this.setState({
            selectedElement: element,
        });
    }

    subscribeOnLocations(branchIds) {
        this.unsubscribeLocations = getLocationsQuery(branchIds).onSnapshot(
            this.props.fetchLocations,
            errorHandler,
        );
    }

    subscribeOnCategories() {
        this.unsubscribeCategories = this.categoriesReference?.onSnapshot(
            this.props.fetchCategories,
            errorHandler,
        );
    }

    componentWillUnmount() {
        this.unsubscribeLocations?.();
        this.unsubscribeCategories?.();
    }

    render() {
        const {
            locations,
            open,
            elements,
            loading,
            organizationData,
            categories,
            userData,
            qrCodeData,
        } = this.props;
        const comparator = getComparator(
            compareStringWithNumericPrefixes,
            'ASC',
        );
        const sortedElements = elements.sort((a, b) =>
            comparator(a.name, b.name),
        );

        const {
            selectedElement,
            assignedEmployee,
            selectedLocation,
            issueImageFiles,
            users,
            selectedCategory,
        } = this.state;
        const role = getUserRole(userData);
        const disableElementAndLocationPicker =
            role === Role.REPORTER && qrCodeData?.id;

        return withUserRole(AddIssueLayout, mappings, {
            open,
            onClose: this.onClose,
            selectedLocation,
            onLocationChange: this.onLocationChange,
            locations,
            selectedCategory,
            onSelectedCategoryChange: this.onSelectedCategoryChange,
            categories,
            selectedElement,
            onElementChange: this.onElementChange,
            elements: sortedElements,
            assignedEmployee,
            onAssignedEmployeeChange: this.onAssignedEmployeeChange,
            users,
            loading,
            organizationData,
            onDescriptionChange: this.onDescriptionChange,
            onPhoneNumberChange: this.onPhoneNumberChange,
            isSubmitButtonDisabled: this.isSubmitButtonDisabled(),
            onSubmitNewIssueHandler: this.onSubmitNewIssueHandler,
            issueImageFiles: issueImageFiles,
            onIssueImageDrop: this.onIssueImageDrop,
            onRemoveIssueImage: this.onRemoveIssueImage,
            onPriorityChange: this.onPriorityChange,
            onLocationMarkerSave: this.onLocationMarkerSave,
            onLocationMarkerDelete: this.onLocationMarkerDelete,
            defaultPriority: defaultPriority,
            isIssuesCategoriesFeatureEnabled:
                this.props.organizationData.isIssuesCategoriesFeatureEnabled,
            maintainerHasNoPermissionToAssignUsersToIssue:
                this.props.organizationData
                    .maintainerHasNoPermissionToAssignUsersToIssue,
            disableElementAndLocationPicker,
            isIssueLocalizationEnabled:
                this.props.organizationData.isIssueLocalizationEnabled,
        });
    }

    onLocationMarkerSave = (latLng: LatLng) => {
        this.setState({
            locationMarker: {
                bounds: {
                    start: mapBoundsStartingPosition,
                    end: [
                        this.state.selectedLocation?.locationMap?.height || 0,
                        this.state.selectedLocation?.locationMap?.width || 0,
                    ],
                },
                position: {lat: latLng.lat, lng: latLng.lng},
            },
        });
    };

    onLocationMarkerDelete = () => this.setState({locationMarker: null});

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    onRemoveIssueImage = index => {
        const issueImageFiles = [...this.state.issueImageFiles];
        issueImageFiles.splice(index, 1);
        this.setState({issueImageFiles});
    };

    onAssignedEmployeeChange = event => {
        const user =
            this.state.users.find(user => event.value === user.uid) || null;
        this.setState({assignedEmployee: user});
    };

    onIssueImageDrop = files => {
        const {issueImageFiles} = this.state;
        const numberOfunacceptedFiles = files.filter(
            file => file.size > MAX_PHOTO_SIZE,
        ).length;
        const fullImageCount = issueImageFiles.length + files.length;
        if (fullImageCount > MAX_PHOTOS) {
            this.showNotification(
                this.props.t('notifications.dialog.tooManyPhotosError'),
                TYPES.error,
            );
        } else if (numberOfunacceptedFiles > 0) {
            this.showNotification(
                this.props.t('notifications.dialog.tooLargePhotoError'),
                TYPES.error,
            );
        } else {
            const images = [...this.state.issueImageFiles, ...files];
            this.setState({issueImageFiles: images});
        }
    };

    onDescriptionChange = event => {
        this.setState({description: event.target.value});
    };

    onPhoneNumberChange = phoneNumber => {
        this.setState({phoneNumber});
    };

    onLocationChange = event => {
        const location = {
            ...this.props.locations.find(
                location => event.value === location.id,
            ),
        };
        if (location !== this.state.selectedLocation) {
            this.setState({
                selectedLocation: location,
                selectedElement: null,
                selectedCategory: null,
                assignedEmployee: null,
            });
            const {rolesToFetchUsersWith} = mappings[getUserRole()].roleProps;

            getUsersQuery()
                .withBranch(location.branch)
                .withRoles(rolesToFetchUsersWith)
                .get()
                .then(users => {
                    const byNameAndSurname = getComparator(
                        maintainerComparator,
                        'DESC',
                    );

                    this.setState({users: users.sort(byNameAndSurname)});
                });
            this.props.getElementsByLocationId(location.id);
        }
    };

    onSelectedCategoryChange = (event: SelectOption) => {
        const selectedCategory =
            this.props.categories.find(
                category => event.value === category.id,
            ) || null;

        if (selectedCategory !== this.state.selectedCategory) {
            this.setState({
                selectedCategory,
                assignedEmployee: null,
            });

            if (this.state.selectedLocation) {
                const {rolesToFetchUsersWith} =
                    mappings[getUserRole()].roleProps;

                getUsersQuery()
                    .withBranch(this.state.selectedLocation.branch)
                    .withRoles(rolesToFetchUsersWith)
                    .withCategory(selectedCategory.id)
                    .get()
                    .then(users => this.setState({users}));
            }
        }
    };

    onElementChange = event => {
        const element = {
            ...this.props.elements.find(element => event.value === element.id),
        };
        this.setState({selectedElement: element});
    };

    onPriorityChange = event => {
        const priority = event.currentTarget.value;
        this.setState({priority});
    };

    isSubmitButtonDisabled = () =>
        !this.state.selectedLocation ||
        !this.state.selectedElement ||
        !this.state.description.length ||
        (this.state.phoneNumber.length > 0 &&
            this.state.phoneNumber.length < 9);

    onClose = () => {
        const role = getUserRole(this.props.userData);
        this.setState(
            {
                selectedCategory: null,
                selectedLocation: null,
                selectedElement: null,
                assignedEmployee: null,
                issueImageFiles: [],
                priority: 'low',
                description: '',
            },
            () => {
                this.props.clearElements();
                role !== Role.REPORTER && this.props.clearScannedElement();
                this.props.handleClose();
            },
        );
    };

    onSubmitNewIssueHandler = () => {
        const {
            description,
            priority,
            assignedEmployee,
            issueImageFiles,
            selectedElement,
            selectedCategory,
            locationMarker,
            phoneNumber,
        } = this.state;

        const issue = {
            issueDescription: description,
            priority,
            branch: selectedElement?.location.branch,
            locationMarker,
            phoneNumber,
        };
        const category =
            this.props.organizationData.isIssuesCategoriesFeatureEnabled &&
            selectedCategory &&
            selectedCategory.id !== 'null'
                ? {
                      id: selectedCategory.id,
                      name: selectedCategory.name,
                  }
                : {id: 'null', name: 'null'};
        this.props.onCreateNewIssue(
            issue,
            selectedElement,
            assignedEmployee,
            issueImageFiles,
            category,
            () => {
                this.showNotification(
                    this.props.t('notifications.dialog.elementAddSuccess'),
                    'success',
                );
                this.props.refreshIssueList();
            },
            () =>
                this.showNotification(
                    this.props.t('notifications.dialog.elementAddError'),
                    TYPES.error,
                ),
        );
        this.onClose();
    };
}

const mapStateToProps = (state: RootState) => ({
    elements: state.element.elements,
    userData: state.auth.userData,
    organizationData: state.auth.organizationData,
    locations: state.location.locations,
    selectedBranches: state.branch.selectedBranches,
    categories: state.category.categories,
    qrCodeData: state.qrCode,
});

const mapDispatchToProps = dispatch => ({
    fetchCategories: dispatch(fetchCategories),
    fetchLocations: querySnapshot => dispatch(fetchLocations(querySnapshot)),
    getElementsByLocationId: locationId =>
        dispatch(getElementsByLocationId(locationId)),
    onCreateNewIssue: dispatch(onCreateNewIssue),
    clearElements: () => dispatch(setElements([])),
    clearScannedElement: () => dispatch(clearScannedElement()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation('issues')(AddIssueDialog));
