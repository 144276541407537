import {Role} from '../../constants/roles';
import {UserDetails} from '../../models';
import {AppStorage, StorageItem} from '../../storage/AppStorage';

const isRole = (userRoles: string[], role: string) =>
    userRoles.includes(role.toLowerCase());

export const getUserRole = (userData?: UserDetails | null) => {
    const data = userData || AppStorage.get(StorageItem.userData);
    const userRoles = data ? data.roles : {};
    const userRolesKeys = Object.keys(userRoles);

    if (isRole(userRolesKeys, Role.SYSTEMADMIN)) {
        return Role.SYSTEMADMIN;
    }

    if (isRole(userRolesKeys, Role.ADMIN)) {
        return Role.ADMIN;
    }

    if (isRole(userRolesKeys, Role.SUPERVISOR)) {
        return Role.SUPERVISOR;
    }

    if (isRole(userRolesKeys, Role.MAINTAINER)) {
        return Role.MAINTAINER;
    }

    return Role.REPORTER;
};
