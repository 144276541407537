import React from 'react';
import {useTranslation} from 'react-i18next';
import {ListActionPanel} from '../../../common/components/index';

const TasksPageControl = ({onSearchTextChange, onToggleAddDialogHandler,onSettingsIconClick}) => {
    const {t} = useTranslation('tasks');

    return (
        <ListActionPanel
            searchPlaceholder={t('main.searchForTask')}
            title={t('pageTitle')}
            addButtonText={t('main.addTask')}
            onSearchTextChange={onSearchTextChange}
            onToggleAddDialogHandler={onToggleAddDialogHandler}
            showAddButton
            onSettingsIconClick={onSettingsIconClick}
        />
    );
};

export default TasksPageControl;
