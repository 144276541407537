import {ReactNode, useCallback, useState} from 'react';
import UserName from '../../components/UserName/UserName';
import {useAppSelector} from '../../store/hooks';

type Props = {
    children: ReactNode;
};
export const WithUser = ({children}: Props) => {
    const [menuExpanded, setMenuExpanded] = useState(false);

    const user = useAppSelector(state => state.auth.userData);

    const onExpandButtonClick = useCallback(() => {
        setMenuExpanded(prev => !prev);
    }, []);

    if (!user) {
        return children;
    }

    return (
        <UserName
            userName={user.email}
            menuExpanded={menuExpanded}
            onExpandButtonClick={onExpandButtonClick}
        />
    );
};
