import styles from './ListTitle.module.css';
import {If} from '../If';
import {clsx} from 'clsx';
import {ReactNode} from 'react';

type Props = {
    title: string;
    className?: string;
    rightComponent?: ReactNode;
};

const ListTitle = ({title, rightComponent, className}: Props) => (
    <div className={clsx(styles.listTitle, className)}>
        {title}
        <If condition={rightComponent}>{rightComponent}</If>
    </div>
);

export default ListTitle;
