import {Issue} from '../../models';
import {
    SET_ARCHIVED_ISSUES,
    SET_ISSUES,
    SET_SORTED_ISSUES_IDS,
} from '../action/actionTypes';

type State = {
    issues: Issue[];
    archivedIssues: Issue[];
    sortedIssuesIds: string[];
};

const initialState: State = {
    issues: [],
    archivedIssues: [],
    sortedIssuesIds: [],
};

const reducer = (state = initialState, action): State => {
    switch (action.type) {
        case SET_ISSUES:
            return {
                ...state,
                issues: action.issues,
            };
        case SET_ARCHIVED_ISSUES:
            return {
                ...state,
                archivedIssues: action.archivedIssues,
            };
        case SET_SORTED_ISSUES_IDS:
            return {
                ...state,
                sortedIssuesIds: action.sortedIssuesIds,
            };
        default:
            return state;
    }
};

export default reducer;
