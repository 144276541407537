import {useTranslation} from 'react-i18next';
import {CustomCircularProgress, ListActionPanel} from '../../common/components';
import {useAppSelector} from '../../store/hooks';
import {AddElementDialog} from '../../components/Element/AddElementDialog/AddElementDialog';
import {getUserRole} from '../../store/action/authHelpers';
import {Role} from '../../constants/roles';
import {If} from '../../common/components/If';
import {Location} from '../../models';
import {ChangeEvent, ReactNode} from 'react';

type Props = {
    actionPending: boolean;
    onToggleAddDialogHandler: () => void;
    onSearchTextChange: (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => void;
    locations: Location[];
    addDialogOpen: boolean;
    onElementSubmit;
    elementsList: ReactNode;
};

const ElementsPageLayout = ({
    actionPending,
    addDialogOpen,
    elementsList,
    locations,
    onElementSubmit,
    onSearchTextChange,
    onToggleAddDialogHandler,
}: Props) => {
    const eventBased = useAppSelector(
        state => state.auth.organizationData.eventBased,
    );

    const {t: tElements} = useTranslation('elements');

    const isReporter = getUserRole() === Role.REPORTER;
    const isMaintainer = getUserRole() === Role.MAINTAINER;

    const showAddDialog = !isReporter && !isMaintainer;

    return (
        <div>
            {actionPending && <CustomCircularProgress />}
            <ListActionPanel
                searchPlaceholder={
                    eventBased
                        ? tElements('main.eventBased.searchPlaceholder')
                        : tElements('main.default.searchPlaceholder')
                }
                title={
                    eventBased
                        ? tElements('main.eventBased.title')
                        : tElements('main.default.title')
                }
                addButtonText={
                    eventBased
                        ? tElements('main.eventBased.actionBtn')
                        : tElements('main.default.actionBtn')
                }
                onSearchTextChange={onSearchTextChange}
                onToggleAddDialogHandler={onToggleAddDialogHandler}
                showAddButton={showAddDialog}
            />
            <If condition={showAddDialog}>
                <AddElementDialog
                    dialogTitle={
                        eventBased
                            ? tElements('dialog.eventBased.title')
                            : tElements('dialog.default.title')
                    }
                    locations={locations}
                    open={addDialogOpen}
                    handleClose={onToggleAddDialogHandler}
                    onElementSubmit={onElementSubmit}
                    loading={actionPending}
                />
            </If>
            <div>{elementsList}</div>
        </div>
    );
};

export default ElementsPageLayout;
