import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
    List,
    ListItem,
    ListColumnCreator,
} from '../../../common/components/index';
import styles from './ArchivedIssueList.module.css';
import ElementName from '../../../common/components/list/listItem/display/elementName/elementName';
import ListItemMobile from '../../../common/components/list/listItem/ListItemMobile/ListItemMobile';
import MobileHeader from '../../../common/components/list/listItem/display/mobileHeader/mobileHeader';
import MobileDataDisplay from '../../../common/components/list/listItem/display/mobileDataDisplay/moblieDataDisplay';
import MobileList from '../../../common/components/list/MobileList';
import Status from '../IssuesList/IssueListItem/display/Status/Status';
import moment from 'moment';
import {ArchiveColumn} from './ArchiveIssueList.types';
import {Issue} from '../../../models';

type Props = {
    issues: any[];
    visibleColumns: ArchiveColumn[];
    onIssueClick: (id: string) => () => void;
};

const ISSUE_COLUMN_ORDER = Object.values(ArchiveColumn);

const sortColumns = (a: ArchiveColumn, b: ArchiveColumn): number => {
    return ISSUE_COLUMN_ORDER.indexOf(a) - ISSUE_COLUMN_ORDER.indexOf(b);
};

const ArchivedIssuesList: React.FC<Props> = ({
    issues,
    visibleColumns,
    onIssueClick,
}) => {
    const {t} = useTranslation('archive');
    const organizationData = useSelector(
        (state: any) => state.auth.organizationData,
    );

    const columnsMap = useMemo(
        () =>
            new Map<ArchiveColumn, any>([
                [
                    ArchiveColumn.ELEMENT_NAME,
                    new ListColumnCreator(
                        'elementName',
                        organizationData.eventBased
                            ? t('table.columns.eventBased.workStation')
                            : t('table.columns.default.element'),
                        {accessAttribute: issue => issue.element.name},
                    ),
                ],
                [
                    ArchiveColumn.LOCATION_NAME,
                    new ListColumnCreator(
                        'locationName',
                        organizationData.eventBased
                            ? t('table.columns.eventBased.company')
                            : t('table.columns.default.location'),
                        {accessAttribute: issue => issue.element.location.name},
                    ),
                ],
                [
                    ArchiveColumn.CREATED_DATE,
                    new ListColumnCreator(
                        'createdDate',
                        t('table.columns.default.creationDate'),
                        {},
                    ),
                ],
                [
                    ArchiveColumn.DESCRIPTION,
                    new ListColumnCreator(
                        'issueDescription',
                        t('table.columns.default.description'),
                        {},
                    ),
                ],
                [
                    ArchiveColumn.ASSIGNED_TO,
                    new ListColumnCreator(
                        'assignedEmployee',
                        t('table.columns.default.assignedTo'),
                        {
                            accessAttribute: issue =>
                                issue.assignedTo &&
                                `${issue.assignedTo.name} ${issue.assignedTo.surname}`,
                        },
                    ),
                ],
                [
                    ArchiveColumn.CLOSED_DATE,
                    new ListColumnCreator(
                        'closedDate',
                        t('table.columns.default.closedDate'),
                        {},
                    ),
                ],
                [
                    ArchiveColumn.CLOSED_BY,
                    new ListColumnCreator(
                        'closedBy',
                        t('table.columns.default.closedBy'),
                        {
                            accessAttribute: issue =>
                                `${issue.acceptedBy.name} ${issue.acceptedBy.surname}`,
                        },
                    ),
                ],
            ]),
        [organizationData.eventBased, t],
    );

    const columns = useMemo(() => {
        return visibleColumns
            .sort(sortColumns)
            .map(column => columnsMap.get(column));
    }, [visibleColumns]);

    const isColumnVisible = (value: ArchiveColumn) =>
        visibleColumns.includes(value);

    const columnIds = useMemo(
        () => [...columnsMap.keys()].map(column => columnsMap.get(column).id),
        [columnsMap],
    );

    const getIssueListItemTemplate = (issue: Issue) => (
        <ListItem
            key={issue.id}
            columns={columnIds}
            onClick={onIssueClick(issue.id)}
        >
            {isColumnVisible(ArchiveColumn.ELEMENT_NAME) && (
                <ElementName
                    elementName={issue.element.name}
                    iconUri={issue.iconUri}
                />
            )}
            {isColumnVisible(ArchiveColumn.LOCATION_NAME) &&
                `${issue.element.location.name}`}
            {isColumnVisible(ArchiveColumn.CREATED_DATE) &&
                moment(issue.createdDate).format('DD/MM/YYYY')}
            {isColumnVisible(ArchiveColumn.DESCRIPTION) && (
                <span>{issue.issueDescription}</span>
            )}
            {isColumnVisible(ArchiveColumn.ASSIGNED_TO) &&
                (issue.assignedTo
                    ? `${issue.assignedTo.name} ${issue.assignedTo.surname}`
                    : ` `)}
            {isColumnVisible(ArchiveColumn.CLOSED_DATE) &&
                moment(issue.closedDate).format('DD/MM/YYYY')}
            {isColumnVisible(ArchiveColumn.CLOSED_BY) &&
                (issue.acceptedBy
                    ? `${issue.acceptedBy.name} ${issue.acceptedBy.surname}`
                    : ` `)}
        </ListItem>
    );

    const getMobileIssueListItemTemplate = (issue: Issue) => (
        <ListItemMobile key={issue.id} onClick={onIssueClick(issue.id)}>
            <MobileHeader classes={['status', 'date']}>
                <Status issue={issue} />
                {moment(issue.createdDate).format('DD/MM/YYYY')}
            </MobileHeader>
            <MobileDataDisplay
                withoutImage={false}
                image={issue.iconUri}
                left={issue.element.name}
                right={issue.element.location.name}
                title={issue.issueDescription}
            />
        </ListItemMobile>
    );

    return (
        <>
            <List
                className={styles.listA}
                columns={columns}
                rows={issues}
                rowTemplate={getIssueListItemTemplate}
                name="archivedIssues"
            />
            <MobileList
                columns={['elementName', 'image']}
                rows={issues}
                rowTemplate={getMobileIssueListItemTemplate}
            />
        </>
    );
};

export default ArchivedIssuesList;
