import firebase from 'firebase/compat/app';
import {task} from '../../constants/endpoints';
import {taskConverter} from '../converter/taskConverter';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';
import {Task} from '../../models/task';

export const fetchAllTasks = callback =>
    firebase
        .firestore()
        .collection(task())
        .withConverter(taskConverter)
        .onSnapshot(callback);

export const updateTask = (id: string, data: Task) =>
    firebase.firestore().collection(task()).doc(id).update(data);

export const deleteTask = (taskId: string) =>
    firebase.firestore().collection(task()).doc(taskId).delete();

export const addNewTask = (
    newTask: Task,
    onSuccess: () => void,
    onError: () => void,
) => {
    firebase
        .firestore()
        .collection(task())
        .add(newTask)
        .then(onSuccess)
        .catch(err => {
            errorHandler(err);
            onError();
        });
};
