import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom/client';
import {QrCodeWithText} from '../../components/QrCodeWithText/QrCodeWithText';

export const generateAndDownloadQR = async ({
    content,
    fileName,
    locationName,
    elementName,
}) => {
    try {
        const qrCodeContainer = document.createElement('div');
        qrCodeContainer.style.position = 'absolute';
        document.body.appendChild(qrCodeContainer);
        const root = ReactDOM.createRoot(qrCodeContainer);

        root.render(
            <QrCodeWithText
                content={content}
                locationName={locationName}
                elementName={elementName}
            />,
        );

        await new Promise(resolve => setTimeout(resolve, 100));

        html2canvas(qrCodeContainer, {useCORS: true}).then(canvas => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = `${fileName}.png`;
            link.click();
            root.unmount();
            document.body.removeChild(qrCodeContainer);
        });
    } catch (err) {
        throw err;
    }
};
