import React, {Component, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Picker from '../../../common/components/picker/Picker';
import {Option, MultiValue, ValueContainer} from './componentsForPicker';
import {
    mapUserToPicker,
    allUsersOption,
    generateOptions,
    isAllUsersOptionPresent,
    areAllUsersSelected,
    findSelectedUserInUsers,
    translateAllUsersOption,
} from './multiUserPickerUtils';

const MultiUserPicker = props => {
    const {t} = useTranslation('tasks');

    const menuPlacement = props.menuPlacement || 'top';
    const {searchable} = props;
    const placeholder =
        props.placeholder || t('multiUserPicker.searchForUsers');
    const label = props.label || t('multiUserPicker.addReceivers');

    translateAllUsersOption(t);
    const selectedUsers = props.selectedUsers.map(mapUserToPicker);

    if (
        areAllUsersSelected(props.users, props.selectedUsers) &&
        selectedUsers.length
    ) {
        selectedUsers.push(mapUserToPicker(allUsersOption));
    }

    const handleChange = selectedUsers => {
        const {selectedUsers: previousSelectedUsers, users: usersFromProps} =
            props;

        if (!selectedUsers) {
            selectedUsers = [];
        }
        if (areAllUsersSelected(usersFromProps, selectedUsers)) {
            if (
                !isAllUsersOptionPresent(selectedUsers) &&
                areAllUsersSelected(usersFromProps, previousSelectedUsers)
            ) {
                selectedUsers = [];
            } else {
                selectedUsers = selectedUsers.filter(
                    user => user.value !== allUsersOption.uid,
                );
            }
        }

        if (isAllUsersOptionPresent(selectedUsers)) {
            selectedUsers = usersFromProps.map(mapUserToPicker);
        }

        props.setSelectedUsers(
            selectedUsers.map(selectedUser =>
                findSelectedUserInUsers(usersFromProps, selectedUser),
            ),
        );
    };

    return (
        <Picker
            componentsForPicker={{Option, MultiValue, ValueContainer}}
            label={label}
            selectedValue={selectedUsers}
            options={generateOptions(props.users)}
            name="Użytkownicy"
            handleChange={handleChange}
            placeholder={placeholder}
            clearable={false}
            closeMenuOnSelect={false}
            searchable={searchable}
            menuPlacement={menuPlacement}
            maxMenuHeight={props.maxMenuHeight}
            multi
            menuPortalTarget={props.menuPortalTarget}
        />
    );
};

MultiUserPicker.defaultProps = {
    bottomLabelActive: true,
};

export default MultiUserPicker;
