import React, {MouseEventHandler} from 'react';
import classnames from 'classnames';

import CloseIcon from '@mui/icons-material/Close';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import styles from './Buttons.module.css';
import {Button} from '@mui/material';

type CustomButtonProps = {
    contained?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    className?: string;
    onClick?: (e: any) => void;
    text: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    color?: 'primary' | 'secondary' | 'default' | undefined;
    startIcon?: React.ReactNode;
    marginTop?: number;
    marginBottom?: number;
    variant?: 'text' | 'outlined' | 'contained' | undefined;
};

export const CustomButton = (props: CustomButtonProps) => {
    const buttonVariant = props.contained
        ? 'contained'
        : props.variant || 'contained';

    return (
        <Button
            variant={buttonVariant}
            color={props?.color || props.contained ? 'primary' : 'secondary'}
            disabled={props.disabled}
            className={classnames(styles.customButton, props.className)}
            classes={{
                containedPrimary: styles.primary,
                textPrimary: styles.textPrimary,
            }}
            style={{
                marginTop: props.marginTop,
                marginBottom: props.marginBottom,
            }}
            fullWidth={props.fullWidth}
            onClick={props.onClick}
            type={props.type}
            startIcon={props.startIcon}
        >
            {props.text}
        </Button>
    );
};
CustomButton.defaultProps = {
    contained: true,
};

type CloseButtonProps = {
    onClick: () => void;
    className?: string;
};
export const closeButton = ({onClick, className}: CloseButtonProps) => (
    <div className={styles.closeButton} onClick={onClick}>
        <CloseIcon
            className={classnames(styles.icon, styles.nomobile, className)}
        />
        <ArrowBack
            className={classnames(styles.icon, styles.mobile, className)}
        />
    </div>
);

type CancelButtonProps = {
    text: string;
    onClick: () => void;
};
export const cancelButton = ({text, onClick}: CancelButtonProps) => (
    <CustomButton
        className={styles.cancelButton}
        text={text}
        contained={false}
        onClick={onClick}
    />
);

type AddButtonProps = Pick<
    CustomButtonProps,
    'disabled' | 'text' | 'onClick' | 'className' | 'type'
>;
export const addButton = ({
    disabled,
    text,
    onClick,
    type,
    className,
}: AddButtonProps) => (
    <CustomButton
        className={classnames(styles.addButton, className)}
        disabled={disabled}
        text={text}
        onClick={onClick}
        type={type}
    />
);
