import {ArchiveColumn} from '../components/Issues/ArchivedIssueList/ArchiveIssueList.types';
import {
    IssueColumn,
    IssuesFilters,
} from '../components/Issues/IssuesList/IssueList.types';
import {TaskColumn} from '../components/Tasks/TasksList/TaskList.types';
import {Branch, UserDetails} from '../models';

export enum StorageItem {
    columnsFilterIssues = 'columnsFilterIssues',
    columnsFilterArchive = 'columnsFilterArchive',
    columnsFilterTasks = 'columnsFilterTasks',
    issuesListFilters = 'issuesListFilters',
    issuesMapVisible = 'issuesMapVisible',
    userData = 'userData',
    selectedBranches = 'selectedBranches',
    tableColumnsSort = 'tableColumnsSort-$variable',
}

type StorageItemPayload = {
    [StorageItem.columnsFilterIssues]: IssueColumn[];
    [StorageItem.columnsFilterArchive]: ArchiveColumn[];
    [StorageItem.columnsFilterTasks]: TaskColumn[];
    [StorageItem.issuesListFilters]: IssuesFilters;
    [StorageItem.issuesMapVisible]: boolean;
    [StorageItem.userData]: UserDetails;
    [StorageItem.selectedBranches]: Branch[];
    [StorageItem.tableColumnsSort]: {sortDirection: string; orderBy: string};
};

export class AppStorage {
    static getDynamicKey = <T extends StorageItem>(
        storageItem: T,
        variable: string,
    ) => storageItem.replace('$variable', variable) as T;

    static get = <T extends StorageItem>(key: T) => {
        try {
            const payload = localStorage.getItem(key);

            return payload
                ? (JSON.parse(payload) as StorageItemPayload[T])
                : null;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    static set = <T extends StorageItem>(
        key: T,
        value: StorageItemPayload[T],
    ) => localStorage.setItem(key, JSON.stringify(value));

    static remove = (key: StorageItem) => localStorage.removeItem(key);

    static clear = () => localStorage.clear();
}
