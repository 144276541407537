import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {CustomTextField, Checkbox} from '../../../common/components/index';
import Dialog from '../../../common/components/Dialog/Dialog';
import FileDropzone from '../../../common/components/FileDropzone/filesDropzone';
import {MAX_XLSX_FILES} from '../../../constants/files';
import {TYPES} from '../../../constants/error';
import {NotificationContext} from '../../../context/notifications';
import {
    REPORTER_CAN_SEE_ALL_ISSUES,
    SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE,
    SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE,
    SEND_EMAIL_WITH_NOTIFICATION_TO_USER_AFTER_ASSIGN_TO_ISSUE,
    SHOW_RECENTLY_ARCHIVED_ISSUES_ON_ISSUES_LIST,
    IS_ISSUES_CATEGORIES_FEATURE_ENABLED,
    MAINTAINER_HAS_NO_PERMISSION_TO_ASSIGN_USERS_TO_ISSUE,
    SUPERVISOR_HAS_ACCESS_TO_TASKS,
    FAIR_ENVIRONMENT,
    ISSUE_EXPIRATION,
    REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION,
    MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION,
    MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE,
    REPORTER_CAN_SEE_ISSUE_SOLUTION,
    MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED,
    SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL,
    REPORTER_GET_CHANGE_MAIL,
    SMS_NOTIFICATIONS,
    SMS_SENT_COUNT,
    SMS_LIMIT_COUNT,
    SMS_LAST_RESET_TIMESTAMP,
    ISSUES_ON_MAP,
} from '../../../constants/environment';
import CustomCircularProgress from '../../../common/components/CustomCircularProgress/customCircularProgress';

class AddEnvironment extends Component {
    state = {
        newEnvironmentName: '',
        environmentFile: null,
        reporterCanSeeAllIssues: false,
        sendEmailWithNotificationToUserAfterAssignToIssue: false,
        showRecentlyArchivedIssuesOnIssuesList: false,
        [SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE]: false,
        [SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE]: false,
        isIssuesCategoriesFeatureEnabled: false,
        maintainerHasNoPermissionToAssignUsersToIssue: false,
        supervisorHasAccessToTasks: false,
        eventBased: false,
        issueExpiration: false,
        reporterHasNoPermissionToEditIssueDescription: false,
        maintainerHasNoPermissionToEditIssueDescription: false,
        maintainerHasNoPermissionToAddIssue: false,
        reporterCanSeeIssueSolution: false,
        maintainerGetPushOnAllIssuesCreated: false,
        supervisorAndMaintainerGetChangeMail: false,
        reporterGetChangeMail: false,
        [SMS_NOTIFICATIONS]: false,
        [SMS_SENT_COUNT]: 0,
        [SMS_LIMIT_COUNT]: 0,
        [SMS_LAST_RESET_TIMESTAMP]: 0,
        [ISSUES_ON_MAP]: false,
    };

    static contextType = NotificationContext;

    notificationSystem = this.context;

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                message,
                type,
            });
        }
    };

    onEnvironmentNameChange = event => {
        this.setState({newEnvironmentName: event.target.value});
    };

    onRemoveFile = () => this.setState({environmentFile: null});

    onFileDrop = files => {
        if (files.length > MAX_XLSX_FILES) {
            this.showNotification(
                this.t('notifications.tooMuchFilesError'),
                TYPES.error,
            );
        } else {
            this.setState({environmentFile: files});
        }
    };

    onSubmitNewEnvironmentHandler = () => {
        this.props.onSubmitHandler({
            ...this.state,
            environmentName: this.state.newEnvironmentName.trim(),
            environmentFile: this.state.environmentFile[0],
        });
    };

    toggleCheckboxValue = event => {
        this.setState({[event.target.id]: !this.state[event.target.id]});
    };

    render() {
        const {dialogTitle, handleClose, loading} = this.props;
        const {newEnvironmentName, environmentFile} = this.state;
        const submitButtonDisabled =
            !newEnvironmentName.trim() || !environmentFile || loading;
        this.t = this.props.t;

        return (
            <Dialog
                loading={loading}
                handleClose={handleClose}
                dialogTitle={dialogTitle}
                submitButtonDisabled={submitButtonDisabled}
                onSubmitHandler={this.onSubmitNewEnvironmentHandler}
                submitButtonText={dialogTitle}
            >
                {loading && <CustomCircularProgress />}
                <CustomTextField
                    label={this.t('dialog.name')}
                    onChange={this.onEnvironmentNameChange}
                    fullWidth
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.reporterCanSeeAllIssues')}
                    id={REPORTER_CAN_SEE_ALL_ISSUES}
                    checked={this.state[REPORTER_CAN_SEE_ALL_ISSUES]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.sendEmailWithNotificationToUserAfterAssignToIssue',
                    )}
                    id={
                        SEND_EMAIL_WITH_NOTIFICATION_TO_USER_AFTER_ASSIGN_TO_ISSUE
                    }
                    checked={
                        this.state[
                            SEND_EMAIL_WITH_NOTIFICATION_TO_USER_AFTER_ASSIGN_TO_ISSUE
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.showRecentlyArchivedIssuesOnIssueList',
                    )}
                    id={SHOW_RECENTLY_ARCHIVED_ISSUES_ON_ISSUES_LIST}
                    checked={
                        this.state[SHOW_RECENTLY_ARCHIVED_ISSUES_ON_ISSUES_LIST]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.sendEmailWithNotificationToAdminsOnIssueCreate',
                    )}
                    id={SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE}
                    checked={
                        this.state[
                            SEND_EMAIL_WITH_NOTIFICATION_TO_ADMINS_ON_ISSUE_CREATE
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.sendEmailWithNotificationToSupervisorsOnIssueCreate',
                    )}
                    id={
                        SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE
                    }
                    checked={
                        this.state[
                            SEND_EMAIL_WITH_NOTIFICATION_TO_SUPERVISORS_ON_ISSUE_CREATE
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.isIssuesCategoriesFeatureEnabled',
                    )}
                    id={IS_ISSUES_CATEGORIES_FEATURE_ENABLED}
                    checked={this.state[IS_ISSUES_CATEGORIES_FEATURE_ENABLED]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.maintainerHasNoPermissionToAssignUsersToIssue',
                    )}
                    id={MAINTAINER_HAS_NO_PERMISSION_TO_ASSIGN_USERS_TO_ISSUE}
                    checked={
                        this.state[
                            MAINTAINER_HAS_NO_PERMISSION_TO_ASSIGN_USERS_TO_ISSUE
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.supervisorHasAccessToTasks',
                    )}
                    id={SUPERVISOR_HAS_ACCESS_TO_TASKS}
                    checked={this.state[SUPERVISOR_HAS_ACCESS_TO_TASKS]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.expoEnvironment')}
                    id={FAIR_ENVIRONMENT}
                    checked={this.state[FAIR_ENVIRONMENT]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.issueExpiration')}
                    id={ISSUE_EXPIRATION}
                    checked={this.state[ISSUE_EXPIRATION]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.reporterHasNoPermissionToEditIssueDescription',
                    )}
                    id={REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION}
                    checked={
                        this.state[
                            REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION
                        ]
                    }
                    value={
                        this.state[
                            REPORTER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.maintainerHasNoPermissionToEditIssueDescription',
                    )}
                    id={MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION}
                    checked={
                        this.state[
                            MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION
                        ]
                    }
                    value={
                        this.state[
                            MAINTAINER_HAS_NO_PERMISSION_TO_EDIT_ISSUE_DESCRIPTION
                        ]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.maintainerHasNoPermissionToAddIssue',
                    )}
                    id={MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE}
                    checked={
                        this.state[MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE]
                    }
                    value={
                        this.state[MAINTAINER_HAS_NO_PERMISSION_TO_ADD_ISSUE]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.reporterCanSeeIssueSolution',
                    )}
                    id={REPORTER_CAN_SEE_ISSUE_SOLUTION}
                    checked={this.state[REPORTER_CAN_SEE_ISSUE_SOLUTION]}
                    value={this.state[REPORTER_CAN_SEE_ISSUE_SOLUTION]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.maintainerGetPushOnAllIssuesCreated',
                    )}
                    id={MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED}
                    checked={
                        this.state[MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED]
                    }
                    value={
                        this.state[MAINTAINER_GET_PUSH_ON_ALL_ISSUES_CREATED]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t(
                        'dialog.checkboxes.supervisorAndMaintainerGetChangeMail',
                    )}
                    id={SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL}
                    checked={
                        this.state[SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL]
                    }
                    value={
                        this.state[SUPERVISOR_AND_MAINTAINER_GET_CHANGE_MAIL]
                    }
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.reporterGetChangeMail')}
                    id={REPORTER_GET_CHANGE_MAIL}
                    checked={this.state[REPORTER_GET_CHANGE_MAIL]}
                    value={this.state[REPORTER_GET_CHANGE_MAIL]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.issuesOnMap')}
                    id={ISSUES_ON_MAP}
                    checked={this.state[ISSUES_ON_MAP]}
                    value={this.state[ISSUES_ON_MAP]}
                    onChange={this.toggleCheckboxValue}
                />
                <Checkbox
                    label={this.t('dialog.checkboxes.smsNotifications')}
                    id={SMS_NOTIFICATIONS}
                    checked={this.state[SMS_NOTIFICATIONS]}
                    value={this.state[SMS_NOTIFICATIONS]}
                    onChange={this.toggleCheckboxValue}
                />
                <CustomTextField
                    label={this.t('dialog.label.smsLimit', {
                        count: this.state[SMS_SENT_COUNT],
                    })}
                    type="number"
                    onChange={this.onSmsLimitChange}
                    fullWidth
                    value={this.state[SMS_LIMIT_COUNT]}
                    disabled={!this.state[SMS_NOTIFICATIONS]}
                />

                <FileDropzone
                    label={this.t('dialog.filedrop')}
                    onDrop={this.onFileDrop}
                    onRemoveFile={this.onRemoveFile}
                    filesSrc={this.state.environmentFile}
                    onlyXLSX
                />
            </Dialog>
        );
    }
}

export default withTranslation('environments')(AddEnvironment);
