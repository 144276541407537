import {SET_USERS} from './actionTypes';
import {
    DELETE_USER,
    CREATE_NEW_USER,
    UPDATE_USER_PASSWORD,
    UPDATE_USER,
} from '../../constants/CloudFunctions';
import axios from '../../config/axios/axiosConfig';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';
import {UserDetails} from '../../models';

export const setUsers = (users: UserDetails[]) => ({
    type: SET_USERS,
    users,
});

export const updateUser = async (
    documentId: string,
    updatedUser: UserDetails,
    onSuccess: () => void,
    onError: () => void,
) => {
    try {
        await axios.post(UPDATE_USER, {documentId, updatedUser});
        onSuccess();
    } catch (e) {
        errorHandler(e);
        onError();
    }
};

export const updateUserPassword = async (
    userUid: string,
    newPassword: string,
    onSuccess: () => void,
    onError: (translationKey: string) => void,
) => {
    try {
        const config = {
            data: {
                uid: userUid,
                newPassword,
            },
        };
        await axios.post(UPDATE_USER_PASSWORD, config);
        onSuccess();
    } catch (error) {
        const passwordUpdateResponse = error.response.data;
        onError('passwordManagerModal.notifications.passwordUpdateError');
        passwordChangeErrorHandler(passwordUpdateResponse?.code, onError);
    }
};

const passwordChangeErrorHandler = (
    code: string | undefined,
    onError: (translationKey: string) => void,
) => {
    switch (code) {
        case 'auth/invalid-password':
            onError('passwordManagerModal.notifications.invalidPasswordError');
            break;
        default:
            onError('passwordManagerModal.notifications.passwordUpdateError');
            break;
    }
};

export const deleteUser = async (
    userUid: string,
    onSuccess: () => void,
    onError: (translationKey: string) => void,
) => {
    try {
        const config = {
            data: {
                uid: userUid,
            },
        };
        await axios.delete(DELETE_USER, config);
        onSuccess();
    } catch (error) {
        if (error.response) {
            const deleteUserResponse = error.response.data;
            deleteUserErrorHandler(deleteUserResponse.code, onError);
        } else {
            onError('notifications.details.userDeleteError');
        }
    }
};

const deleteUserErrorHandler = (
    code: string | undefined,
    onError: (translationKey: string) => void,
) => {
    switch (code) {
        case 'auth/user-not-found':
            onError('notifications.details.userNotFoundError');
            break;
        default:
            onError('notifications.details.userDeleteError');
            break;
    }
};

export const onUserAdded = async (
    user: UserDetails,
    onSuccess: () => void,
    onError: (translationKey: string) => void,
) => {
    try {
        await axios.post(CREATE_NEW_USER, user);
        onSuccess();
    } catch (err) {
        const createNewUserResponse = err.response.data;
        addUserErrorHandler(createNewUserResponse.code, onError);
    }
};

const addUserErrorHandler = (
    code: string | undefined,
    onError: (translationKey: string) => void,
) => {
    switch (code) {
        case 'auth/email-already-exists':
            onError('notifications.dialog.emailTakenError');
            break;
        case 'auth/invalid-email':
            onError('notifications.dialog.emailInvalidError');
            break;
        case 'auth/invalid-password':
            onError('notifications.dialog.passwordInvalidError');
            break;
        default:
            onError('notifications.dialog.defaultError');
    }
};
