import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './IssuesCount.module.css';

type IssuesCountProps = {
    open?: number;
    inProgress?: number;
    inReview?: number;
};

export const IssuesCount = ({
    open = 0,
    inProgress = 0,
    inReview = 0,
}: IssuesCountProps) => {
    const {t: tIssues} = useTranslation('issues');

    return (
        <div className={styles.container}>
            <span className={styles.text}>{`${tIssues('statusLabels.open')}: ${open}`}</span>
            <span className={styles.text}>{`${tIssues('statusLabels.inProgress')}: ${inProgress}`}</span>
            <span className={styles.text}>{`${tIssues('statusLabels.inReview')}: ${inReview}`}</span>
        </div>
    );
};
