import {UserDetails} from '../../models';
import {SET_USERS} from '../action/actionTypes';

type State = {
    users: UserDetails[];
};

type Action = {
    type: typeof SET_USERS;
    users: State['users'];
};

const initialState: State = {
    users: [],
};

const reducer = (state = initialState, action: Action): State => {
    switch (action.type) {
        case SET_USERS:
            return {
                ...state,
                users: action.users,
            };
        default:
            return state;
    }
};

export default reducer;
