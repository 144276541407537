import classnames from 'classnames';

import './Box.css';
import {Paper} from '@mui/material';

const Box = props => (
    <Paper
        classes={{root: classnames('box-container', props.className)}}
        elevation={0}
    >
        {props.children}
    </Paper>
);

export default Box;
