import React, {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/compat/app';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {
    fetchElements,
    fetchLocations,
    getElementsByLocationId,
} from '../../store/action';
import {withUserRole} from '../../hoc/User/WithUserRole';
import {NotificationContext} from '../../context/notifications';
import TaskDetailsLayout from './TaskDetailsLayout';
import {mappings} from './TaskDetailsRoleMapper';
import {categories, elements, locations, task} from '../../constants/endpoints';
import {
    fetchDocumentById,
    getItemFromDocumentAndPassToCallback,
} from '../../utils/firestoreDocumentUtils/firestoreDocumentUtils';
import {taskConverter} from '../../utils/converter/taskConverter';
import {deleteTask, updateTask} from '../../utils/task/task';
import {TASKS} from '../../constants/routes';
import {CustomCircularProgress} from '../../common/components';
import {elementConverter} from '../../utils/converter/elementConverter';
import {TYPES} from '../../constants/error';
import {fetchCategories} from '../../store/action/category';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';

class TaskDetails extends Component {
    static contextType = NotificationContext;

    state = {
        originalTask: null,
        elements: [],
        locations: [],
        saveButtonDisabled: true,
        loading: true,
    };

    editedTask = null;

    notificationSystem = null;

    elementsCollection = firebase.firestore().collection(elements());

    locationCollection = firebase.firestore().collection(locations());

    componentDidMount() {
        this.notificationSystem = this.context;
        this.subscribeOnElements();
        if (this.props.organizationData?.isIssuesCategoriesFeatureEnabled) {
            this.subscribeOnCategories();
        }
        this.fetchTask();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.locations !== this.props.locations) {
            const {selectedLocations} = this.state;
            this.setElementsForLocations(selectedLocations);
        }
        if (
            this.state.originalTask &&
            prevProps.selectedBranches !== this.props.selectedBranches &&
            !this.props.selectedBranches.some(
                branch => branch.id === this.state.originalTask.branch.id,
            )
        ) {
            this.props.history.push(TASKS);
        }
    }

    componentWillUnmount() {
        this.unsubscribeLocations();
        this.unsubscribeElements();
        if (this.unsubscribeCategories) this.unsubscribeCategories();
    }

    fetchTask() {
        const taskId = this.props.match.params.id;
        const taskDocument = fetchDocumentById(task(), taskId, taskConverter);
        getItemFromDocumentAndPassToCallback(taskDocument, this.taskFetched);
    }

    taskFetched = task => {
        this.setState({
            originalTask: task,
            loading: false,
        });
        this.unsubscribeLocations = this.locationCollection
            .where('branch.id', '==', task.branch.id)
            .onSnapshot(this.fetchLocations);
    };

    subscribeOnElements() {
        this.unsubscribeElements = this.elementsCollection
            .withConverter(elementConverter)
            .onSnapshot(this.fetchElements);
    }

    setSaveButtonDisabled = value => {
        this.setState({saveButtonDisabled: value});
    };

    setFormState = formState => {
        this.editedTask = formState;
    };

    fetchLocations = ({docs}) => {
        const locations = docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
            key: doc.id,
        }));
        this.setState({locations});
    };

    fetchElements = ({docs}) => {
        const elements = docs.map(doc => ({
            ...doc.data(),
            key: doc.id,
            id: doc.id,
        }));
        this.setState({elements});
    };

    subscribeOnCategories() {
        this.unsubscribeCategories = firebase
            .firestore()
            .collection(categories())
            .onSnapshot(this.props.fetchCategories, errorHandler);
    }

    showNotification = (message, type) => {
        if (this.notificationSystem) {
            this.notificationSystem.addNotification({
                type,
                message,
            });
        }
    };

    onTaskUpdated = () => {
        this.setState({loading: true});
        const {userData} = this.props;
        const {
            active,
            category,
            assignedTo,
            repeat,
            selectedElements,
            startDate,
            name,
            notifications,
            priority,
            addToIssueList,
            addToIssueListTimePeriod,
            finishRepeatDate,
        } = this.editedTask;

        const updatedTask = {
            active,
            repeat,
            category,
            assignedTo: assignedTo && {
                name: assignedTo.name,
                surname: assignedTo.surname,
                uid: assignedTo.uid,
            },
            reporter: {
                name: userData.name,
                surname: userData.surname,
                uid: userData.uid,
            },
            elements: selectedElements.filter(Boolean),
            startDate,
            name,
            nextOccurrenceDate: startDate,
            notifications,
            priority,
            addToIssueList,
            addToIssueListTimePeriod,
            finishRepeatDate,
        };

        updateTask(this.state.originalTask.key, updatedTask)
            .then(() => {
                this.showNotification(
                    this.t('notifications.elementEditSuccess'),
                    'success',
                );
                this.props.history.push(TASKS);
            })
            .catch(error => {
                errorHandler(error);
                this.setState({loading: false});
                this.showNotification(
                    this.t('notifications.elementEditError'),
                    TYPES.error,
                );
            });
    };

    onTaskDeleted = () => {
        this.setState({loading: true});
        deleteTask(this.state.originalTask.key)
            .then(() => {
                this.setState({loading: false});
                this.showNotification(
                    this.t('notifications.elementDeleteSuccess'),
                    'success',
                );
                this.props.history.push(TASKS);
            })
            .catch(error => {
                errorHandler(error);
                this.setState({loading: false});
                this.showNotification(
                    this.t('notifications.elementDeleteError'),
                    TYPES.error,
                );
            });
    };

    render() {
        const {elements, name, locations, loading, originalTask} = this.state;
        const {users, categories} = this.props;
        this.t = this.props.t;

        return originalTask ? (
            withUserRole(TaskDetailsLayout, mappings, {
                name,
                loading,
                originalTask,
                elements,
                categories,
                isIssuesCategoriesFeatureEnabled:
                    this.props.organizationData
                        .isIssuesCategoriesFeatureEnabled,
                locations,
                users,
                header: originalTask.name,
                saveButtonDisabled: this.state.saveButtonDisabled,
                setSaveButtonDisabled: this.setSaveButtonDisabled,
                setFormState: this.setFormState,
                onTaskUpdated: this.onTaskUpdated,
                onTaskDeleted: this.onTaskDeleted,
            })
        ) : (
            <CustomCircularProgress />
        );
    }
}

const mapStateToProps = state => ({
    users: state.user.users,
    userData: state.auth.userData,
    organizationData: state.auth.organizationData,
    elements: state.element.elements,
    categories: state.category.categories,
    selectedBranches: state.branch.selectedBranches,
});

const mapDispatchToProps = dispatch => ({
    fetchLocations: dispatch(fetchLocations),
    fetchCategories: dispatch(fetchCategories),
    fetchElements: dispatch(fetchElements),
    getElementsByLocationId: dispatch(getElementsByLocationId),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withTranslation('tasks')(TaskDetails)),
);
