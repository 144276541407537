import {useTranslation} from 'react-i18next';
import Picker from '../picker/Picker';
import {useAppSelector} from '../../../store/hooks';
import {Branch} from '../../../models';
import {MenuPlacement} from 'react-select';

export type PickerItem = {
    value: string;
    label: string;
};

type Props = {
    branches: Branch[];
    selectedBranch?: Branch | null;
    selectedBranches?: Branch[];
    first?: boolean;
    disabled?: boolean;
    multi?: boolean;
    handleChange: (payload: PickerItem | PickerItem[]) => void;
    className?: string;
    menuPlacement?: MenuPlacement;
    menuPortalTarget?: HTMLElement | null;
    maxMenuHeight?: number;
};

const BranchPicker = ({
    first,
    branches,
    selectedBranch,
    selectedBranches,
    disabled,
    multi,
    handleChange,
    className,
    menuPlacement,
    menuPortalTarget,
}: Props) => {
    const eventBased = useAppSelector(
        state => state.auth.organizationData?.eventBased,
    );

    const {t} = useTranslation('common');

    const mapBranchToPicker = (branch: Branch) => ({
        value: branch.id,
        label: branch.name,
    });

    const items = branches?.map(mapBranchToPicker);

    const selectedValue = selectedBranch
        ? mapBranchToPicker(selectedBranch)
        : selectedBranches?.map(mapBranchToPicker);

    return (
        items.length > 1 && (
            <Picker
                label={
                    eventBased
                        ? t('branchPicker.eventBased.label')
                        : t('branchPicker.default.label')
                }
                selectedValue={selectedValue}
                options={items}
                name={
                    eventBased
                        ? t('branchPicker.eventBased.name')
                        : t('branchPicker.default.name')
                }
                multi={multi}
                first={first}
                placeholder={
                    eventBased
                        ? t('branchPicker.eventBased.searchPlaceholder')
                        : t('branchPicker.default.searchPlaceholder')
                }
                handleChange={handleChange}
                className={className}
                disabled={disabled}
                clearable={false}
                menuPlacement={menuPlacement}
                menuPortalTarget={menuPortalTarget}
            />
        )
    );
};

export default BranchPicker;
