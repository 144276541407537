import {QRCodeSVG} from 'qrcode.react';
import {useTranslation} from 'react-i18next';
import styles from './qrCodeWithText.module.css';

export const QrCodeWithText = ({content, locationName, elementName}) => {
    const {t} = useTranslation('elements');

    return (
        <div className={styles.container}>
            <QRCodeSVG value={content} size={256} />
            <div className={styles.text}>
                {t('details.default.location')}: {locationName}
            </div>
            <div className={styles.text}>
                {t('details.default.name')}: {elementName}
            </div>
        </div>
    );
};
