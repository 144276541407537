import {useTranslation} from 'react-i18next';
import {MainTheme} from '../../utils/Themes/Themes';
import {CustomTextField, AddButton} from '../../common/components/index';
import styles from './LoginForm.module.css';
import logoSmall from '../../static/images/logo/logo_small2x.png';
import {ThemeProvider as MuiThemeProvider} from '@mui/material';
import {ReactNode} from 'react';

type Props = {
    disabled: boolean;
    onLoginTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onPasswordTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onLoginButtonClicked: () => void;
    onChangePasswordClick: () => void;
    alert: ReactNode;
};

const LoginForm = ({
    alert,
    disabled,
    onChangePasswordClick,
    onLoginButtonClicked,
    onLoginTextChange,
    onPasswordTextChange,
}: Props) => {
    const {t} = useTranslation('auth');

    return (
        <form className={styles.container}>
            <div className={styles.logoContainer}>
                <img className={styles.logo} src={logoSmall} alt="" />
            </div>
            <div className={styles.title}>{t('login.title')}</div>
            <MuiThemeProvider theme={MainTheme}>
                <CustomTextField
                    type="email"
                    placeholder={t('login.emailPlaceholder')}
                    fullWidth
                    onChange={onLoginTextChange}
                    label={t('login.email')}
                    autoFocus
                />

                <div className={styles.textInput}>
                    <div className={styles.passwordLabel}>
                        {t('login.password')}
                        <div
                            onClick={onChangePasswordClick}
                            className={styles.resetPasswordButton}
                        >
                            {t('login.forgotPassword')}
                        </div>
                    </div>
                    <CustomTextField
                        type="password"
                        placeholder={t('login.passwordPlaceholder')}
                        fullWidth
                        onChange={onPasswordTextChange}
                    />
                </div>
            </MuiThemeProvider>
            <MuiThemeProvider theme={MainTheme}>
                <div className={styles.marginForButton}>
                    {alert}
                    <AddButton
                        className={styles.loginButton}
                        disabled={disabled}
                        onClick={event => {
                            event.preventDefault();
                            onLoginButtonClicked();
                        }}
                        type="submit"
                        text={t('login.actionBtn')}
                    />
                </div>
            </MuiThemeProvider>
        </form>
    );
};

export default LoginForm;
