//@ts-nocheck

import getArchivedIssuesQuery from '../utils/queryBuilder/ArchivedIssueQueryBuilder';
import getIssuesQuery from '../utils/queryBuilder/IssueQueryBuilder';
import errorHandler from '../common/components/ExceptionReporting/ErrorReporting';
import {uniqBy, without} from 'lodash';
import {getUserRole} from '../store/action/authHelpers';
import {Role} from '../constants/roles';
import {Category, Issue, Organization, UserDetails} from '../models';

export const nullCategory: Category = {
    id: 'null',
    name: 'null',
};

//  Related to ticket: https://easysolve.atlassian.net/browse/US-218
// Issue visibility logic:
// - Superadmin/Admin can see all issues, regardless of category.
// - Supervisor/Maintainer can see all issues if categories are disabled.
//   If categories are enabled, they can only see issues in their category + uncategorized issues.
// - Reporter can only see their own issues unless `reporterCanSeeAllIssues = true`,
//   in which case they can see everything like a Superadmin/Admin.

class IssuesService {
    private issues: Issue[] = [];

    private archivedIssues: Issue[] = [];

    private unsubscribeArchivedIssues = () => {};
    private unsubscribeIssues = () => {};

    private mergeIssuesWithRecentlyArchivedIssues = (
        issues,
        archivedIssues,
    ) => {
        if (archivedIssues.length) {
            return [...issues, ...archivedIssues];
        }
        return issues;
    };

    public subscribeOnIssues = async (
        userData: UserDetails | null,
        organizationData: Organization,
        branchIds: string[],
        setActivityIndicator,
        fetchIssues,
    ) => {
        const userCategoriesIds = [
            ...(userData?.categories || []),
            nullCategory,
        ].map(category => category.id);

        const isCategoryFeatureEnabled =
            organizationData.isIssuesCategoriesFeatureEnabled;
        const showIssuesWithCategoryRestriction = [
            Role.SUPERVISOR,
            Role.MAINTAINER,
        ].includes(getUserRole());
        const hasUserCategories = userData?.categories?.length;

        const withCategoryFilter = (issue: Issue) => {
            if (
                !isCategoryFeatureEnabled ||
                !showIssuesWithCategoryRestriction
            ) {
                return true;
            }

            if (!issue?.category?.id) {
                return true;
            }

            return userCategoriesIds.includes(issue.category.id);
        };

        const subscribeToArchivedIssues = () => {
            this.unsubscribeArchivedIssues = getArchivedIssuesQuery(branchIds)
                .withClosedDate(
                    new Date(new Date().setDate(new Date().getDate() - 7)),
                )
                .onSnapshot((snapshotArchivedIssues: Issue[]) => {
                    this.archivedIssues =
                        snapshotArchivedIssues.filter(withCategoryFilter);
                    fetchIssues(
                        this.mergeIssuesWithRecentlyArchivedIssues(
                            this.issues,
                            this.archivedIssues,
                        ),
                        setActivityIndicator,
                    );
                }, errorHandler);
        };

        const subscribeToIssues = () => {
            this.unsubscribeIssues = getIssuesQuery(
                branchIds,
                organizationData,
                userData,
            ).onSnapshot((snapshotIssues: Issue[]) => {
                this.issues = snapshotIssues.filter(withCategoryFilter);
                fetchIssues(
                    this.mergeIssuesWithRecentlyArchivedIssues(
                        this.issues,
                        this.archivedIssues,
                    ),
                    setActivityIndicator,
                );
            }, errorHandler);
        };

        subscribeToIssues();
        if (organizationData.showRecentlyArchivedIssuesOnIssuesList) {
            subscribeToArchivedIssues();
        }

        return () => {
            this.unsubscribeArchivedIssues();
            this.unsubscribeIssues();
        };
    };
}

export default new IssuesService();
