import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Alert from '../../components/Alert/Alert';
import LoginForm from '../../components/LoginForm/LoginForm';
import {tryAuth, sendResetPasswordEmail} from '../../store/action';
import {ISSUES} from '../../constants/routes';
import styles from './LoginPage.module.css';
import ChangePasswordDialog from '../../components/ChangePasswordDialog/ChangePasswordDialog';
import CustomCircularProgress from '../../common/components/CustomCircularProgress/customCircularProgress';
import {Logo} from '../../common/components';
import {If} from '../../common/components/If';

const LoginPage = () => {
    const {t: tAuth} = useTranslation('auth');
    const dispatch = useDispatch();
    const history = useHistory();

    const [errorMessage, setErrorMessage] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [dialogInput, setDialogInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [openChangePasswordDialog, setOpenChangePasswordDialog] =
        useState(false);

    const onLoginHandler = () => {
        setLoading(true);
        const authData = {login, password};
        dispatch(tryAuth(authData, onLoginFailedHandler, onLoginSuccess));
    };

    const onChangePassword = () => {
        dispatch(sendResetPasswordEmail(dialogInput, onLoginFailedHandler));
        toggleDialogOpen();
    };

    const onLoginTextChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setLogin(e.target.value);
    };

    const onPasswordChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setPassword(e.target.value);
    };

    const onEmailInDialogChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDialogInput(e.target.value);
    };

    const onLoginFailedHandler = (error: string) => {
        setErrorMessage(error);
        setLoading(false);
    };

    const toggleDialogOpen = () => {
        setOpenChangePasswordDialog(prev => !prev);
    };

    const onLoginSuccess = () => {
        setLoading(false);
        history.push(ISSUES);
    };

    return (
        <>
            <If condition={loading}>
                <CustomCircularProgress />
            </If>
            <If condition={openChangePasswordDialog}>
                <ChangePasswordDialog
                    loading={loading}
                    onClose={toggleDialogOpen}
                    submitButtonDisabled={loading || !dialogInput}
                    onSubmit={onChangePassword}
                    onChange={onEmailInDialogChange}
                />
            </If>
            <div className={styles.loginForm}>
                <LoginForm
                    disabled={loading}
                    onLoginTextChange={onLoginTextChangeHandler}
                    onPasswordTextChange={onPasswordChangeHandler}
                    onLoginButtonClicked={onLoginHandler}
                    onChangePasswordClick={toggleDialogOpen}
                    alert={
                        errorMessage ? (
                            <Alert incorrectCredentials>
                                <p>{tAuth(errorMessage)}</p>
                            </Alert>
                        ) : (
                            <div />
                        )
                    }
                />
                <div className={styles.backgroundImage}>
                    <div className={styles.colorFilter}>
                        <Logo />
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginPage;
