import {QrCodeState} from '../reducer/qrCode';
import {CLEAR_SCANNED_ELEMENT, SET_SCANNED_ELEMENT} from './actionTypes';

export const setScannedElement = (qrCodeData: QrCodeState) => ({
    payload: qrCodeData,
    type: SET_SCANNED_ELEMENT,
});

export const clearScannedElement = () => ({
    type: CLEAR_SCANNED_ELEMENT,
});
