import {Role} from '../../constants/roles';
import AddUserDialog from '../../components/Users/AddUserDialog/AddUserDialog';

const mappings = {};

mappings[Role.MAINTAINER] = {
    innerComponents: [props => null],
    roleProps: {showAddButton: false, linkToDetails: false},
};

mappings[Role.SUPERVISOR] = {
    innerComponents: [props => <AddUserDialog {...props} />],
    roleProps: {showAddButton: false, linkToDetails: false},
};

mappings[Role.ADMIN] = {
    innerComponents: [props => <AddUserDialog {...props} />],
    roleProps: {showAddButton: true, linkToDetails: true},
};

mappings[Role.SYSTEMADMIN] = {
    innerComponents: [props => <AddUserDialog {...props} />],
    roleProps: {showAddButton: true, linkToDetails: true},
};

export {mappings};
