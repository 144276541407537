import React, {Component} from 'react';

import './ListHeaderFilterPanel.css';
import {Collapse} from '@mui/material';

class ListHeaderFilterPanel extends Component {
    wrapperRef = null;

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        return (
            <Collapse
                className="header-column-cell-filter"
                in={this.props.isOpen}
            >
                <div
                    ref={this.setWrapperRef}
                    className="header-column-cell-filter__content"
                >
                    {this.props.filterElementGetter
                        ? this.props.filterElementGetter(this.props.onClose)
                        : null}
                </div>
            </Collapse>
        );
    }

    handleClickOutside = event => {
        // @ts-ignore
        if (
            this.props.isOpen &&
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target)
        ) {
            this.props.onClose();
        }
    };

    setWrapperRef = node => {
        this.wrapperRef = node;
    };
}

export default ListHeaderFilterPanel;
