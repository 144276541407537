import {useCallback, useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {
    MapSearchParams,
    MapSearchParamsPayload,
    useMapStore,
} from '../../store/zustand/useMapStore';

export const useMapUrlState = (enabled: boolean) => {
    const {search} = useLocation();

    const {zoom, lat, lng, setLat, setLng, setZoom} = useMapStore();

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    const updateState = useCallback(
        <T extends MapSearchParams>(
            name: T,
            payload: MapSearchParamsPayload[T],
        ) => {
            if (!enabled) {
                return;
            }

            switch (name) {
                case MapSearchParams.LAT:
                    setLat(payload);
                    break;
                case MapSearchParams.LNG:
                    setLng(payload);
                    break;
                case MapSearchParams.ZOOM:
                    setZoom(payload);
                    break;
                default:
                    break;
            }
        },
        [enabled],
    );

    useEffect(() => {
        if (!enabled || !lng || !lat || !zoom) {
            window.history.pushState({}, '', window.location.pathname);
            return;
        }

        searchParams.set(MapSearchParams.ZOOM, String(zoom));
        searchParams.set(MapSearchParams.LAT, String(lat));
        searchParams.set(MapSearchParams.LNG, String(lng));

        window.history.pushState(
            {},
            '',
            `${window.location.pathname}?${searchParams}`,
        );
    }, [enabled, zoom, lat, lng]);

    return {updateState, zoom, lat, lng};
};
