import sha256 from 'crypto-js/sha256';
import {StorageFilePath} from '../../utils/storageUtils/storageUtils';
import firebase from 'firebase/compat/app';

export const hashFilename = file => {
    const [name, type] = file.name.split('.');

    return `${sha256(name + file.size + file.lastModified)}.${type}`;
};

export const mapPhotosToPhotosData = (photos: File[]) => {
    const photosData = photos.map(photo => ({
        fileName: hashFilename(photo),
        fileSize: photo.size,
        timestamp: new Date().getTime(),
    }));

    return photosData;
};

type UploadOptions = {
    customImageName?: string;
};

export const createPromisesToUploadPhotosAndGetPath = (
    photos: File[],
    storageRef: firebase.storage.Reference,
    options?: UploadOptions,
): Promise<StorageFilePath>[] =>
    photos.map(
        photo =>
            new Promise((resolve, reject) => {
                storageRef
                    .child(options?.customImageName || hashFilename(photo))
                    .put(photo)
                    .then(result => resolve(result.metadata.fullPath))
                    .catch(reject);
            }),
    );

export const removeNullFieldsFromObject = object => {
    Object.keys(object).forEach(key => {
        if (object[key] === undefined) {
            delete object[key];
        }
    });
};
