import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {ListActionPanel} from '../../../common/components/index';

type IssuesPageControlProps = {
    onSearchTextChange: (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => void;
    openFilter?: boolean;
    filterState?: Record<string, boolean>;
    onCheckboxClick?: (
        filed: string,
    ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFilterIconClickHandler?: () => void;
    onSettingsIconClick?: () => void;
    onMapIconClick?: () => void;
    searchText?: string;
    onToggleAddDialogHandler?: VoidFunction;
    showAddIssueButton?: boolean;
    additionalHeaderComponent?: ReactNode;
};

export const IssuesPageControl = ({
    onSearchTextChange,
    onToggleAddDialogHandler,
    onFilterIconClickHandler,
    openFilter,
    filterState,
    onCheckboxClick,
    onSettingsIconClick,
    onMapIconClick,
    showAddIssueButton,
    searchText,
    additionalHeaderComponent,
}: IssuesPageControlProps) => {
    const {t: tIssues} = useTranslation('issues');

    return (
        <ListActionPanel
            searchPlaceholder={tIssues('search.placeholder')}
            title={tIssues('title')}
            addButtonText={tIssues('actionBtn')}
            onSearchTextChange={onSearchTextChange}
            searchText={searchText}
            onToggleAddDialogHandler={onToggleAddDialogHandler}
            onFilterIconClickHandler={onFilterIconClickHandler}
            openFilter={openFilter}
            filterState={filterState}
            onCheckboxClick={onCheckboxClick}
            showAddButton={showAddIssueButton}
            withFilter
            additionalHeaderComponent={additionalHeaderComponent}
            onSettingsIconClick={onSettingsIconClick}
            onMapIconClick={onMapIconClick}
        />
    );
};
