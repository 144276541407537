import {ChangeEvent, ReactNode} from 'react';
import {default as CustomTextField} from '../textField/CustomTextField';
import {InputAdornment} from '@mui/material';

type Props = {
    value: string;
    label: string;
    placeholder: string;
    onChange: (value: string) => void;
    helperText?: ReactNode;
    onBlur?: () => void;
    error?: boolean;
};

export const PhoneInput = ({
    value,
    label,
    placeholder,
    onChange,
    helperText,
    onBlur,
    error,
}: Props) => {
    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value.replace(/\D/g, ''));
    };

    return (
        <CustomTextField
            label={label}
            placeholder={placeholder}
            value={value}
            onChange={_onChange}
            slotProps={{
                input: {
                    startAdornment: (
                        <InputAdornment position="start">+48</InputAdornment>
                    ),
                    inputMode: 'numeric',
                },
            }}
            maxLength={9}
            helperText={helperText}
            onBlur={onBlur}
            error={error}
        />
    );
};
