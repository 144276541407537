import {create} from 'zustand';
import {Coordinates} from '../../models';
import {AppStorage, StorageItem} from '../../storage/AppStorage';
import {Role} from '../../constants/roles';
import {getUserRole} from '../action/authHelpers';
import {parseMapParams} from '../../utils/map/map.utils';
import {MapMarker} from '../../components/Map/Map.types';

export const polandCenterMap = {
    lat: 52.2298,
    lng: 21.0118,
    zoom: 6,
    id: 999,
};

const searchParams = new URLSearchParams(window.location.search);

export const mapEnabledRoles = [
    Role.SYSTEMADMIN,
    Role.ADMIN,
    Role.SUPERVISOR,
    Role.MAINTAINER,
];

export enum MapSearchParams {
    LAT = 'lat',
    LNG = 'lng',
    ZOOM = 'zoom',
}

export type MapSearchParamsPayload = {
    [MapSearchParams.LAT]: number;
    [MapSearchParams.LNG]: number;
    [MapSearchParams.ZOOM]: number;
};

type Store = {
    zoom: number;
    lat: Coordinates['lat'];
    lng: Coordinates['lng'];
    visible: boolean;
    enabled: boolean;
    clickedMarkers: MapMarker[];
    setZoom: (value: number) => void;
    setLat: (value: Coordinates['lat']) => void;
    setLng: (value: Coordinates['lng']) => void;
    setVisible: (value: boolean) => void;
    setClickedMarkers: (value: MapMarker[]) => void;
};

export const useMapStore = create<Store>(set => {
    const mapInitialVisibleState = [
        parseMapParams(searchParams.get(MapSearchParams.ZOOM)),
        parseMapParams(searchParams.get(MapSearchParams.LAT)),
        parseMapParams(searchParams.get(MapSearchParams.LNG)),
        AppStorage.get(StorageItem.issuesMapVisible),
    ].some(Boolean);

    return {
        zoom:
            parseMapParams(searchParams.get(MapSearchParams.ZOOM)) ||
            polandCenterMap.zoom,
        lat:
            parseMapParams(searchParams.get(MapSearchParams.LAT)) ||
            polandCenterMap.lat,
        lng:
            parseMapParams(searchParams.get(MapSearchParams.LNG)) ||
            polandCenterMap.lng,
        visible: mapInitialVisibleState,
        enabled: mapEnabledRoles.includes(getUserRole()),
        clickedMarkers: [],
        setZoom: (zoom: number) => set(state => ({...state, zoom})),
        setLat: (lat?: Coordinates['lat']) => set(state => ({...state, lat})),
        setLng: (lng?: Coordinates['lat']) => set(state => ({...state, lng})),
        setVisible: (visible: boolean) => {
            AppStorage.set(StorageItem.issuesMapVisible, visible);
            if (!visible) {
                set(state => ({...state, clickedMarkers: []}));
            }
            set(state => ({...state, visible}));
        },
        setClickedMarkers: (clickedMarkers: MapMarker[]) =>
            set(state => ({...state, clickedMarkers})),
    };
});
