import { withRouter } from "react-router-dom";
import { useLocation} from "react-router-dom";



export const withLocationRefresh = (Component) => {
    const WithLocationRefresh = props => {
        const location = useLocation();
        
        return (
            <Component key={location.pathname} {...props} />
        );
    }
    return withRouter(WithLocationRefresh);
};

