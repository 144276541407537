import {useMutation} from '@tanstack/react-query';
import axios from 'axios';
import {QueryKey} from '../query';
import {OpenStreetMapCoordinates} from './map.types';

export const useCoordinatesMutation = () => {
    return useMutation({
        mutationKey: [QueryKey.MAP],
        mutationFn: (search: string) => getCoordinates(search),
    });
};

const getCoordinates = async (search: string) => {
    const {data} = await axios<OpenStreetMapCoordinates[]>(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
            search,
        )}`,
    );

    return data.at(0) || null;
};
