import {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Branch,
    Category,
    Element,
    File,
    Issue,
    IssueUser,
    Location,
    UserDetails,
} from '../../models';
import {NotificationContext} from '../../context/notifications';
import {
    archivedIssue,
    categories as categoriesEndpoint,
    issues,
} from '../../constants/endpoints';
import firebase from 'firebase/compat/app';
import {issueConverter} from '../../utils/converter/issueConverter';
import {useHistory, useParams} from 'react-router-dom';
import getLocationsQuery from '../../utils/queryBuilder/LocationQueryBuilder';
import {
    deleteIssue,
    DownloadedPhoto,
    downloadPhotos,
    fetchLocations,
    getElementsByLocationId,
    updateIssue,
} from '../../store/action';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import errorHandler from '../../common/components/ExceptionReporting/ErrorReporting';
import {fetchCategories} from '../../store/action/category';
import {mappings} from './IssueDetailsRoleMapper';
import {getUserRole} from '../../store/action/authHelpers';
import getUsersQuery from '../../utils/queryBuilder/UsersQueryBuilder';
import {TYPES} from '../../constants/error';
import {useTranslation} from 'react-i18next';
import {fetchElementImages} from '../../utils/element/element';
import isEqual from 'react-fast-compare';
import {withUserRole} from '../../hoc/User/WithUserRole';
import {Role} from '../../constants/roles';
import IssueDetailsLayout from './IssueDetailsLayout';
import {ARCHIVED_ISSUES, ISSUES} from '../../constants/routes';
import {LatLng} from 'leaflet';
import {mapBoundsStartingPosition} from '../../utils/map/type';
import {MAX_PHOTO_SIZE, MAX_PHOTOS} from '../../constants/files';
import {SelectOption} from '../../common/components/Select/CustomSelect';

type Images = {
    new: File[];
    uploaded: DownloadedPhoto[];
    removed: DownloadedPhoto[];
};

type SelectedElement = Element & {
    iconUri?: string;
    patternUri?: string;
};

type Props = {
    archived?: boolean;
};

export const IssueDetails = ({archived}: Props) => {
    const dispatch = useAppDispatch();

    const history = useHistory();

    const userData = useAppSelector(state => state.auth.userData);
    const organizationData = useAppSelector(
        state => state.auth.organizationData,
    );

    const locations = useAppSelector(state => state.location.locations);
    const elements = useAppSelector(state => state.element.elements);
    const categories = useAppSelector(state => state.category.categories);

    const {t} = useTranslation('issues');

    const [issue, setIssue] = useState<Issue | null>(null);
    const [editedIssue, setEditedIssue] = useState<Issue | null>(null);
    const [selectedElement, setSelectedElement] =
        useState<SelectedElement | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(
        null,
    );
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(
        null,
    );

    const [issueImages, setIssueImages] = useState<Images>({
        new: [],
        uploaded: [],
        removed: [],
    });
    const [solutionImages, setSolutionImages] = useState<Images>({
        new: [],
        uploaded: [],
        removed: [],
    });
    const [users, setUsers] = useState<UserDetails[]>([]);
    const [uploadingInProgress, setUploadingInProgress] = useState(false);
    const [loadingIssue, setLoadingIssue] = useState(true);

    const notificationContext = useContext(NotificationContext);

    const {id: issueId} = useParams<{id: string}>();

    const collectionPath = archived ? archivedIssue() : issues();

    const issuesRef = useRef(
        firebase.firestore().collection(collectionPath),
    ).current;

    const categoriesReference = useRef(
        firebase.firestore().collection(categoriesEndpoint()),
    ).current;

    const unSubscribeLocationsQuery = useRef(() => {});
    const unSubscribeCategoriesQuery = useRef(() => {});

    useEffect(() => {
        subscribeOnIssue(issueId);
    }, [issueId]);

    const fetchUsers = useCallback(
        (branch?: Branch, category?: Category) => {
            const {rolesToFetchUsersWith} = mappings[getUserRole()].roleProps;

            if (!rolesToFetchUsersWith || !branch) {
                return;
            }

            if (!category) {
                getUsersQuery()
                    .withBranch(branch)
                    .withRoles(rolesToFetchUsersWith)
                    .get()
                    .then(setUsers)
                    .catch(() => {
                        notificationContext?.addNotification({
                            message: t('notifications.details.usersLoadError'),
                            type: TYPES.error,
                        });
                    });
                return;
            }

            getUsersQuery()
                .withBranch(branch)
                .withRoles(rolesToFetchUsersWith)
                .withCategory(category.id)
                .get()
                .then(setUsers)
                .catch(() => {
                    notificationContext?.addNotification({
                        message: t('notifications.details.usersLoadError'),
                        type: TYPES.error,
                    });
                });
        },
        [t],
    );

    const subscribeLocations = useCallback((issue: Issue) => {
        unSubscribeLocationsQuery.current();
        unSubscribeLocationsQuery.current = getLocationsQuery([
            issue.branch.id,
        ]).onSnapshot(_fetchLocations, errorHandler);
    }, []);

    const subscribeOnCategories = useCallback(
        (issue: Issue) => {
            unSubscribeCategoriesQuery.current();
            unSubscribeCategoriesQuery.current = categoriesReference.onSnapshot(
                snapshot => {
                    fetchCategories(dispatch)(snapshot);

                    if (!issue.category) {
                        fetchUsers(issue.branch);
                        return;
                    }
                    const categories = snapshot.docs?.map(doc => ({
                        ...doc.data(),
                        id: doc.id,
                    })) as Category[];

                    const issueCategory = categories?.find(
                        category => category.id === issue.category?.id,
                    );

                    fetchUsers(issue.element.location.branch, issueCategory);
                },
                errorHandler,
            );
        },
        [dispatch, fetchUsers],
    );

    const fetchImagesAndUpdateElement = (element: Element) => {
        fetchElementImages(element)
            .then(([iconUri, patternUri]) => {
                setSelectedElement({
                    ...element,
                    iconUri,
                    patternUri,
                });
            })
            .catch(error => errorHandler(error, 'Bad element image paths'));
    };

    const applyRemoteChanges = useCallback(
        (newIssue: Issue) => {
            if (!issue) {
                return {...newIssue};
            }
            const oldIssue = {...issue};
            Object.keys(newIssue).forEach(key => {
                if (!isEqual(newIssue[key], oldIssue[key])) {
                    oldIssue[key] = newIssue[key];
                }
            });
            return {...editedIssue, ...oldIssue};
        },
        [issue, editedIssue],
    );

    const subscribeOnIssue = useCallback(
        (issueId: string) => {
            setLoadingIssue(true);

            issuesRef
                .doc(issueId)
                .withConverter(issueConverter)
                .onSnapshot(doc => {
                    if (!doc.data()) {
                        setLoadingIssue(false);
                        return;
                    }

                    const issue = {key: doc.id, ...doc.data()} as Issue;

                    subscribeLocations(issue);

                    if (organizationData.isIssuesCategoriesFeatureEnabled) {
                        subscribeOnCategories(issue);
                    } else {
                        fetchUsers(issue.element.location.branch);
                    }

                    getElementsByLocationId(issue.element.location.id)(
                        dispatch,
                    );

                    fetchImagesAndUpdateElement(issue.element);

                    downloadPhotos(issue.issueImagePaths).then(uploaded => {
                        setIssueImages(prev => ({...prev, uploaded}));
                    });
                    downloadPhotos(issue.solutionImagePaths).then(uploaded => {
                        setSolutionImages(prev => ({...prev, uploaded}));
                    });

                    const editedIssue = applyRemoteChanges(issue);

                    setIssue(issue);
                    setEditedIssue(editedIssue);
                    setSelectedCategory(issue.category);
                    setSelectedLocation(issue.element.location);
                    setLoadingIssue(false);
                });
        },
        [subscribeLocations, subscribeOnCategories, dispatch],
    );

    const _fetchLocations = useCallback(
        (querySnapshot: any) => {
            fetchLocations(querySnapshot)(dispatch);
        },
        [dispatch],
    );

    const issueImagesSrc = useMemo(
        () => [
            ...issueImages.new.map((image: any) => URL.createObjectURL(image)),
            ...issueImages.uploaded.map(image => image.uri),
        ],
        [issueImages],
    );
    const solutionImagesSrc = [
        ...solutionImages.new.map((image: any) => URL.createObjectURL(image)),
        ...solutionImages.uploaded.map(image => image.uri),
    ];
    const isCurrentUserIssueReporter =
        editedIssue?.reporter.uid === userData?.uid;

    const currentUserRole = getUserRole();

    const onPhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        editedIssue &&
            setEditedIssue({...editedIssue, phoneNumber: event.target.value});
    };

    const onSelectedCategoryChange = (event: SelectOption) => {
        const category =
            categories.find(category => event.value === category.id) || null;

        if (category !== selectedCategory) {
            setSelectedCategory(category);
            editedIssue && setEditedIssue({...editedIssue, assignedTo: null});

            if (selectedLocation && category) {
                fetchUsers(selectedLocation?.branch, category);
            }
        }

        editedIssue && setEditedIssue({...editedIssue, category});
    };

    const onPriorityChange = event => {
        editedIssue &&
            setEditedIssue({...editedIssue, priority: event.target.value});
    };

    const onElementChange = (event: SelectOption) => {
        const element = elements.find(element => event.value === element.id);
        if (!element) {
            console.warn('[IssueDetails] Element not found');
            return;
        }
        fetchImagesAndUpdateElement(element);
        setSelectedElement(element);
        editedIssue && setEditedIssue({...editedIssue, element});
    };

    const onLocationChange = (event: SelectOption) => {
        const location =
            locations.find(location => event.value === location.id) || null;

        if (location !== selectedLocation) {
            setSelectedLocation(location);
            setSelectedElement(null);

            if (location) {
                fetchUsers(location.branch);
                getElementsByLocationId(location.id)(dispatch);
            }
        }
    };

    const onAssignedEmployeeChange = (event: SelectOption) => {
        const user = users.find(user => event.value === user.uid) || null;
        editedIssue && setEditedIssue({...editedIssue, assignedTo: user});
    };

    const onAcceptedByChange = (event: SelectOption) => {
        const user = users.find(user => event.value === user.uid) || null;
        editedIssue && setEditedIssue({...editedIssue, acceptedBy: user});
    };

    const onIssueCreationDateChange = createdDate => {
        editedIssue && setEditedIssue({...editedIssue, createdDate});
    };

    const onIssueClosedDateChange = closedDate => {
        editedIssue && setEditedIssue({...editedIssue, closedDate});
    };
    const onStatusChange = event => {
        editedIssue && setEditedIssue({...editedIssue, status: event.value});
    };

    const onTextChangeHandlerBuilder = field => event => {
        editedIssue &&
            setEditedIssue({...editedIssue, [field]: event.target.value});
    };

    const onIssueFieldChangeHandlerBuilder = field => change => {
        editedIssue && setEditedIssue({...editedIssue, [field]: change});
    };

    const onLocationMarkerSave = (latLng: LatLng) => {
        const locationMarker: Issue['locationMarker'] = {
            bounds: {
                start: mapBoundsStartingPosition,
                end: [
                    selectedLocation?.locationMap?.height ?? 0,
                    selectedLocation?.locationMap?.width ?? 0,
                ],
            },
            position: latLng,
        };
        editedIssue && setEditedIssue({...editedIssue, locationMarker});
    };

    const onLocationMarkerDelete = () => {
        editedIssue && setEditedIssue({...editedIssue, locationMarker: null});
    };

    const _updateIssue = () => {
        setUploadingInProgress(true);
        updateIssue(dispatch, undefined)(
            editedIssue,
            issue,
            issueImages,
            solutionImages,
            () => {
                notificationContext?.addNotification({
                    message: t('notifications.details.elementUpdateSuccess'),
                    type: TYPES.success,
                });

                setUploadingInProgress(false);
                history.push(archived ? ARCHIVED_ISSUES : ISSUES);
            },
            () => {
                setUploadingInProgress(false);
                notificationContext?.addNotification({
                    message: t('notifications.details.elementEditError'),
                    type: TYPES.error,
                });
            },
        );
    };

    const _deleteIssue = () => {
        if (window.confirm(t('notifications.details.elementConfirmation'))) {
            setUploadingInProgress(true);

            deleteIssue(dispatch, undefined)(
                issue,
                () => {
                    setUploadingInProgress(false);
                    notificationContext?.addNotification({
                        message: t(
                            'notifications.details.elementDeleteSuccess',
                        ),
                        type: TYPES.success,
                    });
                    history.push(archived ? ARCHIVED_ISSUES : ISSUES);
                },
                () => {
                    setUploadingInProgress(false);
                    notificationContext?.addNotification({
                        message: t('notifications.details.elementDeleteError'),
                        type: TYPES.error,
                    });
                },
            );
        }
    };

    const userDidNotChange = (
        user1: IssueUser | undefined | null,
        user2: IssueUser | undefined | null,
    ) => {
        return user1 === user2 || (user1 && user2 && user1.uid === user2.uid);
    };

    const isSubmitButtonDisabled = () => {
        if (archived) {
            return true;
        }

        const photoChangesCount =
            issueImages.removed.length +
            issueImages.new.length +
            solutionImages.removed.length +
            solutionImages.new.length;

        return (
            uploadingInProgress ||
            !selectedElement ||
            (editedIssue?.phoneNumber &&
                editedIssue?.phoneNumber?.length < 9 &&
                editedIssue?.phoneNumber?.length > 0) ||
            (editedIssue?.issueDescription === issue?.issueDescription &&
                editedIssue?.solutionDescription ===
                    issue?.solutionDescription &&
                editedIssue?.createdDate === issue?.createdDate &&
                editedIssue?.closedDate === issue?.closedDate &&
                editedIssue?.element.id === issue?.element.id &&
                editedIssue?.phoneNumber === issue?.phoneNumber &&
                isEqual(editedIssue?.locationMarker, issue?.locationMarker) &&
                photoChangesCount === 0 &&
                editedIssue?.status === issue?.status &&
                userDidNotChange(editedIssue?.assignedTo, issue?.assignedTo) &&
                userDidNotChange(editedIssue?.acceptedBy, issue?.acceptedBy) &&
                editedIssue?.priority === issue?.priority &&
                editedIssue?.category?.id === issue?.category?.id)
        );
    };

    const onAddNewImageHandlerBuilder =
        (photosField: 'issueImages' | 'solutionImages') => (files: File[]) => {
            const isIssue = photosField === 'issueImages';
            const setState = isIssue ? setIssueImages : setSolutionImages;
            const photos = isIssue ? issueImages : solutionImages;
            const numberOfunacceptedFiles = files.filter(
                (file: any) => file && file?.size > MAX_PHOTO_SIZE,
            ).length;
            const actualImageCount = photos.new.length + photos.uploaded.length;
            const newImageCount = actualImageCount + files.length;

            if (newImageCount > MAX_PHOTOS) {
                notificationContext?.addNotification({
                    message: t('notifications.details.tooManyPhotosError'),
                    type: TYPES.error,
                });
                return;
            }

            if (numberOfunacceptedFiles > 0) {
                notificationContext?.addNotification({
                    message: t('notifications.details.tooLargePhotoError'),
                    type: TYPES.error,
                });
                return;
            }

            setState(prev => ({...prev, new: [...prev.new, ...files]}));
        };

    const onRemovePhotoHandlerBuilder =
        (photosField: 'issueImages' | 'solutionImages') =>
        removedPhotoIndex => {
            const isIssue = photosField === 'issueImages';
            const setState = isIssue ? setIssueImages : setSolutionImages;
            const photos = isIssue ? issueImages : solutionImages;
            const newPhotos = [...photos.new];
            const removedPhotos = [...photos.removed];
            const uploadedPhotos = [...photos.uploaded];
            const uploadedPhotoIndex = removedPhotoIndex - newPhotos.length;
            if (uploadedPhotoIndex >= 0) {
                removedPhotos.push(
                    uploadedPhotos.splice(uploadedPhotoIndex, 1)[0],
                );
            } else {
                newPhotos.splice(uploadedPhotoIndex, 1);
            }

            setState({
                new: newPhotos,
                removed: removedPhotos,
                uploaded: uploadedPhotos,
            });
        };

    return (
        (currentUserRole !== Role.REPORTER ||
            !archived ||
            organizationData.showRecentlyArchivedIssuesOnIssuesList) &&
        withUserRole(IssueDetailsLayout, mappings, {
            issue: editedIssue,
            isCurrentUserIssueReporter,
            userData,
            archived,
            locations,
            elements,
            categories,
            users,
            selectedElement,
            selectedLocation,
            selectedCategory,
            solutionImagesSrc,
            issueImagesSrc,
            uploadingInProgress,
            organizationData,
            loadingIssue,
            onPhoneNumberChange,
            onSelectedCategoryChange,
            onPriorityChange,
            onElementChange,
            onLocationChange,
            onAssignedEmployeeChange,
            onAcceptedByChange,
            onStatusChange,
            onIssueCreationDateChange,
            onIssueClosedDateChange,
            onIssueFieldChangeHandlerBuilder,
            onTextChangeHandlerBuilder,
            onAddNewImageHandlerBuilder,
            onRemovePhotoHandlerBuilder,
            updateIssue: _updateIssue,
            deleteIssue: _deleteIssue,
            onLocationMarkerDelete,
            onLocationMarkerSave,
            isSubmitButtonDisabled: isSubmitButtonDisabled(),
            isIssuesCategoriesFeatureEnabled:
                organizationData.isIssuesCategoriesFeatureEnabled,
            maintainerHasNoPermissionToAssignUsersToIssue:
                organizationData.maintainerHasNoPermissionToAssignUsersToIssue,
            reporterHasNoPermissionToEditIssueDescription:
                organizationData.reporterHasNoPermissionToEditIssueDescription,
            maintainerHasNoPermissionToEditIssueDescription:
                organizationData.maintainerHasNoPermissionToEditIssueDescription,
            reporterCanSeeIssueSolution:
                organizationData.reporterCanSeeIssueSolution,
            isIssueLocalizationEnabled:
                organizationData.isIssueLocalizationEnabled,
        })
    );
};
