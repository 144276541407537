import {default as CustomTextField} from '../textField/CustomTextField';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './ClickablePhoneNumberInput.module.css';
import {CustomButton} from '../buttons/Buttons';

export const ClickablePhoneNumberInput = props => {
    const {t} = useTranslation('issues');
    const [open, toggleOpen] = useState(!props?.phoneNumber?.length);
    return open ? (
        <>
            <CustomTextField
                label={t('details.default.phoneNumber')}
                defaultValue={props.phoneNumber}
                pattern="123456789"
                onChange={props.phoneNumberChange}
                maxLength={9}
                placeholder="123456789"
            />
            {!!props.phoneNumber && props.phoneNumber.length < 9 && (
                <p className={styles.error}>
                    {t('details.default.phoneNumberError')}
                </p>
            )}
        </>
    ) : (
        <div className={styles.container}>
            {t('details.default.phoneNumber')}
            <div className={styles.showPhone}>
                <a href={`tel:${props.phoneNumber}`}>{props.phoneNumber}</a>
                <CustomButton
                    className={styles.editButton}
                    onClick={() => toggleOpen(true)}
                    variant="text"
                    contained={false}
                    text={t('details.default.edit')}
                />
            </div>
        </div>
    );
};
