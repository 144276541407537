import {useState, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import Dialog from '../../../common/components/Dialog/Dialog';
import {
    CustomTextField,
    LocationsPicker,
    CustomDatePicker,
    ImageDropzone,
    FilesDropzone,
} from '../../../common/components';
import {normalizeStr} from '../../../utils/StringUtils/StringUtils';
import {NotificationContext} from '../../../context/notifications';
import {MAX_PHOTO_SIZE, MAX_FILES} from '../../../constants/files';
import {TYPES} from '../../../constants/error';
import {useAppSelector} from '../../../store/hooks';

type Props = {
    dialogTitle: string;
    locations: {id: string; name: string}[];
    open: boolean;
    loading: boolean;
    handleClose: () => void;
    onElementSubmit: (
        element: any,
        iconFile: File | null,
        files: File[],
    ) => void;
};

export const AddElementDialog = ({
    dialogTitle,
    locations,
    open,
    loading,
    handleClose,
    onElementSubmit,
}: Props) => {
    const {t: tElements} = useTranslation('elements');
    const notificationSystem = useContext(NotificationContext);
    const organizationData = useAppSelector(
        state => state.auth.organizationData,
    );

    const [newElementLocation, setNewElementLocation] = useState<{
        id: string;
        name: string;
    } | null>(null);
    const [newElementName, setNewElementName] = useState('');
    const [newElementWarranty, setNewElementWarranty] = useState<Date | null>(
        null,
    );
    const [newElementDescription, setNewElementDescription] = useState('');
    const [iconFile, setIconFile] = useState<File | null>(null);
    const [filesDropped, setFilesDropped] = useState<File[]>([]);

    const submitButtonDisabled =
        loading || !(newElementName && newElementLocation);

    const onFileDrop = (files: File[]) => {
        if (filesDropped.length + files.length > MAX_FILES) {
            notificationSystem?.addNotification({
                message: tElements('notifications.tooManyFilesError'),
                type: TYPES.error,
            });
        } else if (files.some(file => file.size > MAX_PHOTO_SIZE)) {
            notificationSystem?.addNotification({
                message: tElements('notifications.tooLargeFileError'),
                type: TYPES.error,
            });
        } else {
            setFilesDropped(prev => [...prev, ...files]);
        }
    };

    const onRemoveFile = (index: number) => {
        setFilesDropped(prev => prev.filter((_, i) => i !== index));
    };

    const onIconSelectHandler = ([file]: File[]) => setIconFile(file);
    const onIconRemoveHandler = () => setIconFile(null);
    const onNewElementLocationChange = (change: {
        value: string;
        label: string;
    }) => {
        setNewElementLocation({id: change.value, name: change.label});
    };
    const onClose = () => {
        setNewElementLocation(null);
        setNewElementName('');
        setNewElementDescription('');
        setIconFile(null);
        setFilesDropped([]);
        setNewElementWarranty(null);
        handleClose();
    };

    const onSubmitNewElementHandler = () => {
        if (!newElementLocation) {
            return;
        }

        const location = locations.find(
            loc => loc.id === newElementLocation.id,
        );
        const elementToSet = {
            description: newElementDescription,
            name: newElementName,
            producer: '',
            warrantyTo: newElementWarranty,
            location,
            qrCode: normalizeStr(`${location?.name}:${newElementName}`),
            createdDate: new Date(),
        };

        onElementSubmit(elementToSet, iconFile, filesDropped);
    };

    if (!open) {
        return null;
    }

    return (
        <Dialog
            loading={loading}
            handleClose={onClose}
            dialogTitle={dialogTitle}
            submitButtonDisabled={submitButtonDisabled}
            onSubmitHandler={onSubmitNewElementHandler}
            submitButtonText={dialogTitle}
        >
            <LocationsPicker
                defaultValue={
                    organizationData.eventBased
                        ? tElements('dialog.eventBased.searchPlaceholder')
                        : tElements('dialog.default.searchPlaceholder')
                }
                selectedLocation={newElementLocation}
                handleChange={onNewElementLocationChange}
                locations={locations}
                first
            />
            <CustomTextField
                label={
                    organizationData.eventBased
                        ? tElements('dialog.eventBased.name')
                        : tElements('dialog.default.name')
                }
                onChange={e => setNewElementName(e.target.value)}
                fullWidth
            />
            <CustomDatePicker
                label={tElements('dialog.default.creationDate')}
                value={new Date()}
                readOnly
            />
            <CustomDatePicker
                label={tElements('dialog.default.warrantyUntil')}
                value={newElementWarranty}
                onChange={setNewElementWarranty}
            />
            <CustomTextField
                label={
                    organizationData.eventBased
                        ? tElements('dialog.eventBased.description')
                        : tElements('dialog.default.description')
                }
                onChange={e => setNewElementDescription(e.target.value)}
                multiline
                fullWidth
            />
            <ImageDropzone
                label={
                    organizationData.eventBased
                        ? tElements('dialog.eventBased.image')
                        : tElements('dialog.default.image')
                }
                imagesSrc={iconFile ? [URL.createObjectURL(iconFile)] : []}
                onDrop={onIconSelectHandler}
                onRemoveImage={onIconRemoveHandler}
            />
            <FilesDropzone
                disabled={false}
                onlyXLSX={false}
                label={tElements('dialog.default.additionalFiles')}
                filesSrc={filesDropped.map(file => ({
                    path: URL.createObjectURL(file),
                    name: file.name,
                }))}
                onDrop={onFileDrop}
                onRemoveFile={onRemoveFile}
            />
        </Dialog>
    );
};
