import {createTheme} from '@mui/material/styles';

export const MainTheme = createTheme({
    palette: {
        primary: {main: '#047beb'},
    },
    components: {
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:after': {
                        borderBottomColor: '#047beb',
                    },
                    '&:before': {
                        borderBottomColor: 'white',
                    },
                    '&&&&:hover:before': {
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                    },
                },
            },
        },
    },
    typography: {},
});
