import React from 'react';

import ArrowBack from '@mui/icons-material/ArrowBackIos';
import {withRouter} from 'react-router-dom';
import {ListTitle} from '../index';
import styles from './BackNavigation.module.css';

const BackNavigation = props => (
    <div>
        <nav
            className={styles.backNav}
            onClick={() => props.history.push(props.backUrl)}
        >
            <ArrowBack className={styles.backArrow} />
            <span className={styles.navText}>{props.text}</span>
        </nav>
        <ListTitle className={styles.titleInBackNav} title={props.header} />
        <hr className={styles.hrInBackNav} />
    </div>
);

export default withRouter(BackNavigation);
