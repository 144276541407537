import LogoutButton from '../UserStatusPanel/UserLogoutButton';
import styles from './UserName.module.css';
import userLogo from '../../static/images/icons/user.svg';
import {Collapse} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const UserName = props => (
    <div
        className={styles.userName}
        onMouseEnter={props.onExpandButtonClick}
        onMouseLeave={props.onExpandButtonClick}
    >
        <div className={styles.expandClickable}>
            <img className={styles.userLogo} alt="" src={userLogo} />
            <p>{props.userName}</p>
            <ExpandMoreIcon className={styles.expandIcon} />
        </div>
        <Collapse
            in={props.menuExpanded}
            className={styles.menu}
            style={{zIndex: 1}}
        >
            <LogoutButton />
        </Collapse>
    </div>
);

export default UserName;
