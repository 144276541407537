import {MapMarker} from '../../../components/Map/Map.types';
import {Coordinates} from '../../../models';

export const parseCoordinatesToString = (coordinates?: Coordinates | null) =>
    coordinates ? `${coordinates.lat} ${coordinates.lng}` : '';

export const parseCoordinatesToMarker = (
    coordinates?: Coordinates | null,
): MapMarker | undefined =>
    coordinates
        ? {
              id: 1,
              name: 'Selected location',
              lat: coordinates.lat,
              lng: coordinates.lng,
          }
        : undefined;
