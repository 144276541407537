import {Button} from '../../common/components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

type Props = {
    text: string;
    onClick: () => void;
    variant: 'previous' | 'next';
};

export const NextIssueButton = ({text, onClick, variant}: Props) => {
    return (
        <Button
            sx={{height: '45px'}}
            customColor="grey"
            variant="outlined"
            onClick={onClick}
        >
            {variant === 'previous' && <ChevronLeftIcon />}
            <p>{text}</p>
            {variant === 'next' && <ChevronRightIcon />}
        </Button>
    );
};
