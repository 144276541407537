import React from 'react';
import {Role as ROLES} from '../../constants/roles';
import {CustomButton} from '../../common/components';

const mappings = {};

mappings[ROLES.MAINTAINER] = {
    innerComponents: [props => null, props => null],
    roleProps: {inputsDisabled: true},
};

mappings[ROLES.SUPERVISOR] = {
    innerComponents: [props => null, props => <CustomButton {...props} />],
    roleProps: {inputsDisabled: false},
};

mappings[ROLES.ADMIN] = {
    innerComponents: [
        props => <CustomButton {...props} />,
        props => <CustomButton {...props} />,
    ],
    roleProps: {inputsDisabled: false},
};

mappings[ROLES.SYSTEMADMIN] = {
    innerComponents: [
        props => <CustomButton {...props} />,
        props => <CustomButton {...props} />,
    ],
    roleProps: {inputsDisabled: false},
};

export {mappings};
