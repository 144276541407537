import {useEffect, useState} from 'react';
import styles from './CheckboxButton.module.css';
import {Button} from '../../../common/components';

type Props = {
    checked: boolean;
    onChange: (checked: boolean) => void;
    label: string;
    disabled?: boolean;
};
export const CheckboxButton = ({
    checked: checkedProp,
    onChange,
    label,
    disabled,
}: Props) => {
    const [checked, setChecked] = useState(checkedProp);

    useEffect(() => {
        setChecked(checkedProp);
    }, [checkedProp]);

    const onClick = () => {
        onChange(!checked);
        setChecked(prev => !prev);
    };

    return (
        <Button
            variant="contained"
            className={checked ? styles.checked : styles.unchecked}
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </Button>
    );
};
