import React, {useState, useEffect, ReactNode} from 'react';
import classNames from 'classnames';
import Select, {GroupBase, MenuPlacement, OptionsOrGroups} from 'react-select';
import styles from './Select.module.css';
import {defaultCustomStyles, globalBranchPickerStyle} from './styles';
import search from '../../../static/images/icons/search.svg';
import {SelectComponents} from 'react-select/dist/declarations/src/components';

export type SelectOption = {
    value: string | null;
    label: ReactNode;
};

export type CustomSelectProps = {
    options: SelectOption[];
    className?: string;
    handleChange: (newValue: any) => void;
    label?: string;
    placeholder?: ReactNode;
    selectedValue?: any;
    searchable?: boolean;
    disabled?: boolean;
    multi?: boolean;
    headerClassName?: string;
    clearable?: boolean;
    closeMenuOnSelect?: boolean;
    componentsForPicker?: Partial<
        SelectComponents<any, boolean, GroupBase<any>>
    >;
    globalBranchPicker?: boolean;
    menuPlacement?: MenuPlacement;
    menuPortalTarget?: HTMLElement | null;
    maxMenuHeight?: number;
    name?: string;
};

const CustomSelect = ({
    options,
    className,
    handleChange,
    label,
    placeholder,
    selectedValue,
    searchable,
    disabled,
    multi,
    headerClassName,
    clearable,
    closeMenuOnSelect,
    componentsForPicker,
    globalBranchPicker,
    menuPlacement,
    menuPortalTarget,
    maxMenuHeight,
    name,
}: CustomSelectProps) => {
    const [customSelectedValue, setSelectedValue] = useState(selectedValue);
    const onChange = (value: SelectOption) => {
        setSelectedValue(value);
        handleChange && handleChange(value);
    };
    useEffect(() => {
        if (selectedValue !== undefined) {
            setSelectedValue(selectedValue);
        }
    });

    const searchIcon = !customSelectedValue && (
        <img src={search} alt="" className={styles.searchIcon} />
    );

    const style = globalBranchPicker
        ? globalBranchPickerStyle(customSelectedValue, disabled)
        : defaultCustomStyles(customSelectedValue);

    return (
        <div className={classNames(styles.select, className)}>
            <header className={headerClassName}>{label}</header>
            <div style={{position: 'relative'}}>
                <Select
                    menuPlacement={menuPlacement}
                    placeholder={placeholder}
                    styles={style}
                    options={options}
                    isDisabled={disabled}
                    isSearchable={searchable}
                    isMulti={multi}
                    value={customSelectedValue}
                    onChange={onChange}
                    isClearable={clearable}
                    hideSelectedOptions={!multi}
                    closeMenuOnSelect={closeMenuOnSelect}
                    components={componentsForPicker}
                    menuPortalTarget={menuPortalTarget}
                    maxMenuHeight={maxMenuHeight}
                    name={name}
                />
                {searchIcon}
            </div>
        </div>
    );
};

export default CustomSelect;
