import React from 'react';
import {useTranslation} from 'react-i18next';
import {AddButton, CancelButton, CloseButton, CustomCircularProgress} from '..';
import styles from './Dialog.module.css';
import {If} from '../If';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog as MaterialDialog,
} from '@mui/material';

type Props = {
    dialogTitle?: string;
    submitButtonText?: string;
    submitButtonDisabled?: boolean;
    onSubmitHandler: () => void;
    handleClose: () => void;
    loading?: boolean;
    open?: boolean;
    children: React.ReactNode;
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    dialogTitleRightElement?: React.ReactNode;
    cancelButtonVisible?: boolean;
    confirmButtonVisible?: boolean;
};

const Dialog = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            cancelButtonVisible = true,
            confirmButtonVisible = true,
            open = true,
            ...props
        },
        ref,
    ) => {
        const {t} = useTranslation('common');

        return (
            <MaterialDialog
                classes={{
                    paper: styles.window,
                }}
                ref={ref}
                fullWidth={props.fullWidth ?? true}
                maxWidth={props.maxWidth || 'sm'}
                open={open}
                onClose={props.handleClose}
                onClick={e => e.stopPropagation()}
                aria-labelledby="form-dialog-title"
            >
                {props.loading && <CustomCircularProgress />}
                <CloseButton onClick={props.handleClose} />
                <If condition={props.dialogTitle}>
                    <DialogTitle
                        id="form-dialog-title"
                        className={styles.title}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <h2>{props.dialogTitle}</h2>
                            {props.dialogTitleRightElement &&
                                props.dialogTitleRightElement}
                        </div>
                    </DialogTitle>
                </If>
                <hr className={styles.hrInMobileForm} />
                <DialogContent className={styles.content}>
                    {props.children}
                </DialogContent>
                <DialogActions className={styles.actions}>
                    {cancelButtonVisible && (
                        <CancelButton
                            text={t('dialog.cancelBtn')}
                            onClick={props.handleClose}
                        />
                    )}
                    {confirmButtonVisible && (
                        <AddButton
                            text={
                                props.submitButtonText
                                    ? props.submitButtonText
                                    : t('dialog.submitBtn')
                            }
                            disabled={props.submitButtonDisabled}
                            onClick={props.onSubmitHandler}
                        />
                    )}
                </DialogActions>
            </MaterialDialog>
        );
    },
);

export default Dialog;
