import {useMemo} from 'react';
import {useLocationsQuery} from '../../api/location/useLocationsQuery';
import {useAppSelector} from '../../store/hooks';
import {Issue} from '../../models';

export const useIssuesWithLocations = (enabled: boolean) => {
    const issuesWithoutUpdatedLocations = useAppSelector(
        state => state.issue.issues,
    );

    const {data: locations} = useLocationsQuery();

    // Firebase onUpdate cloud function not always update all elements. In future we need to migrate to dictionaries and leave only location.id field in firestore
    const issues = useMemo(
        () =>
            issuesWithoutUpdatedLocations.reduce<Issue[]>(
                (acc, issue: Issue | undefined) => {
                    if (!issue) {
                        return [...acc];
                    }

                    const location = locations?.find(
                        item => item.id === issue.element.location.id,
                    );

                    if (!location) {
                        return [...acc, issue];
                    }

                    return [
                        ...acc,
                        {...issue, element: {...issue.element, location}},
                    ];
                },
                [],
            ),
        [issuesWithoutUpdatedLocations, locations],
    );

    return enabled ? issues : issuesWithoutUpdatedLocations;
};
