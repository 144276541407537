import firebase from 'firebase/compat/app';
import {locationsImages} from '../constants/endpoints';
import {type StorageFilePath} from '../utils/storageUtils/storageUtils';
import {Branch} from './branch';
import {createPromisesToUploadPhotosAndGetPath} from '../store/action/utilHelper';

export const MAX_LOCATION_MAP_SIZE = 30 * 1024 * 1024;

export type BranchId = string;

export type LocationId = string;

export type Coordinates = {
    lat: number;
    lng: number;
};

export type Location = {
    id: LocationId;
    name: string;
    branch: Branch;
    locationMap?: {
        height: number;
        width: number;
        image: StorageFilePath;
        name: string;
        size: number;
    } | null;
    coordinates?: Coordinates | null;
};

export const uploadLocationMapObject = async ({
    id: locationId,
    locationMap,
}: {
    locationMap: File;
    id: LocationId;
}): Promise<StorageFilePath> => {
    const storagePath = locationsImages() + locationId;
    const storageRef = firebase.storage().ref().child(storagePath);
    const imagePath = await createPromisesToUploadPhotosAndGetPath(
        [locationMap],
        storageRef,
        {customImageName: locationId},
    )[0];

    return imagePath;
};

export const findLocationFromQRCode = (
    qrCodeData: any,
    locations: Location[],
) => locations.find(location => location.id === qrCodeData.locationId);
