import {useEffect, useState} from 'react';
import Dialog from '../../../common/components/Dialog/Dialog';
import {useTranslation} from 'react-i18next';
import {IssueColumn} from '../IssuesList/IssueList.types';
import {CheckboxButton} from './CheckboxButton';
import styles from './FilterIssueColumnDialog.module.css';
import {ArchiveColumn} from '../ArchivedIssueList/ArchiveIssueList.types';
import {TaskColumn} from '../../Tasks/TasksList/TaskList.types';
import {Divider} from '@mui/material';

type Column = IssueColumn | ArchiveColumn | TaskColumn;

type Props = {
    visibleColumns: Column[];
    visible: boolean;
    handleClose: () => void;
    onSubmit: (payload: Column[]) => void;
    variant: 'archive' | 'tasks' | 'issues';
};

export const FilterIssueColumnDialog = ({
    visibleColumns,
    visible,
    handleClose,
    onSubmit,
    variant,
}: Props) => {
    const [checkedColumns, setCheckedColumns] =
        useState<Column[]>(visibleColumns);

    const {t} = useTranslation(variant);

    const {t: tCommon} = useTranslation('common');

    useEffect(() => {
        setCheckedColumns(visibleColumns);
    }, [visibleColumns, visible]);

    const onChange = (value: IssueColumn, checked: boolean) => {
        setCheckedColumns(prev => {
            if (checked) {
                return [...prev, value];
            } else {
                return prev.filter(column => column !== value);
            }
        });
    };

    const onSubmitHandler = () => {
        onSubmit(checkedColumns);
    };

    if (!visible) {
        return null;
    }

    const enums =
        variant === 'archive'
            ? ArchiveColumn
            : variant === 'tasks'
            ? TaskColumn
            : IssueColumn;

    return (
        <Dialog
            onSubmitHandler={onSubmitHandler}
            handleClose={handleClose}
            dialogTitle={tCommon('columnFilter.dialog.title')}
        >
            <div className={styles.container}>
                {Object.values(enums).map(value => (
                    <CheckboxButton
                        label={t(`table.columns.default.${value}`)}
                        checked={checkedColumns.includes(value)}
                        onChange={checked => onChange(value, checked)}
                        disabled={
                            checkedColumns.includes(value) &&
                            checkedColumns.length === 1
                        }
                    />
                ))}
            </div>
            <Divider className={styles.divider} />
        </Dialog>
    );
};
