import {useTranslation} from 'react-i18next';
import EditLocationDialog from '../../components/LocationDetails/LocationDetailsDialog';
import {ListActionPanel} from '../../common/components';
import {AddLocationDialog} from '../../components/Location/AddLocationDialog/AddLocationDialog';
import {useAppSelector} from '../../store/hooks';

const LocationsPageLayout = ({props}) => {
    const eventBased = useAppSelector(
        state => state.auth.organizationData.eventBased,
    );

    const {t} = useTranslation('locations');

    return (
        <div>
            <ListActionPanel
                searchPlaceholder={
                    eventBased
                        ? t('main.eventBased.searchPlaceholder')
                        : t('main.default.searchPlaceholder')
                }
                title={
                    eventBased
                        ? t('main.eventBased.title')
                        : t('main.default.title')
                }
                addButtonText={
                    eventBased
                        ? t('main.eventBased.actionBtn')
                        : t('main.default.actionBtn')
                }
                onSearchTextChange={props.onSearchTextChange}
                onToggleAddDialogHandler={props.onToggleAddDialogHandler}
                showAddButton
            />
            {props.addDialogOpen && (
                <AddLocationDialog
                    showNotification={props.showNotification}
                    dialogTitle={
                        eventBased
                            ? t('dialog.eventBased.title.add')
                            : t('dialog.default.title.add')
                    }
                    handleClose={props.onToggleAddDialogHandler}
                    onAddLocation={props.onLocationSubmit}
                />
            )}
            {props.editDialogOpen && (
                <EditLocationDialog
                    dialogTitle={
                        eventBased
                            ? t('dialog.eventBased.title.edit')
                            : t('dialog.default.title.edit')
                    }
                    location={props.locationSelectedForEdit}
                    handleClose={props.onToggleEditDialogHandler}
                    onUpdateLocationEvent={props.onUpdateLocationEventHandler}
                    showNotification={props.showNotification}
                />
            )}
            {props.locationsList}
        </div>
    );
};

export default LocationsPageLayout;
