import DatePicker from 'react-datepicker';
import './CustomDatePicker.css';
import styles from './CustomDatePicker.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import {ReactComponent as CalendarIcon} from '../../../static/images/icons/calendar-3.svg';

type Props = {
    label: string;
    readOnly?: boolean;
    value: Date | null;
    onChange?: (date: Date | null) => void;
    maxDate?: Date;
    minDate?: Date;
};

const CustomDatePicker = ({
    label,
    readOnly,
    value,
    onChange,
    maxDate,
    minDate,
}: Props) => (
    <div className={styles.datePickerContainer}>
        <div className={styles.dateLabel}>{label}</div>
        <div
            className={
                readOnly
                    ? `${styles.datePicker} ${styles.disabled}`
                    : styles.datePicker
            }
        >
            <span className={styles.calendarIcon}>
                <CalendarIcon />
            </span>
            <DatePicker
                dateFormat="dd/MM/yyyy"
                className={styles.input}
                selected={value}
                onChange={onChange}
                readOnly={readOnly}
                maxDate={maxDate}
                minDate={minDate}
                fixedHeight
                preventOpenOnFocus
            />
        </div>
    </div>
);

export default CustomDatePicker;
