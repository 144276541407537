import {useContext, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './AdminEnvironmentPage.module.css';

import {NotificationContext} from '../../context/notifications';
import EnvironmentAdminForm from '../../components/Environment/EnvironmentAdminForm/EnvironmentAdminForm';
import {sendDataToUpdate} from '../../store/action/environment';
import {TYPES} from '../../constants/error';
import {ListTitle} from '../../common/components';
import {useAppSelector} from '../../store/hooks';
import {If} from '../../common/components/If';

const AdminEnvironmentPage = () => {
    const {t} = useTranslation('environments');
    const notificationSystem = useContext(NotificationContext);

    const userData = useAppSelector(state => state.auth.userData);
    const organizationData = useAppSelector(
        state => state.auth.organizationData,
    );

    const [updateEnvironmentPending, setUpdateEnvironmentPending] =
        useState(false);

    const onSubmitUpdateEnvironment = async (formData: any) => {
        setUpdateEnvironmentPending(true);
        try {
            await sendDataToUpdate(formData);
            notificationSystem?.addNotification({
                message: t('notifications.elementEditSuccess'),
                type: TYPES.success,
            });
        } catch (error) {
            console.error(error);
            notificationSystem?.addNotification({
                message: t('notifications.elementEditError'),
                type: TYPES.error,
            });
        } finally {
            setUpdateEnvironmentPending(false);
        }
    };

    const organization = useMemo(
        () => ({
            name: userData?.organization,
            ...organizationData,
        }),
        [organizationData, userData?.organization],
    );

    const {
        smsNotifications,
        smsLimitCount = 0,
        smsSentCount = 0,
    } = organization;

    const remainingSms = Math.max(0, smsLimitCount - smsSentCount);

    return (
        <div>
            <ListTitle
                title={t('main.settings')}
                rightComponent={
                    <If condition={smsNotifications}>
                        <span className={styles.smsCountdownContainer}>
                            {t('main.remainingSms')}{' '}
                            <strong>{remainingSms}</strong>
                        </span>
                    </If>
                }
            />
            <EnvironmentAdminForm
                onSubmitHandler={onSubmitUpdateEnvironment}
                environment={organization}
                dialogTitle={t('dialog.title.edit')}
                loading={updateEnvironmentPending}
            />
        </div>
    );
};

export default AdminEnvironmentPage;
