import {Radio, RadioGroup, FormControlLabel} from '@mui/material';
import {styled} from '@mui/system';
import styles from './RadioButton.module.css';

const StyledRadio = styled(Radio)({
    '&.Mui-checked': {
        color: 'var(--light-blue)',
    },
});

type RadioButtonProps = {
    label: string;
    value: string;
    disabled?: boolean;
};

const RadioButton = ({label, value, disabled}: RadioButtonProps) => (
    <FormControlLabel
        className={styles.item}
        value={value}
        label={label}
        control={<StyledRadio disabled={disabled} />}
    />
);

type RadioButtonsProps = {
    items: Array<{value: string; name: string}>;
    label: string;
    onChange: (e: React.FormEvent<HTMLInputElement>, value: string) => void;
    defaultValue?: string;
    disabled?: boolean;
};

const RadioButtons = ({
    items,
    label,
    onChange,
    defaultValue,
    disabled,
}: RadioButtonsProps) => {
    return (
        <RadioGroup
            onChange={onChange}
            className={styles.container}
            defaultValue={defaultValue}
        >
            <label>{label}</label>
            {items.map(item => (
                <RadioButton
                    key={item.value}
                    label={item.name}
                    value={item.value}
                    disabled={disabled}
                />
            ))}
        </RadioGroup>
    );
};

export default RadioButtons;
