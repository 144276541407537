import React from 'react';
import styles from './Checkbox.module.css';
import {ReactComponent as AlertTriangle} from '../../../static/images/icons/alert-triangle.svg';
import {FormControlLabel, Checkbox as MaterialCheckbox} from '@mui/material';

type CheckboxProps = {
    checked?: boolean;
    checkedClassName?: string;
    label?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: unknown;
    color?: string;
    disabled?: boolean;
    showIcon?: boolean;
    className?: string;
    name?: string;
    id?: string;
};

const Checkbox = ({
    checked,
    checkedClassName,
    label,
    onChange,
    value,
    color = 'var(--light-blue)',
    disabled,
    showIcon,
    className,
    name,
    id,
}: CheckboxProps) => {
    const checkedClass = checkedClassName || styles.checked;
    return (
        <div className={`${styles.checkbox} ${className}`}>
            <FormControlLabel
                classes={{label: styles.label}}
                control={
                    showIcon && checked ? (
                        <AlertTriangle className={styles.alertTriangle} />
                    ) : (
                        <MaterialCheckbox
                            sx={{
                                color: color,
                                width: 20,
                            }}
                            classes={{
                                checked: checkedClass,
                                root: styles.root,
                            }}
                            checked={checked}
                            onChange={onChange}
                            value={value}
                            disabled={disabled}
                            id={id}
                        />
                    )
                }
                label={label}
                name={name}
            />
        </div>
    );
};

export default Checkbox;
