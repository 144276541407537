export enum IssueColumn {
    ELEMENT_NAME = 'element',
    ID = 'id',
    LOCATION_NAME = 'location',
    BRAND_NAME = 'branch',
    DESCRIPTION = 'description',
    CREATED_DATE = 'creationDate',
    ASSIGNED_TO = 'assignedTo',
    PRIORITY = 'priority',
    STATUS = 'status',
}

export type IssuesFilters = {
    open: boolean;
    inProgress: boolean;
    inReview: boolean;
    done: boolean;
    low: boolean;
    medium: boolean;
    high: boolean;
};
